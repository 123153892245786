import Axios from 'axios'
import qs from 'qs'

/* common async funtion for axios */
export const GetAsyncHelper = async (api_url) => {
    try {
        const response = await Axios({
            url: api_url,
            method: "get",
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
        })
        return response
    } catch (error) {
        return error
    }
}

export const PostAsyncHelper = async (url, object, onDownloadProgressConfig, ) => {

    try {
        const options = {
            arrayFormat: 'indices',
            format: 'RFC3986',
            charset: 'utf-8',
            // encode: false,
            // strictNullHandling: true,
            // allowDots: true,
            // addQueryPrefix: true
        }
        const response = await Axios({
            method: 'post',
            url: url,
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify(object, options),
            onDownloadProgress: onDownloadProgressConfig,
        }, )
        if (response.statusText !== "OK") {
            console.log ("PostAsyncHelper errors status not OK: ")
            console.log (response.statusText)  
            throw Error(response.statusText)
        }
        return response
    } catch (error) {
        console.log ("PostAsyncHelper error handler")  
        console.log (error)
        console.error(error.message)
        return error
    }
}
export const PostAsyncHelperImage = async (url, form_data, onDownloadProgressConfig) => {

    try {
        const response = await Axios({
            method: 'post',
            url: url,
            headers: {
                'content-type': 'multipart/form-data'
            },
            data: form_data,
            onDownloadProgress: onDownloadProgressConfig,
        }, )
        if (response.statusText !== "OK") {
            throw Error(response.statusText)
        }
        return response
    } catch (error) {
        console.error(error.message)
    }
}


/* SETTING COLORS */

export const SET_APP_MENU_BACK_GROUND_COLOR = "SET_APP_MENU_BACK_GROUND_COLOR"
export const SET_APP_MENU_TEXT_COLOR = "SET_APP_MENU_TEXT_COLOR"
export const SET_APP_MENU_BACK_GROUND_COLOR_MOBILE = "SET_APP_MENU_BACK_GROUND_COLOR_MOBILE"


/* SETTING SCREEN  */
export const CURRENT_SCREEN_SIZE = "CURRENT_SCREEN_SIZE"
export const CURRENT_LANGUAGE = "CURRENT_LANGUAGE"
export const SET_APP_MENU = "SET_APP_MENU"
export const CURRENT_SCREEN_VERTICAL = "CURRENT_SCREEN_VERTICAL"
export const CURRENT_BODY_CLIENT_WIDTH = "CURRENT_BODY_CLIENT_WIDTH"
export const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE"
export const SET_CURRENT_CURRENCY = "SET_CURRENT_CURRENCY"
export const FETCH_API_CURRENCY_SETS = "FETCH_API_CURRENCY_SETS"
export const FETCH_API_LANGUAGE_SETS = "FETCH_API_LANGUAGE_SETS"
export const FETCH_API_LANGUAGE_SETS_FETCHED = "FETCH_API_LANGUAGE_SETS_FETCHED"
export const FETCH_API_CURRENCY_SETS_FETCHED = "FETCH_API_CURRENCY_SETS_FETCHED"
export const FETCH_API_LANGUAGE_SETS_ERROR = "FETCH_API_LANGUAGE_SETS_ERROR"
export const FETCH_API_CURRENCY_SETS_ERROR = "FETCH_API_CURRENCY_SETS_ERROR"
export const CURRENT_SCREEN_VERTICAL_IS_TOP = "CURRENT_SCREEN_VERTICAL_IS_TOP"
export const CURRENT_PAGE_Y_OFFSET = "CURRENT_PAGE_Y_OFFSET"



export const SET_LOADER = "SET_LOADER"
export const ALERT_BARS_HANDLE = "ALERT_BARS_HANDLE"

export const FETCH_UUID_SUCCESS = "FETCH_UUID_SUCCESS"
export const FETCH_UUID_FAILURE = "FETCH_UUID_FAILURE"

export const FETCH_PHOTOS_SUCCESS = "FETCH_PHOTOS_SUCCESS"
export const FETCH_PHOTOS_FAILURE = "FETCH_PHOTOS_FAILURE"

export const FETCH_VIDEOS_SUCCESS = "FETCH_VIDEOS_SUCCESS"
export const FETCH_VIDEOS_FAILURE = "FETCH_VIDEOS_FAILURE"

export const FETCH_MUVER_SUCCESS = "FETCH_MUMER_SUCCESS"
export const FETCH_MUVER_FAILURE = "FETCH_MUVER_FAILURE"

export const FETCH_MEMBER_INFLUENCERS = "FETCH_MEMBER_INFLUENCERS"
export const FETCH_MEMBER_INFLUENCERS_ERROR = "FETCH_MEMBER_INFLUENCERS_ERROR"
export const FETCH_MEMBER_FRIENDS = "FETCH_MEMBER_FRIENDS"
export const FETCH_MEMBER_FRIENDS_ERROR = "FETCH_MEMBER_FRIENDS_ERROR"
export const FETCH_MEMBER_FOLLOWERS = "FETCH_MEMBER_FOLLOWERS"
export const FETCH_MEMBER_FOLLOWERS_ERROR = "FETCH_MEMBER_FOLLOWERS_ERROR"
export const FETCH_MEMBER_DESTINATION_INTERESTS = "FETCH_MEMBER_DESTINATION_INTERESTS"
export const FETCH_MEMBER_DESTINATION_INTERESTS_ERROR = "FETCH_MEMBER_DESTINATION_INTERESTS_ERROR"

export const SET_SELECTED_DESTINATION_VISITED = "SET_SELECTED_DESTINATION_VISITED"
export const SELECTED_DESTINATION_VISITED_MERGE = "SELECTED_DESTINATION_VISITED_MERGE"
export const DELETE_DESTINATION_VISITED = "DELETE_DESTINATION_VISITED"

/*  public trip */
export const SET_COPY_TRIP = "SET_COPY_TRIP"
export const FETCH_TRIP_SUCCESS = "FETCH_TRIP_SUCCESS"
export const FETCH_TRIP_FAILURE = "FETCH_TRIP_FAILURE"
export const FETCH_PUBLIC_TRIP_COST = "FETCH_PUBLIC_TRIP_COST"
export const FETCH_PUBLIC_TRIP_COST_API_ERROR = "FETCH_PUBLIC_TRIP_COST_API_ERROR"


/* MEMBER */

export const UPDATE_SELECTED_HEADER_IMAGE = "UPDATE_SELECTED_HEADER_IMAGE"
export const UPDATE_TRIP_IMAGE = "UPDATE_TRIP_IMAGE"
export const SET_SELECTED_TRIP_IMAGE_MEMBER_AREA = "SET_SELECTED_TRIP_IMAGE_MEMBER_AREA"
export const SET_LIST_DISPLAY_FORMAT_MEMBER = "SET_LIST_DISPLAY_FORMAT_MEMBER"
export const FETCH_MEMBER_SUCCESS = "FETCH_MEMBER_SUCCESS"
export const FETCH_MEMBER_FAILURE = "FETCH_MEMBER_FAILURE"
export const FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES = "FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES"
export const FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR = "FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_ERROR"
export const FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH = "FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_IS_FETCH"
export const FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_MERGE = "FETCH_SOCIAL_MEMBER_HOMETOWN_FAVORITES_MERGE"
export const FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES = "FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES"
export const FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_ERROR = "FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_ERROR"
export const FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH = "FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_IS_FETCH"
export const FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_MERGE = "FETCH_SOCIAL_MEMBER_DESTINATION_FAVORITES_MERGE"

export const FETCH_MEMBER_FUTURE_TRIPS_SUCCESS = "FETCH_MEMBER_FUTURE_TRIPS_SUCCESS"
export const FETCH_MEMBER_FUTURE_TRIPS_FAILURE = "FETCH_MEMBER_FUTURE_TRIPS_FAILURE"
export const FETCH_SOCIAL_MEMBER_PAST_TRIPS = "FETCH_SOCIAL_MEMBER_PAST_TRIPS"
export const FETCH_SOCIAL_MEMBER_PAST_TRIPS_MERGE = "FETCH_SOCIAL_MEMBER_PAST_TRIPS_MERGE"
export const FETCH_SOCIAL_MEMBER_PAST_TRIPS_ERROR = "FETCH_SOCIAL_MEMBER_PAST_TRIPS_ERROR"
export const FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH = "FETCH_SOCIAL_MEMBER_PAST_TRIPS_IS_FETCH"
export const FETCH_MEMBER_LAST_TRIP_SUCCESS = "FETCH_MEMBER_LAST_TRIP_SUCCESS"
export const FETCH_MEMBER_LAST_TRIP_FAILURE = "FETCH_MEMBER_LAST_TRIP_FAILURE"
export const FETCH_MEMBER_DREAM_TRIPS_FAILURE = "FETCH_MEMBER_DREAM_TRIPS_FAILURE"
export const FETCH_MEMBER_DREAM_TRIPS_SUCCESS = "FETCH_MEMBER_DREAM_TRIPS_SUCCESS"


export const SET_PRIVATE_AREA = "SET_PRIVATE_AREA"
export const CURRENT_VIDEO_DISPLAY = "CURRENT_VIDEO_DISPLAY"


/*  is show */
export const IS_EXTANSION_TIMELINE_BAR_CLOSE = "IS_EXTANSION_TIMELINE_BAR_CLOSE"
export const IS_EXTANSION_TIMELINE_BAR_OPEN = "IS_EXTANSION_TIMELINE_BAR_OPEN"


// trip manager
export const GET_SELECTED_TRIP_INFORMATION = "GET_SELECTED_TRIP_INFORMATION"
export const CLEAR_SELECTED_TRIP_INFORMATION = "CLEAR_SELECTED_TRIP_INFORMATION"
export const CLEAR_ALL_TRIP_INFORMATION = "CLEAR_ALL_TRIP_INFORMATION"
export const DELETE_TRIP_MODIFY_PROFILE = "DELETE_TRIP_MODIFY_PROFILE"
export const DELETE_TRIP_LIST = "DELETE_TRIP_LIST"
export const TOGGLE_ELLIPSIS_CLOSE_DISABLE = "TOGGLE_ELLIPSIS_CLOSE_DISABLE"
export const TOGGLE_ELLIPSIS_CLOSE_ENABLE = "TOGGLE_ELLIPSIS_CLOSE_ENABLE"
export const SET_TRIP_DISPLAY_MODE = "SET_TRIP_DISPLAY_MODE"
export const MODIFY_TRIP_NAME_LISTING = "MODIFY_TRIP_NAME_LISTING"
export const MODIFY_TRIP_DATE_LISTING = "MODIFY_TRIP_DATE_LISTING"
export const COPY_TRIP_DREAM_TRIPS = "COPY_TRIP_DREAM_TRIPS"
export const COPY_TRIP_PAST_TRIPS = "COPY_TRIP_PAST_TRIPS"
export const COPY_TRIP_FUTURE_TRIPS = "COPY_TRIP_FUTURE_TRIPS"
export const COPY_TRIP_MODIFY_PROFILE = "COPY_TRIP_MODIFY_PROFILE"
export const SORT_TRIP_COLLECTION_BY_DATE_ASCENDING = "SORT_TRIP_COLLECTION_BY_DATE_ASCENDING"
export const SORT_TRIP_COLLECTION_BY_DATE_DESCENDING = "SORT_TRIP_COLLECTION_BY_DATE_DESCENDING"
export const FETCH_HOMETOWN_FAVORITES_TYPE_FAILURE = "FETCH_HOMETOWN_FAVORITES_TYPE_FAILURE"
export const FETCH_HOMETOWN_FAVORITES_TYPE_SUCCESS = "FETCH_HOMETOWN_FAVORITES_TYPE_SUCCESS"
export const DELETE_PAST_TRIP_LIST = "DELETE_PAST_TRIP_LIST"
export const DELETE_FUTURE_TRIP_LIST = "DELETE_FUTURE_TRIP_LIST"
export const DELETE_DREAM_TRIP_LIST = "DELETE_DREAM_TRIP_LIST"

export const FETCH_TRIP_TYPES_SETS = "FETCH_TRIP_TYPES_SETS"
export const SET_TRIP_TYPE_SELECTED = "SET_TRIP_TYPE_SELECTED"
export const FETCH_TRIP_COMPANION_SETS = "FETCH_TRIP_COMPANION_SETS"

//favorites action

export const CURRENT_FAVORITES_TYPE_SELECTED = "CURRENT_FAVORITES_TYPE_SELECTED"


//landing page action

export const FETCH_MUVER_INTERESTS_SELECTED_SETS = "FETCH_MUVER_INTERESTS_SELECTED_SETS"
export const SWITCH_PROFILE_CONTAINER = "SWITCH_PROFILE_CONTAINER"
export const ADD_TRAVEL_INTERESTS = "ADD_TRAVEL_INTERESTS"
export const REMOVE_TRAVEL_INTERESTS = "REMOVE_TRAVEL_INTERESTS"
export const ADD_TRAVEL_HOW = "ADD_TRAVEL_HOW"
export const REMOVE_TRAVEL_HOW = "REMOVE_TRAVEL_HOW"
export const ADD_TRAVEL_WHAT = "ADD_TRAVEL_WHAT"
export const REMOVE_TRAVEL_WHAT = "REMOVE_TRAVEL_WHAT"
export const ADD_TRAVEL_HOWLONG = "ADD_TRAVEL_HOWLONG"
export const REMOVE_TRAVEL_HOWLONG = "REMOVE_TRAVEL_HOWLONG"
export const ADD_TRAVEL_WHERE = "ADD_TRAVEL_WHERE"
export const REMOVE_TRAVEL_WHERE = "REMOVE_TRAVEL_WHERE"
export const FETCH_MUVER_INTERESTS_SUCCESS = "FETCH_MUVER_INTERESTS_SUCCESS"
export const FETCH_MUVER_INTERESTS_FAILURE = "FETCH_MUVER_INTERESTS_FAILURE"

/* form validation */

export const CEHCK_EMAIL_EXIST_FAILURE = "CEHCK_EMAIL_EXIST_FAILURE"
export const CEHCK_EMAIL_EXIST_SUCCESS = "CEHCK_EMAIL_EXIST_SUCCESS"
export const SET_EMAIL_ADDRESS = "SET_EMAIL_ADDRESS"
export const SET_EMAIL_ADDRESS_ERROR = "SET_EMAIL_ADDRESS_ERROR"
export const SET_EMAIL_ADDRESS_TEXT_HELPER = "SET_EMAIL_ADDRESS_TEXT_HELPER"

export const CEHCK_HANDLE_EXIST_FAILURE = "CEHCK_HANDLE_EXIST_FAILURE"
export const CEHCK_HANDLE_EXIST_SUCCESS = "CEHCK_HANDLE_EXIST_SUCCESS"
export const SET_USER_SIGNATURE = "SET_USER_SIGNATURE"
export const SET_HANDLE = "SET_HANDLE"
export const SET_HANDLE_ERROR = "SET_HANDLE_ERROR"
export const SET_HANDLE_TEXT_HELPER = "SET_HANDLE_TEXT_HELPER"
export const SET_LAST_NAME_ERROR = "SET_LAST_NAME_ERROR"
export const SET_LAST_NAME_TEXT_HELPER = "SET_LAST_NAME_TEXT_HELPER"
export const SET_FIRST_NAME_ERROR = "SET_FIRST_NAME_ERROR"
export const SET_FIRST_NAME_TEXT_HELPER = "SET_FIRST_NAME_TEXT_HELPER"
export const SET_FIRST_NAME = "SET_FIRST_NAME"
export const SET_LAST_NAME = "SET_LAST_NAME"
export const SET_PASSWORD = "SET_PASSWORD"
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR"
export const SET_PASSWORD_TEXT_HELPER = "SET_PASSWORD_TEXT_HELPER"
export const SET_PASSWORD_CONFIRM = "SET_PASSWORD_CONFIRM"
export const SET_PASSWORD_CONFIRM_ERROR = "SET_PASSWORD_CONFIRM_ERROR"
export const SET_PASSWORD_CONFIRM_TEXT_HELPER = "SET_PASSWORD_CONFIRM_TEXT_HELPER"
export const SET_PASSWORD_TEXT_FIELD_TYPE = "SET_PASSWORD_TEXT_FIELD_TYPE"
export const SET_SELECTED_PROFILE_PHOTO_AVATAR = "SET_SELECTED_PROFILE_PHOTO_AVATAR"
export const SET_SELECTED_PROFILE_PHOTO_AVATAR_PROGRESS = "SET_SELECTED_PROFILE_PHOTO_AVATAR_PROGRESS"
export const FETCH_HOMETOWN_AIRPORT_DATA = "FETCH_HOMETOWN_AIRPORT_DATA"
export const FETCH_HOMETOWN_AIRPORT_API_ERROR = "FETCH_HOMETOWN_AIRPORT_API_ERROR"
export const SET_HOMETOWN_AIRPORT_SELECTED = "SET_HOMETOWN_AIRPORT_SELECTED"
export const SET_HOMETOWN_AIRPORT_TEXT_HELPER = "SET_HOMETOWN_AIRPORT_TEXT_HELPER"
export const SET_HOMETOWN_AIRPORT_ERROR = "SET_HOMETOWN_AIRPORT_ERROR"
export const SET_HOMETOWN_AIRPORT_AUTOCOMPLETE_STATUS = "SET_HOMETOWN_AIRPORT_AUTOCOMPLETE_STATUS"
export const FETCH_LEAVING_FROM_AIRPORT_DATA = "FETCH_LEAVING_FROM_AIRPORT_DATA"
export const FETCH_LEAVING_FROM_AIRPORT_API_ERROR = "FETCH_LEAVING_FROM_AIRPORT_API_ERROR"
export const SET_LEAVING_FROM_AIRPORT_SELECTED = "SET_LEAVING_FROM_AIRPORT_SELECTED"
export const SET_LEAVING_FROM_AIRPORT_TEXT_HELPER = "SET_LEAVING_FROM_AIRPORT_TEXT_HELPER"
export const SET_LEAVING_FROM_AIRPORT_ERROR = "SET_LEAVING_FROM_AIRPORT_ERROR"
export const SET_LEAVING_FROM_AIRPORT_AUTOCOMPLETE_STATUS = "SET_LEAVING_FROM_AIRPORT_AUTOCOMPLETE_STATUS"
export const FETCH_RETURNING_TO_AIRPORT_DATA = "FETCH_RETURNING_TO_AIRPORT_DATA"
export const FETCH_RETURNING_TO_AIRPORT_API_ERROR = "FETCH_RETURNING_TO_AIRPORT_API_ERROR"
export const SET_RETURNING_TO_AIRPORT_SELECTED = "SET_RETURNING_TO_AIRPORT_SELECTED"
export const SET_RETURNING_TO_AIRPORT_TEXT_HELPER = "SET_RETURNING_TO_AIRPORT_TEXT_HELPER"
export const SET_RETURNING_TO_AIRPORT_ERROR = "SET_RETURNING_TO_AIRPORT_ERROR"
export const SET_RETURNING_TO_AIRPORT_AUTOCOMPLETE_STATUS = "SET_RETURNING_TO_AIRPORT_AUTOCOMPLETE_STATUS"

export const FETCH_HOMETOWN_API_ERROR = "FETCH_HOMETOWN_API_ERROR"
export const SET_HOMETOWN_AUTOCOMPLETE_STATUS = "SET_HOMETOWN_AUTOCOMPLETE_STATUS"
export const SET_HOMETOWN_ERROR = "SET_HOMETOWN_ERROR"
export const SET_HOMETOWN_SELECTED = "SET_HOMETOWN_SELECTED"
export const SET_HOMETOWN_TEXT_HELPER = "SET_HOMETOWN_TEXT_HELPER"
export const SET_HOMETOWN_LOAD_GOOGLE_URL = "SET_HOMETOWN_LOAD_GOOGLE_URL"
export const SET_HOMETOWN_SEARCH_QUERY = "SET_HOMETOWN_SEARCH_QUERY"
export const SET_HOMETOWN_OPTIONS = "SET_HOMETOWN_OPTIONS"
export const SET_HOMETOWN_SERVICE_ACTIVE = "SET_HOMETOWN_SERVICE_ACTIVE"
export const SET_HOMETOWN_SELECTED_POST_REQUIRED = "SET_HOMETOWN_SELECTED_POST_REQUIRED"

export const FETCH_DESTINATION_VISITED_SEARCH = "FETCH_DESTINATION_VISITED_SEARCH"
export const FETCH_DESTINATION_VISITED_SEARCH_QUERY = "FETCH_DESTINATION_VISITED_SEARCH_QUERY"
export const FETCH_DESTINATION_VISITED_SEARCH_ERROR = "FETCH_DESTINATION_VISITED_SEARCH_ERROR"
export const FETCH_DESTINATION_VISITED_SEARCH_STATUS = "FETCH_DESTINATION_VISITED_SEARCH_STATUS"
export const SET_DESTINATION_VISITED_LOAD_GOOGLE_URL = "SET_DESTINATION_VISITED_LOAD_GOOGLE_URL"

export const FETCH_GENDER_DATA = "FETCH_GENDER_DATA"
export const FETCH_GENDER_API_ERROR = "FETCH_GENDER_API_ERROR"
export const SET_GENDER_SELECTED = "SET_GENDER_SELECTED"
export const SET_GENDER_TEXT_HELPER = "SET_GENDER_TEXT_HELPER"
export const SET_GENDER_ERROR = "SET_GENDER_ERROR"
export const SET_GENDER_COMPONENT_STATUS = "SET_GENDER_COMPONENT_STATUS"
export const SET_GENDER_LOAD_RESOURCE = "SET_GENDER_LOAD_RESOURCE"

export const SET_ORIENTATION_LOAD_RESOURCE = "SET_ORIENTATION_LOAD_RESOURCE"
export const SET_ORIENTATION_SELECTED = "SET_ORIENTATION_SELECTED"
export const FETCH_ORIENTATION_API_ERROR = "FETCH_ORIENTATION_API_ERROR"
export const FETCH_ORIENTATION_DATA = "FETCH_ORIENTATION_DATA"
export const SET_ORIENTATION_ERROR = "SET_ORIENTATION_ERROR"
export const SET_ORIENTATION_TEXT_HELPER = "SET_ORIENTATION_TEXT_HELPER"
export const SET_ORIENTATION_COMPONENT_STATUS = "SET_ORIENTATION_COMPONENT_STATUS"

export const SET_RELIGION_LOAD_RESOURCE = "SET_RELIGION_LOAD_RESOURCE"
export const SET_RELIGION_SELECTED = "SET_RELIGION_SELECTED"
export const FETCH_RELIGION_API_ERROR = "FETCH_RELIGION_API_ERROR"
export const FETCH_RELIGION_DATA = "FETCH_RELIGION_DATA"
export const SET_RELIGION_ERROR = "SET_RELIGION_ERROR"
export const SET_RELIGION_TEXT_HELPER = "SET_RELIGION_TEXT_HELPER"
export const SET_RELIGION_COMPONENT_STATUS = "SET_RELIGION_COMPONENT_STATUS"

export const SET_PROFESSION_LOAD_RESOURCE = "SET_PROFESSION_LOAD_RESOURCE"
export const SET_PROFESSION_SELECTED = "SET_PROFESSION_SELECTED"
export const FETCH_PROFESSION_API_ERROR = "FETCH_PROFESSION_API_ERROR"
export const FETCH_PROFESSION_DATA = "FETCH_PROFESSION_DATA"
export const SET_PROFESSION_ERROR = "SET_PROFESSION_ERROR"
export const SET_PROFESSION_TEXT_HELPER = "SET_PROFESSION_TEXT_HELPER"
export const SET_PROFESSION_COMPONENT_STATUS = "SET_PROFESSION_COMPONENT_STATUS"

export const SET_EDUCATION_LOAD_RESOURCE = "SET_EDUCATION_LOAD_RESOURCE"
export const SET_EDUCATION_SELECTED = "SET_EDUCATION_SELECTED"
export const FETCH_EDUCATION_API_ERROR = "FETCH_EDUCATION_API_ERROR"
export const FETCH_EDUCATION_DATA = "FETCH_EDUCATION_DATA"
export const SET_EDUCATION_ERROR = "SET_EDUCATION_ERROR"
export const SET_EDUCATION_TEXT_HELPER = "SET_EDUCATION_TEXT_HELPER"
export const SET_EDUCATION_COMPONENT_STATUS = "SET_EDUCATION_COMPONENT_STATUS"

export const SET_CULTURE_LOAD_RESOURCE = "SET_CULTURE_LOAD_RESOURCE"
export const SET_CULTURE_SELECTED = "SET_CULTURE_SELECTED"
export const FETCH_CULTURE_API_ERROR = "FETCH_CULTURE_API_ERROR"
export const FETCH_CULTURE_DATA = "FETCH_CULTURE_DATA"
export const SET_CULTURE_ERROR = "SET_CULTURE_ERROR"
export const SET_CULTURE_TEXT_HELPER = "SET_CULTURE_TEXT_HELPER"
export const SET_CULTURE_COMPONENT_STATUS = "SET_CULTURE_COMPONENT_STATUS"

export const SET_LANGUAGE_LOAD_RESOURCE = "SET_LANGUAGE_LOAD_RESOURCE"
export const SET_LANGUAGE_SELECTED = "SET_LANGUAGE_SELECTED"
export const FETCH_LANGUAGE_API_ERROR = "FETCH_LANGUAGE_API_ERROR"
export const FETCH_LANGUAGE_DATA = "FETCH_LANGUAGE_DATA"
export const SET_LANGUAGE_ERROR = "SET_LANGUAGE_ERROR"
export const SET_LANGUAGE_TEXT_HELPER = "SET_LANGUAGE_TEXT_HELPER"
export const SET_LANGUAGE_COMPONENT_STATUS = "SET_LANGUAGE_COMPONENT_STATUS"

export const SET_FLUENCY_LOAD_RESOURCE = "SET_FLUENCY_LOAD_RESOURCE"
export const SET_FLUENCY_SELECTED = "SET_FLUENCY_SELECTED"
export const FETCH_FLUENCY_API_ERROR = "FETCH_FLUENCY_API_ERROR"
export const FETCH_FLUENCY_DATA = "FETCH_FLUENCY_DATA"
export const SET_FLUENCY_ERROR = "SET_FLUENCY_ERROR"
export const SET_FLUENCY_TEXT_HELPER = "SET_FLUENCY_TEXT_HELPER"
export const SET_FLUENCY_COMPONENT_STATUS = "SET_FLUENCY_COMPONENT_STATUS"

export const SET_BIRTHDAY = "SET_BIRTHDAY"
export const SET_BIRTHDAY_ERROR = "SET_BIRTHDAY_ERROR"
export const SET_BIRTHDAY_TEXT_HELPER = "SET_BIRTHDAY_TEXT_HELPER"
export const SET_LANGUAGE_FLUENCY = "SET_LANGUAGE_FLUENCY"
export const SET_LANGUAGE_FLUENCY_SELECTED = "SET_LANGUAGE_FLUENCY_SELECTED"
export const REMOVE_LANGUAGE_FLUENCY_SELECTED = "REMOVE_LANGUAGE_FLUENCY_SELECTED"
export const REMOVE_EXIST_LANGUAGE_FLUENCY_SELECTED = "REMOVE_EXIST_LANGUAGE_FLUENCY_SELECTED"


export const SET_ABOUT_ME = "SET_ABOUT_ME"
export const SET_ABOUT_ME_ERROR = "SET_ABOUT_ME_ERROR"
export const SET_ABOUT_ME_TEXT_HELPER = "SET_ABOUT_ME_TEXT_HELPER"

export const SET_INSTAGRAM = "SET_INSTAGRAM"
export const SET_INSTAGRAM_ERROR = "SET_INSTAGRAM_ERROR"
export const SET_INSTAGRAM_TEXT_HELPER = "SET_INSTAGRAM_TEXT_HELPER"
export const SET_FACEBOOK = "SET_FACEBOOK"
export const SET_FACEBOOK_ERROR = "SET_FACEBOOK_ERROR"
export const SET_FACEBOOK_TEXT_HELPER = "SET_FACEBOOK_TEXT_HELPER"
export const SET_TWITTER = "SET_TWITTER"
export const SET_TWITTER_ERROR = "SET_TWITTER_ERROR"
export const SET_TWITTER_TEXT_HELPER = "SET_TWITTER_TEXT_HELPER"
export const SET_PINTEREST = "SET_PINTEREST"
export const SET_PINTEREST_ERROR = "SET_PINTEREST_ERROR"
export const SET_PINTEREST_TEXT_HELPER = "SET_PINTEREST_TEXT_HELPER"
export const SET_SNAPCHAT = "SET_SNAPCHAT"
export const SET_SNAPCHAT_ERROR = "SET_SNAPCHAT_ERROR"
export const SET_SNAPCHAT_TEXT_HELPER = "SET_SNAPCHAT_TEXT_HELPER"

export const SET_EMAIL_ADDRESS_LOGIN = "SET_EMAIL_ADDRESS_LOGIN"
export const SET_EMAIL_ADDRESS_LOGIN_ERROR = "SET_EMAIL_ADDRESS_LOGIN_ERROR"
export const SET_EMAIL_ADDRESS_LOGIN_TEXT_HELPER = "SET_EMAIL_ADDRESS_LOGIN_TEXT_HELPER"

export const SET_PASSWORD_LOGIN = "SET_PASSWORD_LOGIN"
export const SET_PASSWORD_LOGIN_ERROR = "SET_PASSWORD_LOGIN_ERROR"
export const SET_PASSWORD_LOGIN_TEXT_HELPER = "SET_PASSWORD_LOGIN_TEXT_HELPER"
export const SET_PASSWORD_LOGIN_TEXT_FIELD_TYPE = "SET_PASSWORD_LOGIN_TEXT_FIELD_TYPE"

export const FETCH_GENERAL_SEARCH_ERROR = "FETCH_GENERAL_SEARCH_ERROR"
export const FETCH_GENERAL_SEARCH = "FETCH_GENERAL_SEARCH"
export const FETCH_GENERAL_SEARCH_STATUS = "FETCH_GENERAL_SEARCH_STATUS"
export const FETCH_GENERAL_SEARCH_QUERY = "FETCH_GENERAL_SEARCH_QUERY"
export const SET_GENERAL_SEARCH_SEARCHER_ID = "SET_GENERAL_SEARCH_SEARCHER_ID"


export const SET_LAST_NAME_COMPANION = "SET_LAST_NAME_COMPANION"
export const SET_LAST_NAME_ERROR_COMPANION = "SET_LAST_NAME_ERROR_COMPANION"
export const SET_LAST_NAME_TEXT_HELPER_COMPANION = "SET_LAST_NAME_TEXT_HELPER_COMPANION"
export const SET_FIRST_NAME_COMPANION = "SET_FIRST_NAME_COMPANION"
export const SET_FIRST_NAME_ERROR_COMPANION = "SET_FIRST_NAME_ERROR_COMPANION"
export const SET_FIRST_NAME_TEXT_HELPER_COMPANION = "SET_FIRST_NAME_TEXT_HELPER_COMPANION"
export const SET_EMAIL_ADDRESS_TEXT_HELPER_COMPANION = "SET_EMAIL_ADDRESS_TEXT_HELPER_COMPANION"
export const SET_EMAIL_ADDRESS_ERROR_COMPANION = "SET_EMAIL_ADDRESS_ERROR_COMPANION"
export const CEHCK_EMAIL_EXIST_SUCCESS_COMPANION = "CEHCK_EMAIL_EXIST_SUCCESS_COMPANION"
export const CEHCK_EMAIL_EXIST_FAILURE_COMPANION = "CEHCK_EMAIL_EXIST_FAILURE_COMPANION"
export const SET_EMAIL_ADDRESS_COMPANION = "SET_EMAIL_ADDRESS_COMPANION"



/* auth */
export const SET_AUTH_HANDLE = "SET_AUTH_HANDLE"
export const SET_AUTH_UUID = "SET_AUTH_UUID"
export const SET_MODAL_OPEN_AUTH = "SET_MODAL_OPEN_AUTH"
export const SET_AUTH_FIRST_NAME = "SET_AUTH_FIRST_NAME"
export const SET_AUTH_LAST_NAME = "SET_AUTH_LAST_NAME"

export const SET_MODAL_COOKIES_EXIST_AUTH = "SET_MODAL_COOKIES_EXIST_AUTH"
export const SET_MODAL_ROUTE_AUTH = "SET_MODAL_ROUTE_AUTH"


export const SET_MODAL_CURRENCY_AUTH = "SET_MODAL_CURRENCY_AUTH"
export const SET_MODAL_LANGUAGE_AUTH = "SET_MODAL_LANGUAGE_AUTH"
export const SET_MODAL_MEMBER_STATUS_AUTH = "SET_MODAL_MEMBER_STATUS_AUTH"
export const SET_MODAL_TOKEN_AUTH = "SET_MODAL_TOKEN_AUTH"
export const RESET_MEMBER_ACCOUNT  = "RESET_MEMBER_ACCOUNT"

/* destination */
export const FETCH_BREADCRUMB_DESTINATION = "FETCH_BREADCRUMB_DESTINATION"
export const FETCH_NESTED_IDS_DESTINATION = "FETCH_NESTED_IDS_DESTINATION"
export const FETCH_SLIDER_IMAGES_DESTINATION = "FETCH_SLIDER_IMAGES_DESTINATION"
export const FETCH_GENERAL_INFO_DESTINATION = "FETCH_GENERAL_INFO_DESTINATION"
export const FETCH_API_ERROR_DESTINATION = "FETCH_API_ERROR_DESTINATION"
export const FETCH_INFLUENCERS_DESTINATION = "FETCH_INFLUENCERS_DESTINATION"
export const FETCH_SUGGESTEDTRIPS_DESTINATION = "FETCH_SUGGESTEDTRIPS_DESTINATION"
export const FETCH_SORTING_OPTION_DESTINATION = "FETCH_SORTING_OPTION_DESTINATION"
export const UPDATE_SEARCH_QUERY_DESTINATION = "UPDATE_SEARCH_QUERY_DESTINATION"
export const SET_LIST_DISPLAY_FORMAT_DESTINATION = "SET_LIST_DISPLAY_FORMAT_DESTINATION"

export const SET_SEARCH_ESTABLISHMENT_TEXT_HELPER = "SET_SEARCH_ESTABLISHMENT_TEXT_HELPER"
export const SET_SEARCH_ESTABLISHMENT_AUTOCOMPLETE_STATUS = "SET_SEARCH_ESTABLISHMENT_AUTOCOMPLETE_STATUS"
export const SET_SEARCH_ESTABLISHMENT_ERROR = "SET_SEARCH_ESTABLISHMENT_ERROR"
export const FETCH_SEARCH_ESTABLISHMENT_API_ERROR = "FETCH_SEARCH_ESTABLISHMENT_API_ERROR"
export const FETCH_SEARCH_ESTABLISHMENT_DATA = "FETCH_SEARCH_ESTABLISHMENT_DATA"
export const SET_SEARCH_ESTABLISHMENT_SELECTED = "SET_SEARCH_ESTABLISHMENT_SELECTED"

export const MERGE_FEATURE_LIST_OFFSET_ZERO_FEATURE = "MERGE_FEATURE_LIST_OFFSET_ZERO_FEATURE"
export const MERGE_ALL_FEATURE_LIST_OFFSET_ZERO_FEATURE = "MERGE_ALL_FEATURE_LIST_OFFSET_ZERO_FEATURE"
export const CLEAR_FEATURE_LIST_OFFSET_ZERO_FEATURE = "CLEAR_FEATURE_LIST_OFFSET_ZERO_FEATURE"
export const SELECTED_FEATURE_SEARCH_FEATURE = "SELECTED_FEATURE_SEARCH_FEATURE"
export const FETCH_FEATURE_SEARCH_LIST_FEATURE = "FETCH_FEATURE_SEARCH_LIST_FEATURE"
export const FETCH_FEATURE_LIST_OFFSET_ZERO_FEATURE = "FETCH_FEATURE_LIST_OFFSET_ZERO_FEATURE"

export const FETCH_DESTINATION_SEARCH_LIST_FEATURE = "FETCH_DESTINATION_SEARCH_LIST_FEATURE"
export const FETCH_DESTINATION_LIST_OFFSET_ZERO_FEATURE = "FETCH_DESTINATION_LIST_OFFSET_ZERO_FEATURE"

export const SET_TOP_IMAGE_FEATURE = "SET_TOP_IMAGE_FEATURE"
export const FETCH_FEATURES_DESTINATION = "FETCH_FEATURES_DESTINATION"
export const FETCH_FEATURE_TYPES_DESTINATION = "FETCH_FEATURE_TYPES_DESTINATION"
export const DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER = "DESTINATION_SWITCH_FEATURE_TYPE_CONTAINER"
export const FETCH_FEATURE_SUBTYPES_DESTINATION = "FETCH_FEATURE_SUBTYPES_DESTINATION"
export const UPDATE_SELECTED_SUBTYPES_DESTINATION = "UPDATE_SELECTED_SUBTYPES_DESTINATION"
export const CLEAN_SELECTED_SUBTYPES_DESTINATION = "CLEAN_SELECTED_SUBTYPES_DESTINATION"
export const FETCH_TRIP_SETS_DESTINATION = "FETCH_TRIP_SETS_DESTINATION"
export const FETCH_BUCKET_LIST_DESTINATION = "FETCH_BUCKET_LIST_DESTINATION"
export const FETCH_ESTABLISMENT_SETS_DESTINATION = "FETCH_ESTABLISMENT_SETS_DESTINATION"
export const UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION = "UPDATE_LAZYLOADING_URL_REQUESTS_DESTINATION"
export const UPDATE_LAZYLOADING_OFFSET_DESTINATION = "UPDATE_LAZYLOADING_OFFSET_DESTINATION"
export const UPDATE_LAZYLOADING_LIMIT_DESTINATION = "UPDATE_LAZYLOADING_LIMIT_DESTINATION"
export const UPDATE_LAZYLOADING_DESTINATION = "UPDATE_LAZYLOADING_DESTINATION"
export const FETCH_TRIP_SETS_LAZYLOADING_DESTINATION = "FETCH_TRIP_SETS_LAZYLOADING_DESTINATION" 
export const FETCH_ESTABLISMENT_SETS_LAZYLOADING_DESTINATION = "FETCH_ESTABLISMENT_SETS_LAZYLOADING_DESTINATION"
export const FETCH_BUCKETLIST_SETS_LAZYLOADING_DESTINATION = "FETCH_BUCKETLIST_SETS_LAZYLOADING_DESTINATION"
export const UPDATE_LAZYLOADING_PROGRESS_DESTINATION = "UPDATE_LAZYLOADING_PROGRESS_DESTINATION"
export const UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION = "UPDATE_LAZYLOADING_IS_FATCH_ALL_DESTINATION"
export const SET_DESTINATION_ID_DESTINATION = "SET_DESTINATION_ID_DESTINATION"
export const FETCH_REVIEWS_AVERAGE_RATING_ESTABLISHMENT = "FETCH_REVIEWS_AVERAGE_RATING_ESTABLISHMENT"
export const FETCH_REVIEWS_ESTABLISHMENT = "FETCH_REVIEWS_ESTABLISHMENT"
export const FETCH_GOOGLE_AND_YELP_REVIEWS_ESTABLISHMENT = "FETCH_GOOGLE_AND_YELP_REVIEWS_ESTABLISHMENT"
export const SET_USER_REVIEWS_RATINGS_ESTABLISHMENT = "SET_USER_REVIEWS_RATINGS_ESTABLISHMENT"
export const FETCH_EXPERIENCES_ESTABLISHMENT = "FETCH_EXPERIENCES_ESTABLISHMENT"
export const FETCH_NEIGHBORHOOD_ESTABLISHMENT = "FETCH_NEIGHBORHOOD_ESTABLISHMENT"
export const FETCH_SLIDER_IMAGES_ESTABLISHMENT = "FETCH_SLIDER_IMAGES_ESTABLISHMENT"
export const FETCH_API_ERROR_ESTABLISHMENT = "FETCH_API_ERROR_ESTABLISHMENT"
export const SET_ESTABLISHMENT_ID_ESTABLISHMENT = "SET_ESTABLISHMENT_ID_ESTABLISHMENT"
export const FETCH_GENERAL_INFO_ESTABLISHMENT = "FETCH_GENERAL_INFO_ESTABLISHMENT"
export const FETCH_SUBTYPES_ESTABLISHMENT = "FETCH_SUBTYPES_ESTABLISHMENT"
export const FETCH_BREADCRUMB_ESTABLISHMENT = "FETCH_BREADCRUMB_ESTABLISHMENT"
export const FETCH_GENERAL_INFO_BUCKETLIST = "FETCH_GENERAL_INFO_BUCKETLIST"
export const FETCH_SUBTYPES_BUCKETLIST = "FETCH_SUBTYPES_BUCKETLIST"
export const FETCH_BREADCRUMB_BUCKETLIST = "FETCH_BREADCRUMB_BUCKETLIST" 
export const FETCH_API_ERROR_BUCKETLIST = "FETCH_API_ERROR_BUCKETLIST"
export const FETCH_REVIEWS_BUCKETLIST = "FETCH_REVIEWS_BUCKETLIST"
export const FETCH_REVIEWS_AVERAGE_RATING_BUCKETLIST = "FETCH_REVIEWS_AVERAGE_RATING_BUCKETLIST"
export const FETCH_GOOGLE_AND_YELP_REVIEWS_BUCKETLIST = "FETCH_GOOGLE_AND_YELP_REVIEWS_BUCKETLIST"
export const SET_USER_REVIEWS_RATINGS_BUCKETLIST = "SET_USER_REVIEWS_RATINGS_BUCKETLIST"
export const SET_BUCKETLIST_ID_BUCKETLIST = "SET_BUCKETLIST_ID_BUCKETLIST"
export const FETCH_SLIDER_IMAGES_BUCKETLIST = "FETCH_SLIDER_IMAGES_BUCKETLIST"


/*  discovery*/
export const FETCH_DISCOVERY = "FETCH_DISCOVERY"
export const FETCH_DISCOVERY_ERROR = "FETCH_DISCOVERY_ERROR"
export const IS_FETCHED_DISCOVERY = "IS_FETCHED_DISCOVERY"
export const SWITCH_DISCOVER_EXTENDER = "SWITCH_DISCOVER_EXTENDER"

/* Home page */

export const FETCH_HOMEPAGE_FAILURE = "FETCH_HOMEPAGE_FAILURE"
export const FETCH_HOMEPAGE_SUCCESS = "FETCH_HOMEPAGE_SUCCESS"
export const FETCH_HOMEPAGE_MUVER_STYLE_FAILURE = "FETCH_HOMEPAGE_MUVER_STYLE_FAILURE"
export const FETCH_HOMEPAGE_MUVER_STYLE_SUCCESS = "FETCH_HOMEPAGE_MUVER_STYLE_SUCCESS"
export const FETCH_HOMEPAGE_MUVER_PRESS_FAILURE = "FETCH_HOMEPAGE_MUVER_PRESS_FAILURE"
export const FETCH_HOMEPAGE_MUVER_PRESS_SUCCESS = "FETCH_HOMEPAGE_MUVER_PRESS_SUCCESS"
export const FETCH_DESTINATION_LIST_FAILURE = "FETCH_DESTINATION_LIST_FAILURE"
export const FETCH_DESTINATION_LIST_SUCCESS = "FETCH_DESTINATION_LIST_SUCCESS"
export const CREATE_TRIP_FAILURE = "CREATE_TRIP_FAILURE"
export const CREATE_TRIP_SUCCESS = "CREATE_TRIP_SUCCESS"
export const FETCH_CITIES_LIST_SUCCESS = "FETCH_CITIES_LIST_SUCCESS"
export const FETCH_CITIES_LIST_FAILURE = "FETCH_CITIES_LIST_FAILURE"
export const PL_FETCH_CITIES_LIST_SUCCESS = "PL_FETCH_CITIES_LIST_SUCCESS"
export const PL_FETCH_CITIES_LIST_FAILURE = "PL_FETCH_CITIES_LIST_FAILURE"
export const FETCH_AIRPORT_LIST_SUCCESS = "FETCH_AIRPORT_LIST_SUCCESS"
export const FETCH_AIRPORT_LIST_FAILURE = "FETCH_AIRPORT_LIST_FAILURE"
export const PL_FETCH_AIRPORT_LIST_SUCCESS = "PL_FETCH_AIRPORT_LIST_SUCCESS"
export const PL_FETCH_AIRPORT_LIST_FAILURE = "PL_FETCH_AIRPORT_LIST_FAILURE"
export const FETCH_HOMEPAGE_BUCKET_LIST_SUCCESS = "FETCH_HOMEPAGE_BUCKET_LIST_SUCCESS"
export const FETCH_HOMEPAGE_BUCKET_LIST_FAILURE = "FETCH_HOMEPAGE_BUCKET_LIST_FAILURE"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAILURE = "AUTH_FAILURE"

export const FETCH_HOMEPAGE_GENERAL_INFO_FAILURE = "FETCH_HOMEPAGE_GENERAL_INFO_FAILURE"
export const FETCH_HOMEPAGE_GENERAL_INFO_SUCCESS = "FETCH_HOMEPAGE_GENERAL_INFO_SUCCESS"
export const FETCH_HOMEPAGE_SLIDER_IMAGE_FAILURE = "FETCH_HOMEPAGE_SLIDER_IMAGE_FAILURE"
export const FETCH_HOMEPAGE_SLIDER_IMAGE_SUCCESS = "FETCH_HOMEPAGE_SLIDER_IMAGE_SUCCESS"










/*  member area */

export const FETCH_TIMELINE_MUV_HOTELS = "FETCH_TIMELINE_MUV_HOTELS"
export const SET_CONTAINER_ROUTER = "SET_CONTAINER_ROUTER"
export const FETCH_MEMBER_DREAM_TRIPS = "FETCH_MEMBER_DREAM_TRIPS"
export const FETCH_MEMBER_DREAM_TRIPS_ERROR = "FETCH_MEMBER_DREAM_TRIPS_ERROR"
export const FETCH_MEMBER_FUTURE_TRIPS = "FETCH_MEMBER_FUTURE_TRIPS"
export const FETCH_MEMBER_FUTURE_TRIPS_ERROR = "FETCH_MEMBER_FUTURE_TRIPS_ERROR"
export const FETCH_MEMBER_PAST_TRIPS = "FETCH_MEMBER_PAST_TRIPS"
export const FETCH_MEMBER_PAST_TRIPS_ERROR = "FETCH_MEMBER_PAST_TRIPS_ERROR"
export const FETCH_MEMBER_MUVER = "FETCH_MEMBER_MUVER"
export const FETCH_MEMBER_MUVER_ERROR = "FETCH_MEMBER_MUVER_ERROR"




/* cookies */

export const SET_SELECTED_MEMBER_ACTIONS = "SET_SELECTED_MEMBER_ACTIONS"
export const FETCH_FEATURE_MEMBER_ACTIONS = "FETCH_FEATURE_MEMBER_ACTIONS"
export const FETCH_API_ERROR_MEMBER_ACTIONS = "FETCH_API_ERROR_MEMBER_ACTIONS"
export const FETCH_MEMBER_ACTIONS_MEMBER_ACTIONS = "FETCH_MEMBER_ACTIONS_MEMBER_ACTIONS"
export const IS_FETCHED_MEMBER_ACTIONS_MEMBER_ACTIONS = "IS_FETCHED_MEMBER_ACTIONS_MEMBER_ACTIONS"
export const IS_FETCHED_FEATURES_MEMBER_ACTIONS = "IS_FETCHED_FEATURES_MEMBER_ACTIONS"
export const FETCH_STAR_SETS_MEMBER_ACTIONS = "FETCH_STAR_SETS_MEMBER_ACTIONS"
export const FETCH_HEART_SETS_MEMBER_ACTIONS = "FETCH_HEART_SETS_MEMBER_ACTIONS"
export const FETCH_DONE_SETS_MEMBER_ACTIONS = "FETCH_DONE_SETS_MEMBER_ACTIONS"
export const FETCH_PLUS_SETS_MEMBER_ACTIONS = "FETCH_PLUS_SETS_MEMBER_ACTIONS"
export const IS_FETCHED_STARS_MEMBER_ACTIONS = "IS_FETCHED_STARS_MEMBER_ACTIONS"
export const IS_FETCHED_HEART_MEMBER_ACTIONS = "IS_FETCHED_HEART_MEMBER_ACTIONS"
export const IS_FETCHED_PLUS_MEMBER_ACTIONS = "IS_FETCHED_PLUS_MEMBER_ACTIONS"
export const IS_FETCHED_DONES_MEMBER_ACTIONS = "IS_FETCHED_DONES_MEMBER_ACTIONS"
export const SET_COOKIES_MEMBER_ACTIONS_SETS = "SET_COOKIES_MEMBER_ACTIONS_SETS"

export const FETCH_MEMBER_ACTION_SETS = "FETCH_MEMBER_ACTION_SETS"

/* followers */

export const FETCH_FOLLOWERS_SETS = "FETCH_FOLLOWERS_SETS"
export const IS_FETCHED_FOLLOWERS_SETS = "IS_FETCHED_FOLLOWERS_SETS"

/* personalization */
export const FETCH_PERSONALIZATION = "FETCH_PERSONALIZATION"
export const FETCH_PERSONALIZATION_TYPE_SETS = "FETCH_PERSONALIZATION_TYPE_SETS"
export const FETCH_PERSONALIZATION_SWITCH_CONTAINER = "FETCH_PERSONALIZATION_SWITCH_CONTAINER"
export const UPDATE_PERSONALIZATION_SCORE = "UPDATE_PERSONALIZATION_SCORE"
export const UPDATE_PERSONALIZATION_BARS_SCORE = "UPDATE_PERSONALIZATION_BARS_SCORE"


/*  booking form  */
export const SWITCH_BOOKING_FORM_ACTIVE_TAB = "SWITCH_BOOKING_FORM_ACTIVE_TAB"
export const SWITCH_BOOKING_FORM_STATUS = "SWITCH_BOOKING_FORM_STATUS"

/* timeline */
//search model
export const SET_SEARCH_PLACE_AUTOCOMPLETE_STATUS = "SET_SEARCH_PLACE_AUTOCOMPLETE_STATUS"
export const SET_SEARCH_PLACE_TEXT_HELPER = "SET_SEARCH_PLACE_TEXT_HELPER"
export const FETCH_SEARCH_PLACE_API_ERROR = "FETCH_SEARCH_PLACE_API_ERROR"
export const FETCH_SEARCH_PLACE_DATA = "FETCH_SEARCH_PlACE_DATA"
export const SET_SEARCH_PLACE_ERROR = "SET_SEARCH_PLACE_ERROR"
export const SET_SEARCH_PLACE_SELECTED = "SET_SEARCH_PLACE_SELECTED"


export const SET_SEARCH_DESTINATION_TEXT_HELPER = "SET_SEARCH_DESTINATION_TEXT_HELPER"
export const FETCH_SEARCH_DESTINATION_API_ERROR = "FETCH_SEARCH_DESTINATION_API_ERROR"
export const SET_SEARCH_DESTINATION_ERROR = "SET_SEARCH_DESTINATION_ERROR"
export const FETCH_SEARCH_DESTINATION_DATA = "FETCH_SEARCH_DESTINATION_DATA"
export const SET_SEARCH_DESTINATION_AUTOCOMPLETE_STATUS = "SET_SEARCH_DESTINATION_AUTOCOMPLETE_STATUS"
export const SET_SEARCH_DESTINATION_SELECTED = "SET_SEARCH_DESTINATION_SELECTED"


export const SET_SEARCH_ADDRESS_AUTOCOMPLETE_STATUS = "SET_SEARCH_ADDRESS_AUTOCOMPLETE_STATUS"
export const FETCH_SEARCH_ADDRESS_DATA = "FETCH_SEARCH_ADDRESS_DATA"
export const SET_SEARCH_ADDRESS_SELECTED = "SET_SEARCH_ADDRESS_SELECTED"
export const SET_SEARCH_ADDRESS_TEXT_HELPER = "SET_SEARCH_ADDRESS_TEXT_HELPER"
export const FETCH_SEARCH_ADDRESS_API_ERROR = "FETCH_SEARCH_ADDRESS_API_ERROR"
export const SET_SEARCH_ADDRESS_ERROR = "SET_SEARCH_ADDRESS_ERROR"



export const SET_TIMELINE_ACTIVE_TRIP_ID = "SET_TIMELINE_ACTIVE_TRIP_ID"
export const SET_TIMELINE_ACTIVE_TRIP_NAME = "SET_TIMELINE_ACTIVE_TRIP_NAME"
export const SET_TIMELINE_ACTIVE_TRIP_START_DATE = "SET_TIMELINE_ACTIVE_TRIP_START_DATE"
export const SET_TIMELINE_WIZARD_STATUS = "SET_TIMELINE_WIZARD_STATUS"
export const SET_TIMELINE_WIZARD_DELETE_ALART_STATUS = "SET_TIMELINE_WIZARD_DELETE_ALART_STATUS"
export const SET_TIMELINE_ACTIVE_TRIP_DURATION = "SET_TIMELINE_ACTIVE_TRIP_DURATION"
export const SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION = "SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION"
export const SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS = "SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS"
export const SET_TIMELINE_ACTIVE_TRIP_IMAGE = "SET_TIMELINE_ACTIVE_TRIP_IMAGE"
export const SET_TIMELINE_ACTIVE_TRIP_NO_DATE = "SET_TIMELINE_ACTIVE_TRIP_NO_DATE"
export const SET_TIMELINE_ACTIVE_TRIP_COST = "SET_TIMELINE_ACTIVE_TRIP_COST"
export const SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS = "SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS"
export const SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS = "SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS"
export const SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_OTHERS = "SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_OTHERS"
export const SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS = "SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS"
export const SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_ALL = "SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_ALL"
export const SET_TIMELINE_TRIP_DESTINATIONS_STATUS = "SET_TIMELINE_TRIP_DESTINATIONS_STATUS"
export const SWRAP_TRIP_DESTINATION_IN_TRIP = "SWRAP_TRIP_DESTINATION_IN_TRIP"
export const SET_ACTIVE_TRIP_DESTINATION = "SET_ACTIVE_TRIP_DESTINATION"
export const DRAG_DROP_TRIP_DESTINATION_IN_TRIP = "DRAG_DROP_TRIP_DESTINATION_IN_TRIP"
export const UPDATE_TRIP_DESTINATION_IN_TRIP = "UPDATE_TRIP_DESTINATION_IN_TRIP"
export const ADD_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS = "ADD_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS"
export const DELETE_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS = "DELETE_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS"
export const SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_OPEN_SELECTED = "SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_OPEN_SELECTED"
export const SET_ACTIVE_DAY_IN_TRIP = "SET_ACTIVE_DAY_IN_TRIP"
export const FETCH_ACTIVE_ACTIVITIES_IN_TRIP = "FETCH_ACTIVE_ACTIVITIES_IN_TRIP"
export const SET_ADDED_ACTIVITY_IN_TRIP = "SET_ADDED_ACTIVITY_IN_TRIP"
export const FETCH_TIME_TO_REACH_OPTIONS = "FETCH_TIME_TO_REACH_OPTIONS"
export const FETCH_TRANSPORT_MODES = "FETCH_TRANSPORT_MODES"
export const CLEAR_TIME_TO_REACH_OPTIONS = "CLEAR_TIME_TO_REACH_OPTIONS"
export const ADD_ACTIVITY_IN_ACTIVE_TRIP = "ADD_ACTIVITY_IN_ACTIVE_TRIP"
export const SET_TIMELINE_WIZARD_DRAGGING_ESTABLISHMENT = "SET_TIMELINE_WIZARD_DRAGGING_ESTABLISHMENT"
export const SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS = "SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS"
export const SET_TIMELINE_WIZARD_DRAGGING_ACTIVITY = "SET_TIMELINE_WIZARD_DRAGGING_ACTIVITY"
export const CLEAR_ALL_TIMELINE = "CLEAR_ALL_TIMELINE"
export const CLEAR_TIMELINE_TRIP = "CLEAR_TIMELINE_TRIP"
//map 
export const FETCH_PLACES_NEAR_BY_CENTER = "FETCH_PLACES_NEAR_BY_CENTER"


//feature
export const SET_FEATURE_FORMAT = "SET_FEATURE_FORMAT"

export const SET_LOADING_FEATURE_LIST_OFFSET_FEATURE = "SET_LOADING_FEATURE_LIST_OFFSET_FEATURE"
export const SET_FEATURE_ID_FEATURE = "SET_FEATURE_ID_FEATURE"
export const FETCH_SORTING_OPTION_FEATURE = "FETCH_SORTING_OPTION_FEATURE"
export const FETCH_FEATURE_TYPES_FEATURE = "FETCH_FEATURE_TYPES_FEATURE"
export const SET_LIST_DISPLAY_FORMAT_FEATURE = "SET_LIST_DISPLAY_FORMAT_FEATURE"
export const FETCH_API_ERROR_FEATURE = "FETCH_API_ERROR_FEATURE"
export const FETCH_FEATURE_SUBTYPES_FEATURE = "FETCH_FEATURE_SUBTYPES_FEATURE"
export const UPDATE_SELECTED_SUBTYPES_FEATURE = "UPDATE_SELECTED_SUBTYPES_FEATURE"
export const UPDATE_SELECTED_TYPES_FEATURE = "UPDATE_SELECTED_TYPES_FEATURE"
export const CLEAN_SELECTED_SUBTYPES_FEATURE = "CLEAN_SELECTED_SUBTYPES_FEATURE"
export const SELECTED_SORTING_OPTION_FEATURE = "SELECTED_SORTING_OPTION_FEATURE"
export const FETCH_RELATIVE_DESTINATIONS_FEATURE = "FETCH_RELATIVE_DESTINATIONS_FEATURE"
export const CLEAN_SELECTED_DESTINATIONS_FEATURE = "CLEAN_SELECTED_DESTINATIONS_FEATURE"
export const UPDATE_SELECTED_DESTINATIONS_FEATURE = "UPDATE_SELECTED_DESTINATIONS_FEATURE"
export const SET_APP_MENU_BOOKING_FORM_STATUS = "SET_APP_MENU_BOOKING_FORM_STATUS"

/* hotel search */
export const SET_HOTEL_SEARCH_FORM = "SET_HOTEL_SEARCH_FORM"
export const PL_FETCH_HOTELS_LIST_SUCCESS = "PL_FETCH_HOTELS_LIST_SUCCESS"
export const PL_FETCH_HOTELS_LIST_FAILURE = "PL_FETCH_HOTELS_LIST_FAILURE"
export const CONVERT_HOTELS_TO_ESTABLISHMENTS = "CONVERT_HOTELS_TO_ESTABLISHMENTS"
export const UPDATE_LAZYLOADING_URL_REQUESTS_HOTELS_LIST = "UPDATE_LAZYLOADING_URL_REQUESTS_HOTELS_LIST"
export const UPDATE_LAZYLOADING_OFFSET_HOTELS_LIST = "UPDATE_LAZYLOADING_OFFSET_HOTELS_LIST"
export const UPDATE_LAZYLOADING_LIMIT_HOTELS_LIST = "UPDATE_LAZYLOADING_LIMIT_HOTELS_LIST"
export const UPDATE_LAZYLOADING_HOTELS_LIST = "UPDATE_LAZYLOADING_HOTELS_LIST"
export const UPDATE_LAZYLOADING_PROGRESS_HOTELS_LIST = "UPDATE_LAZYLOADING_PROGRESS_HOTELS_LIST"
export const UPDATE_LAZYLOADING_NO_PROGRESS_HOTELS_LIST = "UPDATE_LAZYLOADING_NO_PROGRESS_HOTELS_LIST"
export const FETCH_ESTABLISMENT_SETS_LAZYLOADING_HOTELS_LIST = "FETCH_ESTABLISMENT_SETS_LAZYLOADING_HOTELS_LIST"
export const FETCH_SORTING_OPTION_HOTELS_LIST = "FETCH_SORTING_OPTION_HOTELS_LIST" 
export const UPDATE_SEARCH_QUERY_HOTELS_LIST = "UPDATE_SEARCH_QUERY_HOTELS_LIST"
export const FETCH_API_ERROR_HOTELS_LIST = "FETCH_API_ERROR_HOTELS_LIST"
export const UPDATE_LAZYLOADING_NO_MORE_HOTELS = "UPDATE_LAZYLOADING_NO_MORE_HOTELS"
export const FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT = "FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT"
export const FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT_FAILURE = "FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT_FAILURE"
export const SET_HOTEL_ESTABLISHMENT_ID_ESTABLISHMENT = "SET_HOTEL_ESTABLISHMENT_ID_ESTABLISHMENT"
export const FETCH_SLIDER_IMAGES_HOTEL_ESTABLISHMENT = "FETCH_SLIDER_IMAGES_HOTEL_ESTABLISHMENT"
export const FETCH_REVIEWS_HOTEL_ESTABLISHMENT = "FETCH_REVIEWS_HOTEL_ESTABLISHMENT"
export const FETCH_GENERAL_INFO_ROOMS = "FETCH_GENERAL_INFO_ROOMS"
export const FETCH_GENERAL_INFO_ROOMS_FAILURE = "FETCH_GENERAL_INFO_ROOMS_FAILURE"
export const SET_HOTEL_FORM_STATE = "SET_HOTEL_FORM_STATE"
export const SET_BOOKING_FORM_STATE = "SET_BOOKING_FORM_STATE"
export const FETCH_CONTRACT_INFO_HOTEL_BOOKING = "FETCH_CONTRACT_INFO_HOTEL_BOOKING"
export const FETCH_CONTRACT_INFO_HOTEL_BOOKING_FAILURE = "FETCH_CONTRACT_INFO_HOTEL_BOOKING_FAILURE"
export const ADD_BUNDLE_TO_HOTEL_BOOKING = "ADD_BUNDLE_TO_HOTEL_BOOKING"
export const ADD_BUNDLE_TO_HOTEL_BOOKING_FAILURE = "ADD_BUNDLE_TO_HOTEL_BOOKING_FAILURE"
export const REMOVE_BUNDLE_TO_HOTEL_BOOKING = "REMOVE_BUNDLE_TO_HOTEL_BOOKING"
export const REMOVE_BUNDLE_TO_HOTEL_BOOKING_FAILURE = "REMOVE_BUNDLE_TO_HOTEL_BOOKING_FAILURE"
export const FETCH_SUBTYPES_HOTELS_LIST = "FETCH_SUBTYPES_HOTELS_LIST"
export const UPDATE_SELECTED_SUBTYPES_HOTELS_LIST = "UPDATE_SELECTED_SUBTYPES_HOTELS_LIST"
export const CLEAN_SELECTED_SUBTYPES_HOTELS_LIST = "CLEAN_SELECTED_SUBTYPES_HOTELS_LIST"
export const SET_ESTABLISHMENTS_TO_FILTER = "SET_ESTABLISHMENTS_TO_FILTER"
export const SET_FILTERED_ESTABLISHMENTS = "SET_FILTERED_ESTABLISHMENTS"
export const SET_HOTEL_ESTABLISHMENTS_COPY = "SET_HOTEL_ESTABLISHMENTS_COPY"
export const SET_HOTEL_SEARCH_IS_NEW = "SET_HOTEL_SEARCH_IS_NEW"
export const UPDATE_LAZYLOADING_SORT_BY = "UPDATE_LAZYLOADING_SORT_BY"
export const SET_HOTEL_SEARCH_IS_SEARCHING = "SET_HOTEL_SEARCH_IS_SEARCHING"
export const SET_SLIDER_INDEX = "SET_SLIDER_INDEX"






