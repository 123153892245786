/* eslint-disable array-callback-return */

import "./ItineraryMap.css";

import { ArrowLeftIcon, ArrowRightIcon } from "../Common/SVGBank"
import { CheckBoxIcon, CheckedBoxIcon } from "../Common/SVGBank"
import { DirectionsRenderer, GoogleMap, Marker, withGoogleMap, } from "react-google-maps"
import { animated, useSpring } from 'react-spring'
import { compose, lifecycle, withProps } from "recompose"
import { useHistory } from "react-router-dom"
import EstablishmentName from '../Common/Establishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import PropTypes from "prop-types"
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { image_boxing } from '../Common/RegExValidate'
import mapStyles from "./mapStyles"
import moment from 'moment'
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { setTimeLineStatus } from "../../../redux/actions";
import { generateMapAPIKey } from "../../../utility/utility";
import { DarkTooltip } from '../Common/MemberActions/tooltipActions'
import Tooltip from '@material-ui/core/Tooltip';

/* wrap map component */
const WrappedMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${generateMapAPIKey()}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
    lifecycle({
        componentDidUpdate(prevProps) {
            if (prevProps.trip.tripID !== this.props.trip.tripID
                || prevProps.activeActivitiesInDay !== this.props.activeActivitiesInDay) {
                let waypts = []
                let first_lat = ""
                let first_lng = ""
                this.props.activeActivitiesInDay.map((activity) => {
                    first_lat = activity.activityLatitude
                    first_lng = activity.activityLongitude
                    waypts.push(new window.google.maps.LatLng({ lat: parseFloat(activity.activityLatitude), lng: parseFloat(activity.activityLongitude) }))
                })
                let first = waypts.shift();
                let last = waypts.pop()
                let way_points = waypts.map(obj => {
                    let key_obj = {}
                    key_obj["location"] = obj
                    key_obj["stopover"] = true
                    return key_obj
                })
                if (first !== undefined && last !== undefined) {
                    const DirectionsService = new window.google.maps.DirectionsService();

                    DirectionsService.route({
                        origin: first,
                        destination: last,
                        waypoints: way_points,
                        provideRouteAlternatives: true,
                        optimizeWaypoints: true,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    }, (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            this.setState({
                                directions: { ...result },
                                display: true
                            });
                        } else {
                            console.log(`Driving error fetching directions ${result}`);
                            DirectionsService.route({
                                origin: first,
                                destination: last,
                                waypoints: way_points,
                                provideRouteAlternatives: true,
                                optimizeWaypoints: true,
                                travelMode: window.google.maps.TravelMode.WALKING,
                            }, (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    this.setState({
                                        directions: { ...result },
                                        display: true
                                    });
                                } else {
                                    console.error(`Walking error fetching directions ${result}`);
                                }
                            });
                        }
                    });
                } else if (first !== undefined) {
                    this.setState({
                        directions: null,
                        display: false,
                    });
                    this.props.setMapCenter({
                        lat: parseFloat(first_lat),
                        lng: parseFloat(first_lng)
                    })
                } else {
                    this.setState({
                        directions: null,
                        display: false
                    });
                }
            }
        },
        componentDidMount() {
            let waypts = []
            let first_lat = ""
            let first_lng = ""
            this.props.activeActivitiesInDay.map((activity) => {
                first_lat = activity.activityLatitude
                first_lng = activity.activityLongitude
                waypts.push(new window.google.maps.LatLng({ lat: parseFloat(activity.activityLatitude), lng: parseFloat(activity.activityLongitude) }))
            })
            let first = waypts.shift();
            let last = waypts.pop()
            let way_points = waypts.map(obj => {
                let key_obj = {}
                key_obj["location"] = obj
                key_obj["stopover"] = true
                return key_obj
            })
            if (first !== undefined && last !== undefined) {
                const DirectionsService = new window.google.maps.DirectionsService();

                DirectionsService.route({
                    origin: first,
                    destination: last,
                    waypoints: way_points,
                    provideRouteAlternatives: true,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({
                            directions: { ...result },
                            display: true
                        });
                    } else {
                        console.log(`Driving error fetching directions ${result}`);
                        DirectionsService.route({
                            origin: first,
                            destination: last,
                            waypoints: way_points,
                            provideRouteAlternatives: true,
                            optimizeWaypoints: true,
                            travelMode: window.google.maps.TravelMode.WALKING,
                        }, (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                                this.setState({
                                    directions: { ...result },
                                    display: true
                                });
                            } else {
                                console.error(`Walking error fetching directions ${result}`);
                            }
                        });
                    }
                });
            } else if (first !== undefined) {
                this.setState({
                    directions: null,
                    display: false
                });
                this.props.setMapCenter({
                    lat: parseFloat(first_lat),
                    lng: parseFloat(first_lng)
                })
            } else {
                this.setState({
                    directions: null,
                    display: false
                });
            }
        }
    })
)((props) =>
    <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.mapCenter}
        center={props.mapCenter}
        defaultOptions={{
            controlSize: 20,
            mapTypeControl: false,
            fullscreenControl: false,

            minZoom: 4,
            maxZoom: 18,
            styles: mapStyles
        }}
    >


        {props.activeActivitiesInDay.map((activity, index) => {
            return (
                <Marker
                    key={activity.activityID}
                    position={{ lat: parseFloat(activity.activityLatitude), lng: parseFloat(activity.activityLongitude) }}

                    onClick={() =>
                        props.setActiveActivityHandler(activity, index)
                    }
                    icon={{
                        url: index < 9 ?
                            activity.activityID === props.activeActivity.activityID ? `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                    <g fillRule="evenodd">    
                                        <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                        <text fontSize="15" fontWeight="bold" fill="white">
                                            <tspan x="19" y="27">`+ (++index) + `</tspan>
                                        </text>    
                                    </g>
                                    </svg>`:
                                `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                    <g fillRule="evenodd">    
                                        <circle cx="22.5" cy="22.5" r="12" fill="rgba(25, 188, 155, 1)" />
                                        <circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="rgba(25, 188, 155, 1)"/>
                                        <text fontSize="15" fontWeight="bold" fill="white">
                                            <tspan x="19" y="27">`+ (++index) + `</tspan>
                                        </text>    
                                    </g>
                                    </svg>`
                            :
                            activity.activityID === props.activeActivity.activityID ? `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                    <g fillRule="evenodd">    
                                        <circle cx="22.5" cy="22.5" r="10" stroke="rgba(25, 188, 155, 0.4)" stroke-width="20" fill="rgba(25, 188, 155, 1)" />
                                        <text fontSize="11" fontWeight="bold" fill="white">
                                            <tspan x="16" y="27">`+ (++index) + `</tspan>
                                        </text>    
                                    </g>
                                    </svg>`:
                                `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                    <g fillRule="evenodd">    
                                        <circle cx="22.5" cy="22.5" r="10" fill="rgba(25, 188, 155, 1)" />
                                        <circle width="45" height="45" cx="22.5" cy="22.5" r="20" fill="none" stroke-dasharray="2, 2" stroke-width="2" stroke="rgba(25, 188, 155, 1)"/>
                                        <text fontSize="11" fontWeight="bold" fill="white">
                                            <tspan x="16" y="27">`+ (++index) + `</tspan>
                                        </text>    
                                    </g>
                                    </svg>`,
                        size: { width: 50, height: 50 },
                        // anchor: {x: 0, y: 0},
                        scaledSize: { width: 50, height: 50 }
                    }}
                />

            )
        })}
        {props.display && props.directions &&
            <DirectionsRenderer
                directions={props.directions}
                options={{
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: "#19BC9B",
                        strokeOpacity: 0.5,
                        strokeWeight: 4
                    }
                }}
            />}

    </GoogleMap>
)

/* itinerary map component */
const PublicTripItineraryMap_ = ({ trip, screenSize, is_mobile }) => {

    const [previousActivity, setPreviousActivity] = React.useState(null)
    const [nextActivity, setNextActivity] = React.useState(null)
    const [activeActivityIndex, setActiveActivityIndex] = React.useState("1")
    const [activeActivity, setActiveActivity] = React.useState(null)
    const [activeDayInTrip, setActiveDayInTrip] = React.useState("1")
    const [activeActivities, setActiveActivities] = React.useState([])
    const [activeActivitiesInDay, setActiveActivitiesInDay] = React.useState([])
    const [activeTripDestinationIndex, setActiveTripDestinationIndex] = React.useState(0)
    const [mapCenter, setMapCenter] = React.useState(null)
    const [tripDestinations, setTripDestinations] = React.useState([])

    const dispatch = useDispatch();
    const history = useHistory()

    React.useEffect(() => {
        setTripDestinations(trip.tripDestinations)
        const tripDestinations = trip.tripDestinations
        clearPrevious()
        if (tripDestinations !== undefined && tripDestinations.length > 0 && tripDestinations[0] !== undefined) {
            tripDestinations.sort((a, b) => parseFloat(a?.startingDayInTrip) - parseFloat(b?.startingDayInTrip));
            setMapCenter({
                lat: parseFloat(tripDestinations[0].latitude),
                lng: parseFloat(tripDestinations[0].longitude)
            })
            tripDestinations !== undefined
                && tripDestinations.length >= 0
                && tripDestinations[0] !== undefined
                && tripDestinations[0].destinationDays !== undefined
                && tripDestinations[0].destinationDays[0] !== undefined
                && setActiveDayInTrip(tripDestinations[0].destinationDays[0].activityDay)
            tripDestinations !== undefined
                && tripDestinations.length >= 0
                && tripDestinations[0] !== undefined
                && tripDestinations[0].destinationDays !== undefined
                && tripDestinations[0].destinationDays[0] !== undefined
                && setActiveActivities(tripDestinations[0].destinationDays)
            tripDestinations !== undefined
                && tripDestinations.length >= 0
                && tripDestinations[0] !== undefined
                && tripDestinations[0].destinationDays !== undefined
                && tripDestinations[0].destinationDays[0] !== undefined
                && setActiveActivitiesInDay(tripDestinations[0].destinationDays.filter(a => a.activityDay === tripDestinations[0].destinationDays[0].activityDay))
            tripDestinations !== undefined
                && tripDestinations.length >= 0
                && tripDestinations[0] !== undefined
                && tripDestinations[0].destinationDays !== undefined
                && tripDestinations[0].destinationDays[0] !== undefined
                && tripDestinations[0].destinationDays.filter(a => a.activityDay === tripDestinations[0].destinationDays[0].activityDay).length > 0
                && setActiveActivity(tripDestinations[0].destinationDays.filter(a => a.activityDay === tripDestinations[0].destinationDays[0].activityDay)[0])

            setActiveActivityIndex("1")
            setPreviousActivity(null)
            tripDestinations !== undefined
                && tripDestinations.length >= 0
                && tripDestinations[0] !== undefined
                && tripDestinations[0].destinationDays !== undefined
                && tripDestinations[0].destinationDays[0] !== undefined
                && tripDestinations[0].destinationDays.filter(a => a.activityDay === tripDestinations[0].destinationDays[0].activityDay).length >= 2 &&
                tripDestinations[0].destinationDays.filter(a => a.activityDay === tripDestinations[0].destinationDays[0].activityDay)[1] !== undefined ?
                setNextActivity(tripDestinations[0].destinationDays.filter(a => a.activityDay === tripDestinations[0].destinationDays[0].activityDay)[1]) : setNextActivity(null)


        }
    }, [trip.tripDestinations, trip.tripID])


    const clearPrevious = () => {
        setActiveDayInTrip(null);
        setActiveActivities([])
        setActiveActivitiesInDay([])
        setActiveActivity(null)

    }

    const destinaitonRadioHandler = (num) => {
        clearPrevious()
        setActiveTripDestinationIndex(num)
        setMapCenter({
            lat: parseFloat(trip.tripDestinations[num].latitude),
            lng: parseFloat(trip.tripDestinations[num].longitude)
        })
        tripDestinations !== undefined
            && tripDestinations.length >= num
            && tripDestinations[num] !== undefined
            && tripDestinations[num].destinationDays !== undefined
            && tripDestinations[num].destinationDays[0] !== undefined
            && setActiveDayInTrip(tripDestinations[num].destinationDays[0].activityDay)
        tripDestinations !== undefined
            && tripDestinations.length >= num
            && tripDestinations[num] !== undefined
            && tripDestinations[num].destinationDays !== undefined
            && tripDestinations[num].destinationDays[0] !== undefined
            && setActiveActivities(tripDestinations[num].destinationDays)
        tripDestinations !== undefined
            && tripDestinations.length >= 0
            && tripDestinations[num] !== undefined
            && tripDestinations[num].destinationDays !== undefined
            && tripDestinations[num].destinationDays[0] !== undefined
            && setActiveActivitiesInDay(tripDestinations[num].destinationDays.filter(a => a.activityDay === tripDestinations[num].destinationDays[0].activityDay))

        tripDestinations !== undefined
            && tripDestinations.length >= 0
            && tripDestinations[num] !== undefined
            && tripDestinations[num].destinationDays !== undefined
            && tripDestinations[num].destinationDays[0] !== undefined
            && tripDestinations[num].destinationDays.filter(a => a.activityDay === tripDestinations[num].destinationDays[0].activityDay).length > 0
            && setActiveActivity(tripDestinations[num].destinationDays.filter(a => a.activityDay === tripDestinations[num].destinationDays[0].activityDay)[0])

        setActiveActivityIndex("1")
        setPreviousActivity(null)
        tripDestinations !== undefined
            && tripDestinations.length >= 0
            && tripDestinations[num] !== undefined
            && tripDestinations[num].destinationDays !== undefined
            && tripDestinations[num].destinationDays[0] !== undefined
            && tripDestinations[num].destinationDays.filter(a => a.activityDay === tripDestinations[num].destinationDays[0].activityDay).length >= 2 &&
            tripDestinations[num].destinationDays.filter(a => a.activityDay === tripDestinations[num].destinationDays[0].activityDay)[1] !== undefined ?
            setNextActivity(tripDestinations[num].destinationDays.filter(a => a.activityDay === tripDestinations[num].destinationDays[0].activityDay)[1]) : setNextActivity(null)
    }

    const daysCheckBoxHandler = (selectedDayInTrip) => {
        setActiveDayInTrip(selectedDayInTrip)
        activeActivities.length > 0 && setActiveActivitiesInDay(activeActivities.filter(a => a.activityDay === selectedDayInTrip))
        activeActivities.length > 0
            && activeActivities.filter(a => a.activityDay === selectedDayInTrip).length > 0
            && setActiveActivity(activeActivities.filter(a => a.activityDay === selectedDayInTrip)[0])


        setActiveActivityIndex("1")
        setPreviousActivity(null)
        activeActivities.filter(a => a.activityDay === selectedDayInTrip).length >= 2 &&
            activeActivities.filter(a => a.activityDay === selectedDayInTrip)[1] !== undefined ?
            setNextActivity(activeActivities.filter(a => a.activityDay === selectedDayInTrip)[1]) : setNextActivity(null)

    }
    const setActiveActivityHandler = (activity, activeActivityIndex) => {
        setActiveActivity(activity)
        setActiveActivityIndex(activeActivityIndex)

        Number(activeActivityIndex) - 2 >= 0
            && activeActivitiesInDay[Number(activeActivityIndex) - 2] !== undefined
            ? setPreviousActivity(activeActivitiesInDay[Number(activeActivityIndex) - 2]) : setPreviousActivity(null)

        activeActivitiesInDay.length > Number(activeActivityIndex)
            && activeActivitiesInDay[Number(activeActivityIndex)] !== undefined
            ? setNextActivity(activeActivitiesInDay[Number(activeActivityIndex)]) : setNextActivity(null)
        setMapCenter({
            lat: parseFloat(activity.activityLatitude),
            lng: parseFloat(activity.activityLongitude)
        })
    }
    const setActiveActivityArrowRightHanlder = (previousActivity, previousActiveActivityIndex) => {
        setActiveActivity(previousActivity)
        setActiveActivityIndex(String(Number(previousActiveActivityIndex) + 1))

        Number(previousActiveActivityIndex) - 1 >= 0
            && activeActivitiesInDay[Number(previousActiveActivityIndex) - 1] !== undefined
            ? setPreviousActivity(activeActivitiesInDay[Number(previousActiveActivityIndex) - 1]) : setPreviousActivity(null)

        activeActivitiesInDay.length > (Number(previousActiveActivityIndex) + 1)
            && activeActivitiesInDay[Number(previousActiveActivityIndex) + 1] !== undefined
            ? setNextActivity(activeActivitiesInDay[Number(previousActiveActivityIndex) + 1]) : setNextActivity(null)
        setMapCenter({
            lat: parseFloat(previousActivity.activityLatitude),
            lng: parseFloat(previousActivity.activityLongitude)
        })
    }
    const setActiveActivityArrowLeftHanlder = (previousActivity, previousActiveActivityIndex) => {

        setActiveActivity(previousActivity)
        setActiveActivityIndex(String(Number(previousActiveActivityIndex) - 1))

        Number(previousActiveActivityIndex) - 3 >= 0
            && activeActivitiesInDay[Number(previousActiveActivityIndex) - 3] !== undefined
            ? setPreviousActivity(activeActivitiesInDay[Number(previousActiveActivityIndex) - 3]) : setPreviousActivity(null)

        activeActivitiesInDay.length > Number(previousActiveActivityIndex) - 1
            && activeActivitiesInDay[Number(previousActiveActivityIndex) - 1] !== undefined
            ? setNextActivity(activeActivitiesInDay[Number(previousActiveActivityIndex) - 1]) : setNextActivity(null)
        setMapCenter({
            lat: parseFloat(previousActivity.activityLatitude),
            lng: parseFloat(previousActivity.activityLongitude)
        })
    }

    let containerH = 600;
    if (screenSize !== undefined) {
        containerH = screenSize / 3;
        containerH = screenSize <= 320 ? 400 : (screenSize <= 414 ? 517.5 : (containerH > 600 ? 600 : containerH));
    }


    const goToDestination = () => {
        if (tripDestinations && tripDestinations?.length > 0) {
            const destination = tripDestinations[activeTripDestinationIndex];
            const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + destination?.id;

            dispatch(setTimeLineStatus(true))
            history.push(url.toLowerCase());
        }

    }

    return (
        <React.Fragment>
            {tripDestinations !== undefined && tripDestinations.length > 0 && <div className="futura_hv_bt_30_32_black pl-2 margin_bottom_20">Daily Itineraries</div>}
            {tripDestinations !== undefined && tripDestinations.length > 0
                ?
                <div>
                    <div style={{
                        font: "16px/20px Futura Lt BT",
                        color: "var(--mainGreen)",
                        cursor: "pointer",
                        textAlign: "right",
                        paddingRight: 10,
                        paddingBottom: 40
                    }}>  </div>
                    <div className={screenSize > 500 ? "d-flex" : ""}>
                        <div style={{ height: screenSize > 500 ? containerH : 400, width: screenSize > 500 ? "66%" : "100%" }} className="content_container margin_bottom_20 inline-block" id="google_map_wrap">
                            {/* setting map */}


                            <WrappedMap
                                trip={trip}
                                activeActivitiesInDay={activeActivitiesInDay}
                                zoom={8}
                                mapCenter={mapCenter}
                                setMapCenter={setMapCenter}
                                activeActivity={activeActivity}
                                setActiveActivityHandler={setActiveActivityHandler}
                            />
                            <div className="destinations_container row">
                                {tripDestinations.map((destination, destination_idx) => {
                                    return (
                                        <React.Fragment key={destination_idx}>
                                            {
                                                <div
                                                    className={activeTripDestinationIndex === destination_idx ? "bg_color_map_detination_btn col-lg-2 col-md-3 col-sm-6 destination_active" : "bg_color_map_detination_btn col-lg-2 col-md-3 col-sm-6"}
                                                    onClick={() => destinaitonRadioHandler(destination_idx)}>
                                                    <div className="futura_md_bt_18_14 box_1 p-2 text-center">{destination.title}</div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            <div className={trip.noDate === "1" ? "destinations_days_container_day" : "destinations_days_container"} >

                                {activeActivities.length > 0 && Array.from(new Set(activeActivities.map(a => a.activityDay)))?.sort((a, b) => Number(a) - Number(b)).map((dayInTrip, day_idx) => {
                                    return (
                                        <div key={day_idx}
                                            className={trip.noDate === "1" ? "bg_color_map_days_btn_day" : "bg_color_map_days_btn"}
                                            onClick={() => daysCheckBoxHandler(dayInTrip)}
                                        >
                                            <div className={trip.noDate === "1" ? "futura_md_bt_12_16 pl-1 pt-1 pb-1 text-center" : "futura_md_bt_12_16 pl-1 pt-1 pb-1 text-left"}>
                                                {dayInTrip === activeDayInTrip ? <CheckedBoxIcon className="mr-1" /> : <CheckBoxIcon className="mr-1" />}
                                                {"Day " + dayInTrip}
                                                {/* {
                                                trip.noDate === "1" ?
                                                    "Day " + dayInTrip
                                                    :
                                                    moment(trip.tripStartDate, 'YYYY-MM-DD').add((dayInTrip - 1), 'days').format('ddd D[,] MMM[.]')
                                            } */}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div>
                            <div onClick={goToDestination} style={{
                                font: "16px/20px Futura Lt BT",
                                color: "var(--mainGreen)",
                                cursor: "pointer",
                                textAlign: "right",
                                paddingRight: 10,
                                paddingBottom: 10,
                                paddingLeft: 10,
                                position: 'absolute',
                                marginTop: -30
                            }}> edit destination itinerary </div>
                            {is_mobile ?
                                <ActivityImageSlider
                                    activeActivities={activeActivitiesInDay}
                                    activeActivitiesInDay={activeActivitiesInDay}
                                    containerH={containerH}
                                />

                                :
                                <div>
                                    <DarkTooltip  title="Click on top of image to see description." placement="top-start">
                                        <div>
                                        <ActivityImage
                                            activeActivity={activeActivity}
                                            screenSize={screenSize}
                                            previousActivity={previousActivity}
                                            nextActivity={nextActivity}
                                            activeActivityIndex={activeActivityIndex}
                                            setActiveActivityArrowRightHanlder={setActiveActivityArrowRightHanlder}
                                            setActiveActivityArrowLeftHanlder={setActiveActivityArrowLeftHanlder}
                                            containerH={containerH}
                                            is_mobile={is_mobile} />
                                            </div>
                                    </DarkTooltip>

                                </div>}
                        </div>
                    </div>
                </div>
                : <div className="futura_md_bt_18_21_lightGray pl-2">No activity found.</div>
            }
        </React.Fragment >
    )
}

PublicTripItineraryMap_.propTypes = {
    trip: PropTypes.object.isRequired
}
const mapStateToProps = state => {
    return {
        trip: state.PublicTrip.trip,
        is_mobile: state.Setting.is_mobile,
        screenSize: state.Setting.htmlBody.bodyClientWidth,
    }
};

const mapDispatchToProps = {
}

const PublicTripItineraryMap = connect(mapStateToProps, mapDispatchToProps)(PublicTripItineraryMap_)
export default PublicTripItineraryMap


const ActivityImageSlider = ({ activeActivities, containerH }) => {

    return (
        <div style={{ marginTop: 1 }}>
            <ScrollMenu LeftArrow={null} RightArrow={null}>
                {activeActivities !== undefined && activeActivities.length > 0 && activeActivities.map((activeActivity, idx) => {
                    const activeActivityIndex = idx + 1;
                    return (
                        <React.Fragment key={activeActivityIndex}>
                            <ActivityMobileImage
                                activeActivity={activeActivity}
                                activeActivityIndex={activeActivityIndex}
                                containerH={containerH}
                            />
                        </React.Fragment>
                    )
                })
                }
            </ScrollMenu>



        </div>
    )
}

const ActivityMobileImage = ({ activeActivity, activeActivityIndex, containerH }) => {
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })
    return (
        <div style={{ position: 'relative' }}>
            <animated.div style={{ zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform }}>

                <div className={"content_container inline-block"} style={{ height: 400 }}>
                    <Image
                        src={image_boxing(activeActivity.activityImage, "220", "400")}
                        style={{ height: containerH }} />


                    <div className="z_index_3 mask_map_activity_icon">
                        {activeActivityIndex <= 9 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="15" fontWeight="bold" fill="white">
                                        <tspan x="18" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="10" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="11" fontWeight="bold" fill="white">
                                        <tspan x="16" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                        }
                    </div>
                    <div className="mask_block_trip" style={{ height: containerH, width: "auto" }} onClick={() => setFlipped(state => !state)} ></div>
                    <div className="z_index_3 mask_map_activity_name">
                        <EstablishmentName
                            establishmentId={activeActivity.activityEstabID}
                            establishmentName={activeActivity.activityName}
                            titleClass={"box_1 PT-2"}
                            titleStyle={{ cursor: 'pointer', font: "24px/29px Futura Md BT", textAlign: "left", color: "#FFFFFF", padding: "0px 2px" }}
                        />
                        {activeActivity.activityDescription !== undefined && activeActivity.activityDescription !== "" && <div className="futura_md_bt_18_21_white box_1 pt-2"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => setFlipped(state => !state)}>{activeActivity.activityDescription}</div>}
                    </div>
                </div>
            </animated.div>
            <animated.div style={{ position: "absolute", top: "0px", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                <div className={"content_container inline-block"} style={{ height: 400 }}>
                    <Image
                        onClick={() => setFlipped(state => !state)}
                        src={image_boxing(activeActivity.activityImage, "220", "400")}
                        style={{ height: containerH }} />
                    <div className="z_index_3 mask_map_activity_icon_description mt-1">
                        {activeActivityIndex <= 9 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="15" fontWeight="bold" fill="white">
                                        <tspan x="18" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="10" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="11" fontWeight="bold" fill="white">
                                        <tspan x="16" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                        }
                    </div>
                    <div style={{ backgroundColor: "#19191980", position: "absolute", width: containerH / 1.25 + "px", height: containerH + "px", top: "0px", zIndex: "2" }} onClick={() => setFlipped(state => !state)} />
                    <div style={{ zIndex: "3", position: "absolute", top: "0px", left: "25px" }}>
                        <EstablishmentName
                            establishmentId={activeActivity.activityEstabID}
                            establishmentName={activeActivity.activityName}
                            titleClass={"box_1 pt-2 "}
                            titleStyle={{ cursor: 'pointer', font: "24px/29px Futura Md BT", textAlign: "left", color: "#FFFFFF", marginLeft: "20px", marginBottom: "20px", marginTop: "60px" }}
                        />
                    </div>
                    {activeActivity.activityDescription !== undefined && activeActivity.activityDescription !== "" && <div
                        style={{
                            overflow: "hidden",
                            height: "100%",
                            width: "100%"
                        }}
                    ><div
                        style={{
                            font: "18px/ 28px Futura Lt BT",
                            color: "#FFFFFF",
                            marginLeft: "45px",
                            marginRight: "25px",
                            cursor: "pointer",
                            position: "absolute",
                            top: "110px",

                            zIndex: "3",
                            maxHeight: "260px",
                            textAlign: "justify",
                            lineHeight: "1",

                            overflow: "auto",
                            paddingRight: "20px"

                        }} onClick={() => setFlipped(state => !state)}>
                            {activeActivity.activityDescription}
                        </div></div>}


                </div>

            </animated.div>

        </div>

    )
}

const ActivityImage = ({ activeActivity, screenSize, containerH, nextActivity,
    setActiveActivityArrowRightHanlder, activeActivityIndex, previousActivity,
    setActiveActivityArrowLeftHanlder, is_mobile
}) => {
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })

    return (
        <div style={{ position: "relative" }}>
            <animated.div style={{ position: is_mobile ? "relative" : "absolute", zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform }}>
                {activeActivity !== null && <div className={screenSize > 500 ? "content_container inline-block ml-3" : "content_container inline-block"} style={{ height: containerH }}>
                    <Image
                           onClick={() => setFlipped(state => !state)}
                        src={activeActivity?.activityImage ? image_boxing(activeActivity.activityImage, "480", "600") :
                            "https://amhqajbken.cloudimg.io/v7/_muvestab_/000_c…on/grayBackgroundCamera.jpg"}
                        style={{ height: containerH }} />
                    {nextActivity !== null && <div style={{
                        position: "absolute",
                        right: "0px",
                        bottom: "47%",
                        zIndex: "3",
                        cursor: "pointer",
                    }}
                    
                        onClick={() => setActiveActivityArrowRightHanlder(nextActivity, activeActivityIndex)}
                    >
                        <ArrowRightIcon style={{
                            height: "40px",
                            width: "40px",
                        }} />
                    </div>}
                    {previousActivity !== null && <div style={{
                        position: "absolute",
                        left: "0px",
                        bottom: "47%",
                        zIndex: "3",
                        cursor: "pointer",
                    }}
                        onClick={() => setActiveActivityArrowLeftHanlder(previousActivity, activeActivityIndex)}
                    >
                        <ArrowLeftIcon style={{
                            height: "40px",
                            width: "40px",
                        }} />
                    </div>}
                    <div className="z_index_3 mask_map_activity_icon">
                        {activeActivityIndex <= 9 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="15" fontWeight="bold" fill="white">
                                        <tspan x="18" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="10" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="11" fontWeight="bold" fill="white">
                                        <tspan x="16" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                        }
                    </div>
                    <div className="mask_block_trip" style={{ height: containerH, width: "auto" }} onClick={() => setFlipped(state => !state)}></div>
                    <div className="z_index_3 mask_map_activity_name">
                        <EstablishmentName
                            establishmentId={activeActivity.activityEstabID}
                            establishmentName={activeActivity.activityName}
                            titleClass={"box_1 PT-2"}
                            titleStyle={{ cursor: 'pointer', font: "24px/29px Futura Md BT", textAlign: "left", color: "#FFFFFF", padding: "0px 2px" }}
                        />
                        {activeActivity.activityDescription !== undefined && activeActivity.activityDescription !== "" && <div className="futura_md_bt_18_21_white box_1 pt-2"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() => setFlipped(state => !state)}>{activeActivity.activityDescription}</div>}
                    </div>
                </div>}
            </animated.div>
            <animated.div style={{ position: is_mobile ? "relative" : "absolute", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                {activeActivity !== null && <div className={screenSize > 500 ? "content_container inline-block ml-3" : "content_container inline-block"} style={{ height: containerH, width: "480px" }}>
                    <Image
                        onClick={() => setFlipped(state => !state)}
                        src={activeActivity?.activityImage ? image_boxing(activeActivity.activityImage, "480", "600") :
                            "https://amhqajbken.cloudimg.io/v7/_muvestab_/000_c…on/grayBackgroundCamera.jpg"}
                        style={{ height: containerH }} />
                    <div className="z_index_3 mask_map_activity_icon_description mt-1">
                        {activeActivityIndex <= 9 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="12" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="15" fontWeight="bold" fill="white">
                                        <tspan x="18" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                                <g fillRule="evenodd">
                                    <circle cx="22.5" cy="22.5" r="10" stroke="rgba(25, 188, 155, 0.4)" strokeWidth="20" fill="rgba(25, 188, 155, 1)" />
                                    <text fontSize="11" fontWeight="bold" fill="white">
                                        <tspan x="16" y="27">{activeActivityIndex}</tspan>
                                    </text>
                                </g>
                            </svg>
                        }
                    </div>
                    <div style={{ backgroundColor: "#19191980", position: "absolute", width: containerH / 1.25 + "px", height: containerH + "px", top: "0px", zIndex: "2" }} onClick={() => setFlipped(state => !state)} />
                    <div style={{ zIndex: "3", position: "absolute", top: "0px", left: "25px" }}>
                        <EstablishmentName
                            establishmentId={activeActivity.activityEstabID}
                            establishmentName={activeActivity.activityName}
                            titleClass={"box_1 pt-2 "}
                            titleStyle={{ cursor: 'pointer', font: "24px/29px Futura Md BT", textAlign: "left", color: "#FFFFFF", marginLeft: "20px", marginBottom: "20px", marginTop: "60px" }}
                        />
                    </div>
                    {activeActivity.activityDescription !== undefined && activeActivity.activityDescription !== "" && <div
                        style={{
                            font: "18px/ 28px Futura Lt BT",
                            color: "#FFFFFF",
                            marginLeft: "45px",
                            marginRight: "45px",
                            cursor: "pointer",
                            position: "absolute",
                            top: "110px",
                            overflowY: "auto",
                            zIndex: "3",
                            maxHeight: (containerH - 130) + "px",
                            paddingRight: "20px",
                            width: (containerH - 170) + "px",
                            textAlign: "justify"
                        }}

                        dangerouslySetInnerHTML={{ __html: activeActivity?.activityDescription }}
                        onClick={() => setFlipped(state => !state)}>
                    </div>}
                    {nextActivity !== null && <div style={{
                        position: "absolute",
                        right: "0px",
                        bottom: "47%",
                        zIndex: "3",
                        cursor: "pointer",
                    }}
                        onClick={() => setActiveActivityArrowRightHanlder(nextActivity, activeActivityIndex)}
                    >
                        <ArrowRightIcon style={{
                            height: "40px",
                            width: "40px",
                        }} />
                    </div>}
                    {previousActivity !== null && <div style={{
                        position: "absolute",
                        left: "0px",
                        bottom: "47%",
                        zIndex: "3",
                        cursor: "pointer",
                    }}
                        onClick={() => setActiveActivityArrowLeftHanlder(previousActivity, activeActivityIndex)}
                    >
                        <ArrowLeftIcon style={{
                            height: "40px",
                            width: "40px",
                        }} />
                    </div>}
                </div>}
            </animated.div>
        </div >
    )
}