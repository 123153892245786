import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'var(--mainGrey)',
      padding:5,
      paddingLeft:10,
      paddingRight:10,
      border: '1px solid rgb(232, 232, 232)',
      font: '18px/21px Futura Lt BT'
    },
    popper: {
        marginLeft: '-2%',
      },
  }))(Tooltip);

  export const DarkTooltip = withStyles((theme) => ({
    tooltip: {
   
      padding:5,
      paddingLeft:10,
      paddingRight:10,
      border: '1px solid rgb(232, 232, 232)',
      font: '12px/21px Futura Lt BT',
      background:"black",
      color:"white",
      textAlign:"center",
      width:150
    },
    popper: {
zIndex:30,
position:'relative',
        // marginLeft: '15%',
        // marginLeft:345
        marginLeft:"18%"
    
      },
  }))(Tooltip);


  export const AiTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'var(--mainBlack)',
      padding:5,
      paddingLeft:10,
      paddingRight:10,
      border: '1px solid rgb(232, 232, 232)',
      font: '18px/21px Futura Lt BT',
    },
    tooltipPlacementTop: {
      margin: "5px 0",
    },
    popper: {
        marginLeft: '-2%',
      },
  }))(Tooltip);
