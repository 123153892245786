import {
  ADD_ACTIVITY_IN_ACTIVE_TRIP,
  ADD_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS,
  CEHCK_EMAIL_EXIST_FAILURE,
  CEHCK_EMAIL_EXIST_FAILURE_COMPANION,
  CEHCK_EMAIL_EXIST_SUCCESS,
  CEHCK_EMAIL_EXIST_SUCCESS_COMPANION,
  CEHCK_HANDLE_EXIST_FAILURE,
  CEHCK_HANDLE_EXIST_SUCCESS,
  SET_USER_SIGNATURE,
  DELETE_DESTINATION_VISITED,
  DELETE_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS,
  DRAG_DROP_TRIP_DESTINATION_IN_TRIP,
  FETCH_ACTIVE_ACTIVITIES_IN_TRIP,
  SET_ADDED_ACTIVITY_IN_TRIP,
  FETCH_CULTURE_API_ERROR,
  FETCH_CULTURE_DATA,
  FETCH_DESTINATION_VISITED_SEARCH,
  FETCH_DESTINATION_VISITED_SEARCH_ERROR,
  FETCH_DESTINATION_VISITED_SEARCH_QUERY,
  FETCH_DESTINATION_VISITED_SEARCH_STATUS,
  FETCH_EDUCATION_API_ERROR,
  FETCH_EDUCATION_DATA,
  FETCH_FLUENCY_API_ERROR,
  FETCH_FLUENCY_DATA,
  FETCH_GENDER_API_ERROR,
  FETCH_GENDER_DATA,
  FETCH_GENERAL_SEARCH,
  FETCH_GENERAL_SEARCH_ERROR,
  FETCH_GENERAL_SEARCH_QUERY,
  FETCH_GENERAL_SEARCH_STATUS,
  SET_GENERAL_SEARCH_SEARCHER_ID,
  FETCH_HOMETOWN_AIRPORT_API_ERROR,
  FETCH_HOMETOWN_AIRPORT_DATA,
  FETCH_HOMETOWN_API_ERROR,
  FETCH_LANGUAGE_API_ERROR,
  FETCH_LANGUAGE_DATA,
  FETCH_LEAVING_FROM_AIRPORT_API_ERROR,
  FETCH_LEAVING_FROM_AIRPORT_DATA,
  FETCH_ORIENTATION_API_ERROR,
  FETCH_ORIENTATION_DATA,
  FETCH_PERSONALIZATION,
  FETCH_PERSONALIZATION_SWITCH_CONTAINER,
  FETCH_PERSONALIZATION_TYPE_SETS,
  FETCH_PLACES_NEAR_BY_CENTER,
  FETCH_PROFESSION_API_ERROR,
  FETCH_PROFESSION_DATA,
  FETCH_RELIGION_API_ERROR,
  FETCH_RELIGION_DATA,
  FETCH_RETURNING_TO_AIRPORT_API_ERROR,
  FETCH_RETURNING_TO_AIRPORT_DATA,
  FETCH_SEARCH_DESTINATION_API_ERROR,
  FETCH_SEARCH_DESTINATION_DATA,
  FETCH_SEARCH_ESTABLISHMENT_API_ERROR,
  FETCH_SEARCH_ESTABLISHMENT_DATA,
  FETCH_TIMELINE_MUV_HOTELS,
  FETCH_TRIP_COMPANION_SETS,
  FETCH_TRIP_TYPES_SETS,
  PL_FETCH_HOTELS_LIST_SUCCESS,
  PL_FETCH_HOTELS_LIST_FAILURE,
  CONVERT_HOTELS_TO_ESTABLISHMENTS,
  REMOVE_EXIST_LANGUAGE_FLUENCY_SELECTED,
  REMOVE_LANGUAGE_FLUENCY_SELECTED,
  SELECTED_DESTINATION_VISITED_MERGE,
  SET_ABOUT_ME,
  SET_ABOUT_ME_ERROR,
  SET_ABOUT_ME_TEXT_HELPER,
  SET_ACTIVE_DAY_IN_TRIP,
  SET_ACTIVE_TRIP_DESTINATION,
  SET_BIRTHDAY,
  SET_BIRTHDAY_ERROR,
  SET_BIRTHDAY_TEXT_HELPER,
  SET_CULTURE_COMPONENT_STATUS,
  SET_CULTURE_ERROR,
  SET_CULTURE_LOAD_RESOURCE,
  SET_CULTURE_SELECTED,
  SET_CULTURE_TEXT_HELPER,
  SET_DESTINATION_VISITED_LOAD_GOOGLE_URL,
  SET_EDUCATION_COMPONENT_STATUS,
  SET_EDUCATION_ERROR,
  SET_EDUCATION_LOAD_RESOURCE,
  SET_EDUCATION_SELECTED,
  SET_EDUCATION_TEXT_HELPER,
  SET_EMAIL_ADDRESS,
  SET_EMAIL_ADDRESS_COMPANION,
  SET_EMAIL_ADDRESS_ERROR,
  SET_EMAIL_ADDRESS_ERROR_COMPANION,
  SET_EMAIL_ADDRESS_LOGIN,
  SET_EMAIL_ADDRESS_LOGIN_ERROR,
  SET_EMAIL_ADDRESS_LOGIN_TEXT_HELPER,
  SET_EMAIL_ADDRESS_TEXT_HELPER,
  SET_EMAIL_ADDRESS_TEXT_HELPER_COMPANION,
  SET_FACEBOOK,
  SET_FACEBOOK_ERROR,
  SET_FACEBOOK_TEXT_HELPER,
  SET_FEATURE_FORMAT,
  SET_FIRST_NAME,
  SET_FIRST_NAME_COMPANION,
  SET_FIRST_NAME_ERROR,
  SET_FIRST_NAME_ERROR_COMPANION,
  SET_FIRST_NAME_TEXT_HELPER,
  SET_FIRST_NAME_TEXT_HELPER_COMPANION,
  SET_FLUENCY_COMPONENT_STATUS,
  SET_FLUENCY_ERROR,
  SET_FLUENCY_LOAD_RESOURCE,
  SET_FLUENCY_SELECTED,
  SET_FLUENCY_TEXT_HELPER,
  SET_GENDER_COMPONENT_STATUS,
  SET_GENDER_ERROR,
  SET_GENDER_LOAD_RESOURCE,
  SET_GENDER_SELECTED,
  SET_GENDER_TEXT_HELPER,
  SET_HANDLE,
  SET_HANDLE_ERROR,
  SET_HANDLE_TEXT_HELPER,
  SET_HOTEL_SEARCH_FORM,
  SET_HOMETOWN_AIRPORT_AUTOCOMPLETE_STATUS,
  SET_HOMETOWN_AIRPORT_ERROR,
  SET_HOMETOWN_AIRPORT_SELECTED,
  SET_HOMETOWN_AIRPORT_TEXT_HELPER,
  SET_HOMETOWN_AUTOCOMPLETE_STATUS,
  SET_HOMETOWN_ERROR,
  SET_HOMETOWN_LOAD_GOOGLE_URL,
  SET_HOMETOWN_OPTIONS,
  SET_HOMETOWN_SEARCH_QUERY,
  SET_HOMETOWN_SELECTED,
  SET_HOMETOWN_SELECTED_POST_REQUIRED,
  SET_HOMETOWN_SERVICE_ACTIVE,
  SET_HOMETOWN_TEXT_HELPER,
  SET_INSTAGRAM,
  SET_INSTAGRAM_ERROR,
  SET_INSTAGRAM_TEXT_HELPER,
  SET_LANGUAGE_COMPONENT_STATUS,
  SET_LANGUAGE_ERROR,
  SET_LANGUAGE_FLUENCY,
  SET_LANGUAGE_FLUENCY_SELECTED,
  SET_LANGUAGE_LOAD_RESOURCE,
  SET_LANGUAGE_SELECTED,
  SET_LANGUAGE_TEXT_HELPER,
  SET_LAST_NAME,
  SET_LAST_NAME_COMPANION,
  SET_LAST_NAME_ERROR,
  SET_LAST_NAME_ERROR_COMPANION,
  SET_LAST_NAME_TEXT_HELPER,
  SET_LAST_NAME_TEXT_HELPER_COMPANION,
  SET_LEAVING_FROM_AIRPORT_AUTOCOMPLETE_STATUS,
  SET_LEAVING_FROM_AIRPORT_ERROR,
  SET_LEAVING_FROM_AIRPORT_SELECTED,
  SET_LEAVING_FROM_AIRPORT_TEXT_HELPER,
  SET_ORIENTATION_COMPONENT_STATUS,
  SET_ORIENTATION_ERROR,
  SET_ORIENTATION_LOAD_RESOURCE,
  SET_ORIENTATION_SELECTED,
  SET_ORIENTATION_TEXT_HELPER,
  SET_PASSWORD,
  SET_PASSWORD_CONFIRM,
  SET_PASSWORD_CONFIRM_ERROR,
  SET_PASSWORD_CONFIRM_TEXT_HELPER,
  SET_PASSWORD_ERROR,
  SET_PASSWORD_LOGIN,
  SET_PASSWORD_LOGIN_ERROR,
  SET_PASSWORD_LOGIN_TEXT_FIELD_TYPE,
  SET_PASSWORD_LOGIN_TEXT_HELPER,
  SET_PASSWORD_TEXT_FIELD_TYPE,
  SET_PASSWORD_TEXT_HELPER,
  SET_PINTEREST,
  SET_PINTEREST_ERROR,
  SET_PINTEREST_TEXT_HELPER,
  SET_PROFESSION_COMPONENT_STATUS,
  SET_PROFESSION_ERROR,
  SET_PROFESSION_LOAD_RESOURCE,
  SET_PROFESSION_SELECTED,
  SET_PROFESSION_TEXT_HELPER,
  SET_RELIGION_COMPONENT_STATUS,
  SET_RELIGION_ERROR,
  SET_RELIGION_LOAD_RESOURCE,
  SET_RELIGION_SELECTED,
  SET_RELIGION_TEXT_HELPER,
  SET_RETURNING_TO_AIRPORT_AUTOCOMPLETE_STATUS,
  SET_RETURNING_TO_AIRPORT_ERROR,
  SET_RETURNING_TO_AIRPORT_SELECTED,
  SET_RETURNING_TO_AIRPORT_TEXT_HELPER,
  SET_SEARCH_DESTINATION_AUTOCOMPLETE_STATUS,
  SET_SEARCH_DESTINATION_ERROR,
  SET_SEARCH_DESTINATION_SELECTED,
  SET_SEARCH_DESTINATION_TEXT_HELPER,
  SET_SEARCH_ESTABLISHMENT_AUTOCOMPLETE_STATUS,
  SET_SEARCH_ESTABLISHMENT_ERROR,
  SET_SEARCH_ESTABLISHMENT_SELECTED,
  SET_SEARCH_ESTABLISHMENT_TEXT_HELPER,
  SET_SELECTED_DESTINATION_VISITED,
  SET_SELECTED_PROFILE_PHOTO_AVATAR,
  SET_SELECTED_PROFILE_PHOTO_AVATAR_PROGRESS,
  SET_SNAPCHAT,
  SET_SNAPCHAT_ERROR,
  SET_SNAPCHAT_TEXT_HELPER,
  SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS,
  SET_TIMELINE_ACTIVE_TRIP_COST,
  SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS,
  SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION,
  SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS,
  SET_TIMELINE_ACTIVE_TRIP_DURATION,
  SET_TIMELINE_ACTIVE_TRIP_ID,
  SET_TIMELINE_ACTIVE_TRIP_IMAGE,
  SET_TIMELINE_ACTIVE_TRIP_NAME,
  SET_TIMELINE_ACTIVE_TRIP_NO_DATE,
  SET_TIMELINE_ACTIVE_TRIP_START_DATE,
  SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS,
  SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_ALL,
  SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_OTHERS,
  SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_OPEN_SELECTED,
  SET_TIMELINE_WIZARD_DELETE_ALART_STATUS,
  SET_TIMELINE_WIZARD_DRAGGING_ACTIVITY,
  SET_TIMELINE_WIZARD_DRAGGING_ESTABLISHMENT,
  SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS,
  SET_TIMELINE_WIZARD_STATUS,
  SET_TRIP_TYPE_SELECTED,
  SET_TWITTER,
  SET_TWITTER_ERROR,
  SET_TWITTER_TEXT_HELPER,
  SWITCH_BOOKING_FORM_ACTIVE_TAB,
  SWITCH_BOOKING_FORM_STATUS,
  SWRAP_TRIP_DESTINATION_IN_TRIP,
  UPDATE_PERSONALIZATION_BARS_SCORE,
  UPDATE_PERSONALIZATION_SCORE,
  UPDATE_TRIP_DESTINATION_IN_TRIP,
  CLEAR_ALL_TIMELINE,
  UPDATE_LAZYLOADING_URL_REQUESTS_HOTELS_LIST,
  UPDATE_LAZYLOADING_OFFSET_HOTELS_LIST,
  UPDATE_LAZYLOADING_LIMIT_HOTELS_LIST,
  UPDATE_LAZYLOADING_HOTELS_LIST,
  UPDATE_LAZYLOADING_PROGRESS_HOTELS_LIST,
  UPDATE_LAZYLOADING_NO_PROGRESS_HOTELS_LIST,
  FETCH_ESTABLISMENT_SETS_LAZYLOADING_HOTELS_LIST,
  FETCH_SORTING_OPTION_HOTELS_LIST,
  UPDATE_SEARCH_QUERY_HOTELS_LIST,
  FETCH_API_ERROR_HOTELS_LIST,
  UPDATE_LAZYLOADING_NO_MORE_HOTELS,
  FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT,
  FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT_FAILURE,
  SET_HOTEL_ESTABLISHMENT_ID_ESTABLISHMENT,
  FETCH_SLIDER_IMAGES_HOTEL_ESTABLISHMENT,
  FETCH_REVIEWS_HOTEL_ESTABLISHMENT,
  FETCH_GENERAL_INFO_ROOMS,
  FETCH_GENERAL_INFO_ROOMS_FAILURE,
  FETCH_TIME_TO_REACH_OPTIONS,
  SET_HOTEL_FORM_STATE,
  SET_BOOKING_FORM_STATE,
  FETCH_CONTRACT_INFO_HOTEL_BOOKING,
  FETCH_CONTRACT_INFO_HOTEL_BOOKING_FAILURE,
  ADD_BUNDLE_TO_HOTEL_BOOKING,
  ADD_BUNDLE_TO_HOTEL_BOOKING_FAILURE,
  REMOVE_BUNDLE_TO_HOTEL_BOOKING,
  REMOVE_BUNDLE_TO_HOTEL_BOOKING_FAILURE,
  FETCH_SUBTYPES_HOTELS_LIST,
  UPDATE_SELECTED_SUBTYPES_HOTELS_LIST,
  CLEAN_SELECTED_SUBTYPES_HOTELS_LIST,
  SET_SEARCH_PLACE_AUTOCOMPLETE_STATUS,
  SET_SEARCH_PLACE_TEXT_HELPER,
  FETCH_SEARCH_PLACE_API_ERROR,
  FETCH_SEARCH_PLACE_DATA,
  SET_SEARCH_PLACE_ERROR,
  SET_SEARCH_PLACE_SELECTED,
  SET_SEARCH_ADDRESS_AUTOCOMPLETE_STATUS,
  SET_SEARCH_ADDRESS_TEXT_HELPER,
  FETCH_SEARCH_ADDRESS_API_ERROR,
  FETCH_SEARCH_ADDRESS_DATA,
  SET_SEARCH_ADDRESS_ERROR,
  SET_SEARCH_ADDRESS_SELECTED,
  SET_ESTABLISHMENTS_TO_FILTER,
  SET_FILTERED_ESTABLISHMENTS,
  SET_HOTEL_ESTABLISHMENTS_COPY,
  SET_HOTEL_SEARCH_IS_NEW,
  UPDATE_LAZYLOADING_SORT_BY,
  SET_HOTEL_SEARCH_IS_SEARCHING,
  SET_SLIDER_INDEX,
  FETCH_TRANSPORT_MODES,
  CLEAR_TIMELINE_TRIP,
  SET_TIMELINE_TRIP_DESTINATIONS_STATUS,
} from "../interfaces/types";

import {
  feature_color,
  list_container_mode,
  LAZYDISPLAY_INIT_LIMIT,
  PRICELINE_API_DEFAULT_SORT_BY,
  PRICELINE_API_DEFAULT_SORT_TITLE,
} from "../../Components/WebContent/Common/RegExValidate";

const initialState = {
  TimelineWizard: {
    status: false,
    alartModalStatusDeleting: false,
    draggingEstablishment: {
      draggingEstablishmentDestinationId: "",
      draggingEstablishment: null,
    },
    draggingActivity: {
      dragActivityIndexInDay: 0,
      dragActivityInDestination: "",
      dragDeleteActivity: null,
      draggingActivity: null,
      dragActivityIdsInDay: [],
    },
    isFetchingEstablishments: false,
    activeTrip: {
      tripId: "",
      tripName: "",
      tripNoDate: "",
      tripStartDate: "",
      tripDuration: "",
      tripDescription: "",
      tripAuthStatus: "",
      tripImage: null,
      addedActivityID: "",
      hotels: [],
      tripCost: {
        cost: null,
        fetching: false,
      },
      tripDestinations: [],
      tripDestinationsOpenStatus: [],
      activities: [],
      leavingFromAirport: {
        airportResource: [],
        airportSelected: null,
        errorAPI: false,
        error: false,
        textHelper: "",
        open: false,
      },
      returningToAirport: {
        airportResource: [],
        airportSelected: null,
        errorAPI: false,
        error: false,
        textHelper: "",
        open: false,
      },
      tripType: {
        selectedTypeId: "",
        sets: [],
      },
      tripCompanion: {
        sets: [],
      },
    },
    activeTripDestinationId: "",
    activeDestinationId: "",
    activeDay: {
      tripDestinationId: "",
      dayInTrip: "",
      dayInTripDestination: "",
    },
    searchDestination: {
      searchDestinationSet: [],
      destinationSelected: null,
      errorAPI: false,
      error: false,
      textHelper: "",
      open: false,
    },
    searchPlace: {
      searchPlaceSet: [],
      placeSelected: null,
      errorAPI: false,
      error: false,
      textHelper: "",
      open: false,
    },
    searchAddress: {
      searchAddressSet: [],
      addressSelected: null,
      errorAPI: false,
      error: false,
      textHelper: "",
      open: false,
    },
    /* search establishment and add it to timeline */
    searchEstablishment: {
      searchEstablishmentSet: [],
      establishmentSelected: null,
      errorAPI: false,
      error: false,
      textHelper: "",
      open: false,
    },
  },
  FeatureFormat: {
    featureId: "",
    slug: "",
    title: "",
    color: "#19BC9B",
    disable: "",
    sorting: null,
  },
  Map: {
    placesNearByCenter: [],
  },
  HotelSearch: {
    establishment: {},
    establishmentListDisplayFormat: list_container_mode.list,
    lazyLoading: {
      isFetching: false,
      progress: 0,
      noProgress: true,
      noMore: false,
      url: "",
      offset: LAZYDISPLAY_INIT_LIMIT,
      requests: {
        //"proxyData[limit]": LAZYLOADING_HOTELS_INIT_LIMIT,
        "proxyData[sort_by]": PRICELINE_API_DEFAULT_SORT_BY,
      },
      sortCode: PRICELINE_API_DEFAULT_SORT_BY,
      sortTitle: PRICELINE_API_DEFAULT_SORT_TITLE,
    },
    hotel_form_state: {
      check_in: "",
      check_out: "",
      rooms: "",
      adults: "",
      type: "hotels",
      city_id: "",
      city_latitude: "",
      city_longitude: "",
      where: "",
      sort_by: PRICELINE_API_DEFAULT_SORT_BY,
      selectedCity: [],
      isNew: true,
      isSearching: true,
    },
    subtypes: [],
    selectedSubtypes: [],
    establishments: [],
    establishmentsToFilter: [],
    filteredEstablishments: [],
    highPrice: 0,
    lowPrice: 999999,
    highGuestScore: 0,
    lowGuestScore: 999999,
    currencyLetters: "USD",
    currencySymbol: "$",
  },
  BookingPriceline: {
    booking_form_state: {
      check_in: "",
      check_out: "",
      rooms: "",
      adults: "",
      children: "",
      hotel_id: "",
      ppn_book_bundle: "",
    },
    booking_info: {
      check_in: "",
      check_out: "",
      num_rooms: "",
      num_adults: "",
      num_children: "",
      hotel_id: "",
      city_id: "",
    },
    booking_bundles: [],
    /* {    ppn_book_bundle: bundle_data.ppn_book_bundle,
                check_in: bundle_data.check_in,
                check_out: bundle_data.check_out,
                rate_currency: bundle_data.rate_currency,
                rate_avg: bundle_data.rate_avg,
                rate_processing_fee: bundle_data.rate_processing_fee,
                rate_fee: bundle_data.rate_fee,
                rate_total: bundle_data.rate_total,
                room_text: bundle_data.room_text,
                room_id: bundle_data.room_id,
                pcln_id: bundle_data.pcln_id, 
            }
        */
  },
  BookingForm: {
    status: false,
    changeHeader: false,
    activeTab: "plan a trip",
    activeTripSelection: "round-trip",
    MultiFlightsInput: ["input-0"],
    showMoreOptionsInMultiCity: false,
    disableFeatured: false,
    errorMessage: "",

    hotel_form_state: {
      check_in: "",
      check_out: "",
      rooms: "",
      adults: "",
      type: "hotels",
      currency: "usd",
      city_id: "",
      city_latitude: "",
      city_longitude: "",
      children: "0",
      package: "0",
      where: "",
    },
    train_bus_form_state: {
      check_in: "",
      check_out: "",
    },
  },

  emailAddressTextField: {
    checkEmailExsit: "",
    check_mail_exsit_axios_error: "",
    error: false,
    helperText: "",
    emailAddress: "",
  },
  handleTextField: {
    checkHandleExsit: "",
    check_handle_exsit_axios_error: "",
    error: false,
    helperText: "",
    handle: "",
  },
  signatureTextField: {
    signature: "",
  },
  firstNameTextField: {
    error: false,
    helperText: "",
    firstName: "",
  },
  lastNameTextField: {
    error: false,
    helperText: "",
    lastName: "",
  },
  passwordTextField: {
    error: false,
    helperText: "",
    password: "",
    passwordConfirmedError: false,
    passwordConfirmedhelperText: "",
    passwordConfirm: "",
    fieldType: "password",
  },
  photoAvatar: {
    progress: "",
    selectedFilePath: "",
    selectedFileName: "",
    isSelected: false,
  },
  hometownTextField: {
    hometownSelectedPostRequired: {
      placeId: "",
      city: "",
      country: "",
      state: "",
      latitude: "",
      longitude: "",
    },
    query: "",
    options: [],
    hometownSelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
    googleServiceActive: true,
    loadGoogleUrl: false,
  },
  homeTownAirportTextField: {
    airportResource: [],
    airportSelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  genderTextField: {
    isLoad: false,
    genderResource: [],
    genderSelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  orientationTextField: {
    isLoad: false,
    orientationResource: [],
    orientationSelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  religionTextField: {
    isLoad: false,
    religionResource: [],
    religionSelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  professionTextField: {
    isLoad: false,
    professionResource: [],
    professionSelected: [],
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  educationTextField: {
    isLoad: false,
    educationResource: [],
    educationSelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  cultureTextField: {
    isLoad: false,
    cultureResource: [],
    cultureSelected: [],
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  birthdayDatePiker: {
    birthday: null,
    error: false,
    textHelper: "",
  },
  languageTextField: {
    isLoad: false,
    languageResource: [],
    languageSelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
    languageFluency: [],
  },

  fluencyTextField: {
    isLoad: false,
    fluencyResource: [],
    fluencySelected: null,
    errorAPI: false,
    error: false,
    textHelper: "",
    open: false,
  },
  aboutMeTextField: {
    error: false,
    helperText: "",
    aboutMe: "",
  },
  instagramTextField: {
    error: false,
    helperText: "",
    instagram: "",
  },
  facebookTextField: {
    error: false,
    helperText: "",
    facebook: "",
  },
  twitterTextField: {
    error: false,
    helperText: "",
    twitter: "",
  },
  pinterestTextField: {
    error: false,
    helperText: "",
    pinterest: "",
  },
  snapchatTextField: {
    error: false,
    helperText: "",
    snapchat: "",
  },

  emailAddressLoginTextField: {
    error: false,
    helperText: "",
    emailAddressOrUsername: "",
  },

  passwordLoginTextField: {
    error: false,
    helperText: "",
    password: "",
    fieldType: "password",
  },
  generalSearch: {
    query: "",
    establishments: [],
    destinations: [],
    open: false,
    apiError: false,
    searcherId: "",
  },
  destinationVisitedSearch: {
    selectedDestinationSets: [],
    query: "",
    destinations: [],
    open: false,
    apiError: false,
    loadGoogleUrl: false,
  },
  personalization: {
    switch_container: "1",
    typeSets: [],
    barSets: [],
    checkBoxesSets: [],
  },
  emailAddressTextFieldCompanion: {
    checkEmailExsit: "",
    check_mail_exsit_axios_error: "",
    error: false,
    helperText: "",
    emailAddress: "",
  },
  firstNameTextFieldCompanion: {
    error: false,
    helperText: "",
    firstName: "",
  },
  lastNameTextFieldCompanion: {
    error: false,
    helperText: "",
    lastName: "",
  },
};

/* display activity reducer */
export default (state = initialState, action) => {
  switch (action.type) {
    /* HOTEL SEARCH FORM  */
    case SET_HOTEL_SEARCH_FORM:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          hotel_form_state: {
            ...state.HotelSearch.hotel_form_state,
            ...action.hotelSearchForm,
            /*  check_in: action.hotelSearchForm.check_in,
                        check_out: action.hotelSearchForm.check_out,
                        rooms: action.hotelSearchForm.rooms,
                        adults: action.hotelSearchForm.adults,
                        type: 'hotels',
                        currency: action.hotelSearchForm.currency,
                        city_id: action.hotelSearchForm.city_id,
                        city_latitude: action.hotelSearchForm.city_latitude,
                        city_longitude: action.hotelSearchForm.city_longitude,
                        where: action.hotelSearchForm.where, */
          },
        },
      };

    case SET_HOTEL_SEARCH_IS_NEW:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          hotel_form_state: {
            ...state.HotelSearch.hotel_form_state,
            isNew: action.isNew,
            /*  check_in: action.hotelSearchForm.check_in,
                        check_out: action.hotelSearchForm.check_out,
                        where: action.hotelSearchForm.where, */
          },
        },
      };

    case SET_HOTEL_SEARCH_IS_SEARCHING:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          hotel_form_state: {
            ...state.HotelSearch.hotel_form_state,
            isSearching: action.isSearching,
            /*  check_in: action.hotelSearchForm.check_in,
                        check_out: action.hotelSearchForm.check_out,
                        where: action.hotelSearchForm.where, */
          },
        },
      };

    case UPDATE_LAZYLOADING_URL_REQUESTS_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            url: action.api_url,
            offset: action.offset,
            requests: action.requests,
          },
        },
      };

    case UPDATE_LAZYLOADING_SORT_BY:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            sortCode: action.sortCode,
            sortTitle: action.sortTitle,
          },
        },
      };

    case UPDATE_LAZYLOADING_OFFSET_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            offset: action.offset,
          },
        },
      };
    case UPDATE_LAZYLOADING_LIMIT_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            requests: {
              ...state.HotelSearch.lazyLoading.requests,
              "proxyData[limit]": action.requests.limit,
            },
          },
        },
      };
    case UPDATE_LAZYLOADING_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            isFetching: action.is_fetching,
          },
        },
      };
    case UPDATE_LAZYLOADING_NO_MORE_HOTELS:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            noMore: action.no_more,
          },
        },
      };
    case UPDATE_LAZYLOADING_PROGRESS_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            progress: action.progress,
          },
        },
      };
    case UPDATE_LAZYLOADING_NO_PROGRESS_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            noProgress: action.no_progress,
          },
        },
      };

    case FETCH_ESTABLISMENT_SETS_LAZYLOADING_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishments: state.HotelSearch.establishments.concat(
            action.result_sets_establishments
          ),
        },
      };
    case FETCH_SORTING_OPTION_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          sortingOptions: action.sorting_options,
        },
      };
    case UPDATE_SEARCH_QUERY_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          lazyLoading: {
            ...state.HotelSearch.lazyLoading,
            searchquery: action.search_query,
          },
        },
      };

    case FETCH_API_ERROR_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          error: action.api_error,
        },
      };
    case PL_FETCH_HOTELS_LIST_SUCCESS:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          results: action.results,
        },
      };

    case PL_FETCH_HOTELS_LIST_FAILURE:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          api_error: action.payload,
        },
      };

    case CONVERT_HOTELS_TO_ESTABLISHMENTS:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishments: action.establishments,
        },
      };

    case FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {
            ...state.HotelSearch.establishment,
            generalInfo: {
              ...action.general_info,
              room_data: state.HotelSearch.establishment.generalInfo?.room_data,
              allowed_cards_data:
                state.HotelSearch.establishment.generalInfo?.allowed_cards_data,
            },
          },
        },
      };

    case FETCH_GENERAL_INFO_HOTEL_ESTABLISHMENT_FAILURE:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {
            ...state.HotelSearch.establishment,
            api_error: action.payload,
          },
        },
      };

    case FETCH_SUBTYPES_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          subtypes: action.subtypes,
          lowPrice: action.lowPrice,
          highPrice: action.highPrice,
          lowGuestScore: action.lowGuestScore,
          smopRates:action?.smopRates,
          resortFees:action?.resortFees,
          highGuestScore: action.highGuestScore,
          currencyLetters: action.currencyLetters,
          currencySymbol: action.currencySymbol,
          establishmentsToFilter: action.establishmentsToFilter,
        },
      };

    case SET_ESTABLISHMENTS_TO_FILTER:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishmentsToFilter: action.establishmentsToFilter,
        },
      };
      
    case SET_FILTERED_ESTABLISHMENTS:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          filteredEstablishments: action.filteredEstablishments,
        },
      };
      case SET_HOTEL_ESTABLISHMENTS_COPY:
        return {
          ...state,
          HotelSearch: {
            ...state.HotelSearch,
            copyHotelEstablishments: action.filteredEstablishments,
          },
        };
    case UPDATE_SELECTED_SUBTYPES_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          selectedSubtypes: action.selectedSubtypes,
        },
      };
    case CLEAN_SELECTED_SUBTYPES_HOTELS_LIST:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          selectedSubtypes: [],
          establishments: [],
        },
      };

    case FETCH_CONTRACT_INFO_HOTEL_BOOKING:
      return {
        ...state,
        BookingPriceline: {
          ...state.BookingPriceline,
          booking_info: action.booking_info,
        },
      };

    case FETCH_CONTRACT_INFO_HOTEL_BOOKING_FAILURE:
      return {
        ...state,
        BookingPriceline: {
          ...state.BookingPriceline,
          booking_info: {
            ...state.BookingPriceline.booking_info,
            api_error: action.payload,
          },
        },
      };

    case ADD_BUNDLE_TO_HOTEL_BOOKING:
      return {
        ...state,
        BookingPriceline: {
          ...state.BookingPriceline,
          booking_bundles: action.booking_bundles,
          booking_info: action.booking_info,
        },
      };

    case ADD_BUNDLE_TO_HOTEL_BOOKING_FAILURE:
      return {
        ...state,
        BookingPriceline: {
          ...state.BookingPriceline,
          booking_info: {
            ...state.BookingPriceline.booking_info,
            api_error: action.payload,
          },
        },
      };

    case REMOVE_BUNDLE_TO_HOTEL_BOOKING:
      return {
        ...state,
        BookingPriceline: {
          ...state.BookingPriceline,
          booking_bundles: action.booking_bundles,
        },
      };

    case REMOVE_BUNDLE_TO_HOTEL_BOOKING_FAILURE:
      return {
        ...state,
        BookingPriceline: {
          ...state.BookingPriceline,
          booking_info: {
            ...state.BookingPriceline.booking_info,
            api_error: action.payload,
          },
        },
      };

    case SET_HOTEL_FORM_STATE:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          hotel_form_state: action.hotel_form_state,
        },
      };

    case SET_BOOKING_FORM_STATE:
      return {
        ...state,
        BookingPriceline: {
          ...state.BookingPriceline,
          booking_form_state: action.booking_form_state,
        },
      };
    case SET_HOTEL_ESTABLISHMENT_ID_ESTABLISHMENT:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {},
        },
        establishment: {
          ...state.establishment,
          activeId: action.active_id,
        },
      };

    case SET_SLIDER_INDEX:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {
            ...state.HotelSearch.establishment,
            sliderIndex: action.sliderIndex,
          },
        },
      };

    case FETCH_SLIDER_IMAGES_HOTEL_ESTABLISHMENT:
      let allImages = action.slider_images;
      allImages = allImages.map((item) => {
        let imageName = item.imageName;
        if (imageName) {
          imageName = imageName.replace("max500", "max1500");
        }

        const image = { description: item.description, imageName: imageName };
        return image;
      });
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {
            ...state.HotelSearch.establishment,
            sliderImages: allImages,
            sliderIndex: action.sliderIndex,
          },
        },
      };

    case FETCH_REVIEWS_HOTEL_ESTABLISHMENT:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {
            ...state.HotelSearch.establishment,
            reviewsAverage: action.reviewsAverage,
            review_rating_desc: action.review_rating_desc,
            reviewsGoogleAndYelp: {
              ...state.HotelSearch.establishment.reviewsGoogleAndYelp,
              priceline: action.reviewsPriceline,
            },
            reviewsFromApi: action.reviewsFromApi,
          },
        },
      };

    case FETCH_GENERAL_INFO_ROOMS:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {
            ...state.HotelSearch.establishment,
            generalInfo: {
              ...state.HotelSearch.establishment.generalInfo,
              room_data: action.room_data,
              allowed_cards_data: action.allowed_cards_data,
              amenity_data: action.amenity_data,
            },
          },
        },
      };

    case FETCH_GENERAL_INFO_ROOMS_FAILURE:
      return {
        ...state,
        HotelSearch: {
          ...state.HotelSearch,
          establishment: {
            ...state.HotelSearch.establishment,
            api_error: action.payload,
          },
        },
      };

    /* email address  */
    case CEHCK_EMAIL_EXIST_FAILURE:
      return {
        ...state,
        emailAddressTextField: {
          ...state.emailAddressTextField,
          checkEmailExsit: action.check_email_exsit,
          check_mail_exsit_axios_error: action.axios_error,
        },
      };

    case CEHCK_EMAIL_EXIST_SUCCESS:
      return {
        ...state,
        emailAddressTextField: {
          ...state.emailAddressTextField,
          checkEmailExsit: action.check_email_exsit,
          check_mail_exsit_axios_error: action.axios_error,
        },
      };
    case SET_EMAIL_ADDRESS:
      return {
        ...state,
        emailAddressTextField: {
          ...state.emailAddressTextField,
          emailAddress: action.email_address,
        },
      };
    case SET_EMAIL_ADDRESS_ERROR:
      return {
        ...state,
        emailAddressTextField: {
          ...state.emailAddressTextField,
          error: action.error,
        },
      };
    case SET_EMAIL_ADDRESS_TEXT_HELPER:
      return {
        ...state,
        emailAddressTextField: {
          ...state.emailAddressTextField,
          textHelper: action.text_helper,
        },
      };

      /* muver account handle */
    case CEHCK_HANDLE_EXIST_FAILURE:
      return {
        ...state,
        handleTextField: {
          ...state.handleTextField,
          checkHanldeExsit: action.check_hanlde_exsit,
          check_handle_exsit_axios_error: action.axios_error,
        },
      };
    case CEHCK_HANDLE_EXIST_SUCCESS:
      return {
        ...state,
        handleTextField: {
          ...state.handleTextField,
          checkHanldeExsit: action.check_hanlde_exsit,
          check_handle_exsit_axios_error: action.axios_error,
        },
      };
    case SET_USER_SIGNATURE:
      return {
        ...state,
        signatureTextField: {
          ...state.signatureTextField,
          signature: action.signature,
        },
      };
    case SET_HANDLE:
      return {
        ...state,
        handleTextField: {
          ...state.handleTextField,
          handle: action.hanlde,
        },
      };
    case SET_HANDLE_ERROR:
      return {
        ...state,
        handleTextField: {
          ...state.handleTextField,
          error: action.error,
        },
      };
    case SET_HANDLE_TEXT_HELPER:
      return {
        ...state,
        handleTextField: {
          ...state.handleTextField,
          textHelper: action.text_helper,
        },
      };
      /* first name  */
    case SET_FIRST_NAME:
      return {
        ...state,
        firstNameTextField: {
          ...state.firstNameTextField,
          firstName: action.first_name,
        },
      };
    case SET_FIRST_NAME_ERROR:
      return {
        ...state,
        firstNameTextField: {
          ...state.firstNameTextField,
          error: action.error,
        },
      };
    case SET_FIRST_NAME_TEXT_HELPER:
      return {
        ...state,
        firstNameTextField: {
          ...state.firstNameTextField,
          textHelper: action.text_helper,
        },
      };
      /* last name  */
    case SET_LAST_NAME:
      return {
        ...state,
        lastNameTextField: {
          ...state.lastNameTextField,
          lastName: action.last_name,
        },
      };
    case SET_LAST_NAME_ERROR:
      return {
        ...state,
        lastNameTextField: {
          ...state.lastNameTextField,
          error: action.error,
        },
      };
    case SET_LAST_NAME_TEXT_HELPER:
      return {
        ...state,
        lastNameTextField: {
          ...state.lastNameTextField,
          textHelper: action.text_helper,
        },
      };
      /* password  */
    case SET_PASSWORD:
      return {
        ...state,
        passwordTextField: {
          ...state.passwordTextField,
          password: action.password,
        },
      };
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        passwordTextField: {
          ...state.passwordTextField,
          error: action.error,
        },
      };
    case SET_PASSWORD_TEXT_HELPER:
      return {
        ...state,
        passwordTextField: {
          ...state.passwordTextField,
          textHelper: action.text_helper,
        },
      };
      /* password confirm  */
    case SET_PASSWORD_CONFIRM:
      return {
        ...state,
        passwordTextField: {
          ...state.passwordTextField,
          passwordConfirm: action.password_confirm,
        },
      };
    case SET_PASSWORD_CONFIRM_ERROR:
      return {
        ...state,
        passwordTextField: {
          ...state.passwordTextField,
          passwordConfirmedError: action.error,
        },
      };
    case SET_PASSWORD_CONFIRM_TEXT_HELPER:
      return {
        ...state,
        passwordTextField: {
          ...state.passwordTextField,
          passwordConfirmedhelperText: action.text_helper,
        },
      };
    case SET_PASSWORD_TEXT_FIELD_TYPE:
      return {
        ...state,
        passwordTextField: {
          ...state.passwordTextField,
          fieldType: action.field_type,
        },
      };
    case SET_SELECTED_PROFILE_PHOTO_AVATAR:
      return {
        ...state,
        photoAvatar: {
          ...state.photoAvatar,
          selectedFilePath: action.selected_file_path,
          isSelected: action.is_selected,
        },
      };
    case SET_SELECTED_PROFILE_PHOTO_AVATAR_PROGRESS:
      return {
        ...state,
        photoAvatar: {
          ...state.photoAvatar,
          progress: action.progress,
        },
      };
      /* home actions */
    case SET_HOMETOWN_SELECTED:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          hometownSelected: action.hometown_selected,
        },
      };
    case FETCH_HOMETOWN_API_ERROR:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_HOMETOWN_ERROR:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          error: action.error,
        },
      };
    case SET_HOMETOWN_TEXT_HELPER:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_HOMETOWN_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          open: action.open,
        },
      };
    case SET_HOMETOWN_LOAD_GOOGLE_URL:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          loadGoogleUrl: action.load_google_url,
        },
      };
    case SET_HOMETOWN_SEARCH_QUERY:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          query: action.query,
        },
      };
    case SET_HOMETOWN_OPTIONS:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          options: action.options,
        },
      };
    case SET_HOMETOWN_SERVICE_ACTIVE:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          googleServiceActive: action.active,
        },
      };
    case SET_HOMETOWN_SELECTED_POST_REQUIRED:
      return {
        ...state,
        hometownTextField: {
          ...state.hometownTextField,
          hometownSelectedPostRequired: {
            ...state.hometownTextField.hometownSelectedPostRequired,
            placeId: action.place_id,
            city: action.city,
            country: action.country,
            state: action.state,
            latitude: action.latitude,
            longitude: action.longitude,
          },
        },
      };
    /*  airport actions */
    case SET_HOMETOWN_AIRPORT_SELECTED:
      return {
        ...state,
        homeTownAirportTextField: {
          ...state.homeTownAirportTextField,
          airportSelected: action.airport_selected,
        },
      };
    case FETCH_HOMETOWN_AIRPORT_DATA:
      return {
        ...state,
        homeTownAirportTextField: {
          ...state.homeTownAirportTextField,
          airportResource: action.airport_resource,
        },
      };
    case FETCH_HOMETOWN_AIRPORT_API_ERROR:
      return {
        ...state,
        homeTownAirportTextField: {
          ...state.homeTownAirportTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_HOMETOWN_AIRPORT_ERROR:
      return {
        ...state,
        homeTownAirportTextField: {
          ...state.homeTownAirportTextField,
          error: action.error,
        },
      };
    case SET_HOMETOWN_AIRPORT_TEXT_HELPER:
      return {
        ...state,
        homeTownAirportTextField: {
          ...state.homeTownAirportTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_HOMETOWN_AIRPORT_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        homeTownAirportTextField: {
          ...state.homeTownAirportTextField,
          open: action.open,
        },
      };
    /* gender */
    case SET_GENDER_SELECTED:
      return {
        ...state,
        genderTextField: {
          ...state.genderTextField,
          genderSelected: action.gender_selected,
        },
      };
    case FETCH_GENDER_DATA:
      return {
        ...state,
        genderTextField: {
          ...state.genderTextField,
          genderResource: action.gender_resource,
        },
      };
    case FETCH_GENDER_API_ERROR:
      return {
        ...state,
        genderTextField: {
          ...state.genderTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_GENDER_ERROR:
      return {
        ...state,
        genderTextField: {
          ...state.genderTextField,
          error: action.error,
        },
      };
    case SET_GENDER_TEXT_HELPER:
      return {
        ...state,
        genderTextField: {
          ...state.genderTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_GENDER_COMPONENT_STATUS:
      return {
        ...state,
        genderTextField: {
          ...state.genderTextField,
          open: action.open,
        },
      };
    case SET_GENDER_LOAD_RESOURCE:
      return {
        ...state,
        genderTextField: {
          ...state.genderTextField,
          isLoad: action.is_load,
        },
      };
      /* orientation */
    case SET_ORIENTATION_SELECTED:
      return {
        ...state,
        orientationTextField: {
          ...state.orientationTextField,
          orientationSelected: action.orientation_selected,
        },
      };
    case FETCH_ORIENTATION_DATA:
      return {
        ...state,
        orientationTextField: {
          ...state.orientationTextField,
          orientationResource: action.orientation_resource,
        },
      };
    case FETCH_ORIENTATION_API_ERROR:
      return {
        ...state,
        orientationTextField: {
          ...state.orientationTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_ORIENTATION_ERROR:
      return {
        ...state,
        orientationTextField: {
          ...state.orientationTextField,
          error: action.error,
        },
      };
    case SET_ORIENTATION_TEXT_HELPER:
      return {
        ...state,
        orientationTextField: {
          ...state.orientationTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_ORIENTATION_COMPONENT_STATUS:
      return {
        ...state,
        orientationTextField: {
          ...state.orientationTextField,
          open: action.open,
        },
      };
    case SET_ORIENTATION_LOAD_RESOURCE:
      return {
        ...state,
        orientationTextField: {
          ...state.orientationTextField,
          isLoad: action.is_load,
        },
      };
      /* religion */
    case SET_RELIGION_SELECTED:
      return {
        ...state,
        religionTextField: {
          ...state.religionTextField,
          religionSelected: action.religion_selected,
        },
      };
    case FETCH_RELIGION_DATA:
      return {
        ...state,
        religionTextField: {
          ...state.religionTextField,
          religionResource: action.religion_resource,
        },
      };
    case FETCH_RELIGION_API_ERROR:
      return {
        ...state,
        religionTextField: {
          ...state.religionTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_RELIGION_ERROR:
      return {
        ...state,
        religionTextField: {
          ...state.religionTextField,
          error: action.error,
        },
      };
    case SET_RELIGION_TEXT_HELPER:
      return {
        ...state,
        religionTextField: {
          ...state.religionTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_RELIGION_COMPONENT_STATUS:
      return {
        ...state,
        religionTextField: {
          ...state.religionTextField,
          open: action.open,
        },
      };
    case SET_RELIGION_LOAD_RESOURCE:
      return {
        ...state,
        religionTextField: {
          ...state.religionTextField,
          isLoad: action.is_load,
        },
      };
      /* profession */
    case SET_PROFESSION_SELECTED:
      return {
        ...state,
        professionTextField: {
          ...state.professionTextField,
          professionSelected: action.profession_selected,
        },
      };
    case FETCH_PROFESSION_DATA:
      return {
        ...state,
        professionTextField: {
          ...state.professionTextField,
          professionResource: action.profession_resource,
        },
      };
    case FETCH_PROFESSION_API_ERROR:
      return {
        ...state,
        professionTextField: {
          ...state.professionTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_PROFESSION_ERROR:
      return {
        ...state,
        professionTextField: {
          ...state.professionTextField,
          error: action.error,
        },
      };
    case SET_PROFESSION_TEXT_HELPER:
      return {
        ...state,
        professionTextField: {
          ...state.professionTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_PROFESSION_COMPONENT_STATUS:
      return {
        ...state,
        professionTextField: {
          ...state.professionTextField,
          open: action.open,
        },
      };
    case SET_PROFESSION_LOAD_RESOURCE:
      return {
        ...state,
        professionTextField: {
          ...state.professionTextField,
          isLoad: action.is_load,
        },
      };

      /* education */
    case SET_EDUCATION_SELECTED:
      return {
        ...state,
        educationTextField: {
          ...state.educationTextField,
          educationSelected: action.education_selected,
        },
      };
    case FETCH_EDUCATION_DATA:
      return {
        ...state,
        educationTextField: {
          ...state.educationTextField,
          educationResource: action.education_resource,
        },
      };
    case FETCH_EDUCATION_API_ERROR:
      return {
        ...state,
        educationTextField: {
          ...state.educationTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_EDUCATION_ERROR:
      return {
        ...state,
        educationTextField: {
          ...state.educationTextField,
          error: action.error,
        },
      };
    case SET_EDUCATION_TEXT_HELPER:
      return {
        ...state,
        educationTextField: {
          ...state.educationTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_EDUCATION_COMPONENT_STATUS:
      return {
        ...state,
        educationTextField: {
          ...state.educationTextField,
          open: action.open,
        },
      };
    case SET_EDUCATION_LOAD_RESOURCE:
      return {
        ...state,
        educationTextField: {
          ...state.educationTextField,
          isLoad: action.is_load,
        },
      };

      /* culture */
    case SET_CULTURE_SELECTED:
      return {
        ...state,
        cultureTextField: {
          ...state.cultureTextField,
          cultureSelected: action.culture_selected,
        },
      };
    case FETCH_CULTURE_DATA:
      return {
        ...state,
        cultureTextField: {
          ...state.cultureTextField,
          cultureResource: action.culture_resource,
        },
      };
    case FETCH_CULTURE_API_ERROR:
      return {
        ...state,
        cultureTextField: {
          ...state.cultureTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_CULTURE_ERROR:
      return {
        ...state,
        cultureTextField: {
          ...state.cultureTextField,
          error: action.error,
        },
      };
    case SET_CULTURE_TEXT_HELPER:
      return {
        ...state,
        cultureTextField: {
          ...state.cultureTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_CULTURE_COMPONENT_STATUS:
      return {
        ...state,
        cultureTextField: {
          ...state.cultureTextField,
          open: action.open,
        },
      };
    case SET_CULTURE_LOAD_RESOURCE:
      return {
        ...state,
        cultureTextField: {
          ...state.cultureTextField,
          isLoad: action.is_load,
        },
      };
    case SET_BIRTHDAY:
      return {
        ...state,
        birthdayDatePiker: {
          ...state.birthdayDatePiker,
          birthday: action.date,
        },
      };
    case SET_BIRTHDAY_ERROR:
      return {
        ...state,
        birthdayDatePiker: {
          ...state.birthdayDatePiker,
          error: action.error,
        },
      };
    case SET_BIRTHDAY_TEXT_HELPER:
      return {
        ...state,
        birthdayDatePiker: {
          ...state.birthdayDatePiker,
          textHelper: action.text_helper,
        },
      };

      /* language */
    case SET_LANGUAGE_FLUENCY_SELECTED:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          languageFluency: [
            ...state.languageTextField.languageFluency,
            action.language_fluency_selected,
          ],
        },
      };
    case SET_LANGUAGE_FLUENCY:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          languageFluency: action.language_fluency,
        },
      };
    case REMOVE_LANGUAGE_FLUENCY_SELECTED:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          languageFluency: state.languageTextField.languageFluency.filter(
            (val) => val.ids !== action.ids
          ),
        },
      };
    case REMOVE_EXIST_LANGUAGE_FLUENCY_SELECTED:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          languageFluency: state.languageTextField.languageFluency.filter(
            (val) => val.language.id !== action.language_id
          ),
        },
      };
    case SET_LANGUAGE_SELECTED:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          languageSelected: action.language_selected,
        },
      };
    case FETCH_LANGUAGE_DATA:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          languageResource: action.language_resource,
        },
      };
    case FETCH_LANGUAGE_API_ERROR:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_LANGUAGE_ERROR:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          error: action.error,
        },
      };
    case SET_LANGUAGE_TEXT_HELPER:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_LANGUAGE_COMPONENT_STATUS:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          open: action.open,
        },
      };
    case SET_LANGUAGE_LOAD_RESOURCE:
      return {
        ...state,
        languageTextField: {
          ...state.languageTextField,
          isLoad: action.is_load,
        },
      };

      /* fluency */
    case SET_FLUENCY_SELECTED:
      return {
        ...state,
        fluencyTextField: {
          ...state.fluencyTextField,
          fluencySelected: action.fluency_selected,
        },
      };
    case FETCH_FLUENCY_DATA:
      return {
        ...state,
        fluencyTextField: {
          ...state.fluencyTextField,
          fluencyResource: action.fluency_resource,
        },
      };
    case FETCH_FLUENCY_API_ERROR:
      return {
        ...state,
        fluencyTextField: {
          ...state.fluencyTextField,
          errorAPI: action.error_api,
        },
      };
    case SET_FLUENCY_ERROR:
      return {
        ...state,
        fluencyTextField: {
          ...state.fluencyTextField,
          error: action.error,
        },
      };
    case SET_FLUENCY_TEXT_HELPER:
      return {
        ...state,
        fluencyTextField: {
          ...state.fluencyTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_FLUENCY_COMPONENT_STATUS:
      return {
        ...state,
        fluencyTextField: {
          ...state.fluencyTextField,
          open: action.open,
        },
      };
    case SET_FLUENCY_LOAD_RESOURCE:
      return {
        ...state,
        fluencyTextField: {
          ...state.fluencyTextField,
          isLoad: action.is_load,
        },
      };
      /* about me  */
    case SET_ABOUT_ME:
      return {
        ...state,
        aboutMeTextField: {
          ...state.aboutMeTextField,
          aboutMe: action.about_me,
        },
      };
    case SET_ABOUT_ME_ERROR:
      return {
        ...state,
        aboutMeTextField: {
          ...state.aboutMeTextField,
          error: action.error,
        },
      };
    case SET_ABOUT_ME_TEXT_HELPER:
      return {
        ...state,
        aboutMeTextField: {
          ...state.aboutMeTextField,
          textHelper: action.text_helper,
        },
      };
      /* instagram  */
    case SET_INSTAGRAM:
      return {
        ...state,
        instagramTextField: {
          ...state.instagramTextField,
          instagram: action.instagram,
        },
      };
    case SET_INSTAGRAM_ERROR:
      return {
        ...state,
        instagramTextField: {
          ...state.instagramTextField,
          error: action.error,
        },
      };
    case SET_INSTAGRAM_TEXT_HELPER:
      return {
        ...state,
        instagramTextField: {
          ...state.instagramTextField,
          textHelper: action.text_helper,
        },
      };
      /* facebook  */
    case SET_FACEBOOK:
      return {
        ...state,
        facebookTextField: {
          ...state.facebookTextField,
          facebook: action.facebook,
        },
      };
    case SET_FACEBOOK_ERROR:
      return {
        ...state,
        facebookTextField: {
          ...state.facebookTextField,
          error: action.error,
        },
      };
    case SET_FACEBOOK_TEXT_HELPER:
      return {
        ...state,
        facebookTextField: {
          ...state.facebookTextField,
          textHelper: action.text_helper,
        },
      };
      /* pinterest  */
    case SET_PINTEREST:
      return {
        ...state,
        pinterestTextField: {
          ...state.pinterestTextField,
          pinterest: action.pinterest,
        },
      };
    case SET_PINTEREST_ERROR:
      return {
        ...state,
        pinterestTextField: {
          ...state.pinterestTextField,
          error: action.error,
        },
      };
    case SET_PINTEREST_TEXT_HELPER:
      return {
        ...state,
        pinterestTextField: {
          ...state.pinterestTextField,
          textHelper: action.text_helper,
        },
      };
      /* twitter  */
    case SET_TWITTER:
      return {
        ...state,
        twitterTextField: {
          ...state.twitterTextField,
          twitter: action.twitter,
        },
      };
    case SET_TWITTER_ERROR:
      return {
        ...state,
        twitterTextField: {
          ...state.twitterTextField,
          error: action.error,
        },
      };
    case SET_TWITTER_TEXT_HELPER:
      return {
        ...state,
        twitterTextField: {
          ...state.twitterTextField,
          textHelper: action.text_helper,
        },
      };
      /* snapchat  */
    case SET_SNAPCHAT:
      return {
        ...state,
        snapchatTextField: {
          ...state.snapchatTextField,
          snapchat: action.snapchat,
        },
      };
    case SET_SNAPCHAT_ERROR:
      return {
        ...state,
        snapchatTextField: {
          ...state.snapchatTextField,
          error: action.error,
        },
      };
    case SET_SNAPCHAT_TEXT_HELPER:
      return {
        ...state,
        snapchatTextField: {
          ...state.snapchatTextField,
          textHelper: action.text_helper,
        },
      };
      /* email address login */
    case SET_EMAIL_ADDRESS_LOGIN:
      return {
        ...state,
        emailAddressLoginTextField: {
          ...state.emailAddressLoginTextField,
          emailAddressOrUsername: action.email_address_or_username,
        },
      };
    case SET_EMAIL_ADDRESS_LOGIN_ERROR:
      return {
        ...state,
        emailAddressLoginTextField: {
          ...state.emailAddressLoginTextField,
          error: action.error,
        },
      };
    case SET_EMAIL_ADDRESS_LOGIN_TEXT_HELPER:
      return {
        ...state,
        emailAddressLoginTextField: {
          ...state.emailAddressLoginTextField,
          textHelper: action.text_helper,
        },
      };
      /* password login */
    case SET_PASSWORD_LOGIN:
      return {
        ...state,
        passwordLoginTextField: {
          ...state.passwordLoginTextField,
          password: action.password,
        },
      };
    case SET_PASSWORD_LOGIN_ERROR:
      return {
        ...state,
        passwordLoginTextField: {
          ...state.passwordLoginTextField,
          error: action.error,
        },
      };
    case SET_PASSWORD_LOGIN_TEXT_HELPER:
      return {
        ...state,
        passwordLoginTextField: {
          ...state.passwordLoginTextField,
          textHelper: action.text_helper,
        },
      };
    case SET_PASSWORD_LOGIN_TEXT_FIELD_TYPE:
      return {
        ...state,
        passwordLoginTextField: {
          ...state.passwordLoginTextField,
          fieldType: action.field_type,
        },
      };
      /* top bar search */
    case FETCH_GENERAL_SEARCH:
      return {
        ...state,
        generalSearch: {
          ...state.generalSearch,
          establishments: action.establishments,
          destinations: action.destinations,
        },
      };
    case FETCH_GENERAL_SEARCH_QUERY:
      return {
        ...state,
        generalSearch: {
          ...state.generalSearch,
          query: action.query,
        },
      };
    case FETCH_GENERAL_SEARCH_ERROR:
      return {
        ...state,
        generalSearch: {
          ...state.generalSearch,
          apiError: action.api_error,
        },
      };
    case FETCH_GENERAL_SEARCH_STATUS:
      return {
        ...state,
        generalSearch: {
          ...state.generalSearch,
          open: action.open,
        },
      };
      /* landing destination visited search */
    //SET_GENERAL_SEARCH_SEARCHER_ID
    case SET_GENERAL_SEARCH_SEARCHER_ID:
      return {
        ...state,
        generalSearch: {
          ...state.generalSearch,
          searcherId: action.searcherId,
        },
      };
      /* landing destination visited search */
    case FETCH_DESTINATION_VISITED_SEARCH:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          destinations: action.destinations,
        },
      };
    case FETCH_DESTINATION_VISITED_SEARCH_QUERY:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          query: action.query,
        },
      };
    case FETCH_DESTINATION_VISITED_SEARCH_ERROR:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          apiError: action.api_error,
        },
      };
    case FETCH_DESTINATION_VISITED_SEARCH_STATUS:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          open: action.open,
        },
      };
    case SET_SELECTED_DESTINATION_VISITED:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          selectedDestinationSets: action.selected_destination_sets,
        },
      };
    case SELECTED_DESTINATION_VISITED_MERGE:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          selectedDestinationSets: [
            ...state.destinationVisitedSearch.selectedDestinationSets,
            action.destination,
          ],
        },
      };
    case DELETE_DESTINATION_VISITED:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          selectedDestinationSets:
            state.destinationVisitedSearch.selectedDestinationSets.filter(
              (val) => val.destinationId !== action.destination_id
            ),
        },
      };
    case SET_DESTINATION_VISITED_LOAD_GOOGLE_URL:
      return {
        ...state,
        destinationVisitedSearch: {
          ...state.destinationVisitedSearch,
          loadGoogleUrl: action.load_google_url,
        },
      };
    case FETCH_PERSONALIZATION_TYPE_SETS:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          typeSets: action.types,
        },
      };
    case FETCH_PERSONALIZATION:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          barSets: action.bars,
          checkBoxesSets: action.check_boxes,
        },
      };
    case FETCH_PERSONALIZATION_SWITCH_CONTAINER:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          switch_container: action.switch_container,
        },
      };
    case UPDATE_PERSONALIZATION_SCORE:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          checkBoxesSets: state.personalization.checkBoxesSets.map((group) =>
            group.map((checkbox) => {
              if (checkbox.id !== action.id) {
                return checkbox;
              }
              return {
                ...checkbox,
                score: action.score,
              };
            })
          ),
        },
      };
    case UPDATE_PERSONALIZATION_BARS_SCORE:
      return {
        ...state,
        personalization: {
          ...state.personalization,
          barSets: state.personalization.barSets.map((group) => {
            if (group.id !== action.bars_id) {
              return group;
            }
            return {
              ...group,
              avg: action.avg,
              subTypes_bars: group.subTypes_bars.map((bar) => {
                if (bar.id !== action.id) {
                  return bar;
                }
                return {
                  ...bar,
                  score: action.score,
                };
              }),
            };
          }),
        },
      };
    case SWITCH_BOOKING_FORM_ACTIVE_TAB:
      return {
        ...state,
        BookingForm: {
          ...state.BookingForm,
          activeTab: action.active_tab,
        },
      };
    case SWITCH_BOOKING_FORM_STATUS:
      return {
        ...state,
        BookingForm: {
          ...state.BookingForm,
          status: action.status,
        },
      };

    case CLEAR_ALL_TIMELINE:
      return initialState;

    case CLEAR_TIMELINE_TRIP:
      return {
        ...state,
        TimelineWizard: initialState.TimelineWizard,
      };

    /*  search address actions */
    case SET_SEARCH_ADDRESS_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchAddress: {
            ...state.TimelineWizard.searchAddress,
            addressSelected: action.address_selected,
          },
        },
      };
    case FETCH_SEARCH_ADDRESS_API_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchAddress: {
            ...state.TimelineWizard.searchAddress,
            errorAPI: action.error_api,
          },
        },
      };
    case SET_SEARCH_ADDRESS_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchAddress: {
            ...state.TimelineWizard.searchAddress,
            error: action.error,
          },
        },
      };
    case SET_SEARCH_ADDRESS_TEXT_HELPER:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchAddress: {
            ...state.TimelineWizard.searchAddress,
            textHelper: action.text_helper,
          },
        },
      };
    case FETCH_SEARCH_ADDRESS_DATA:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchAddress: {
            ...state.TimelineWizard.searchAddress,
            searchAddressSet: action.search_address_set,
          },
        },
      };

    case SET_SEARCH_ADDRESS_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchAddress: {
            ...state.TimelineWizard.searchAddress,
            open: action.open,
          },
        },
      };

    /*  search place actions */
    case SET_SEARCH_PLACE_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchPlace: {
            ...state.TimelineWizard.searchPlace,
            placeSelected: action.place_selected,
          },
        },
      };
    case FETCH_SEARCH_PLACE_DATA:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchPlace: {
            ...state.TimelineWizard.searchPlace,
            searchPlaceSet: action.search_place_set,
          },
        },
      };
    case FETCH_SEARCH_PLACE_API_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchPlace: {
            ...state.TimelineWizard.searchPlace,
            errorAPI: action.error_api,
          },
        },
      };
    case SET_SEARCH_PLACE_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchPlace: {
            ...state.TimelineWizard.searchPlace,
            error: action.error,
          },
        },
      };
    case SET_SEARCH_PLACE_TEXT_HELPER:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchPlace: {
            ...state.TimelineWizard.searchPlace,
            textHelper: action.text_helper,
          },
        },
      };
    case SET_SEARCH_PLACE_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchPlace: {
            ...state.TimelineWizard.searchPlace,
            open: action.open,
          },
        },
      };

    /*  search destination actions */
    case SET_SEARCH_DESTINATION_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchDestination: {
            ...state.TimelineWizard.searchDestination,
            destinationSelected: action.destination_selected,
          },
        },
      };
    case FETCH_TIME_TO_REACH_OPTIONS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          timeToReachOptions: [...action.timeToReachOptionsSet],
        },
      };
    case FETCH_TRANSPORT_MODES:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          transportModes: [...action.transportModes],
        },
      };
    case FETCH_SEARCH_DESTINATION_DATA:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchDestination: {
            ...state.TimelineWizard.searchDestination,
            searchDestinationSet: action.search_destination_set,
          },
        },
      };
    case FETCH_SEARCH_DESTINATION_API_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchDestination: {
            ...state.TimelineWizard.searchDestination,
            errorAPI: action.error_api,
          },
        },
      };
    case SET_SEARCH_DESTINATION_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchDestination: {
            ...state.TimelineWizard.searchDestination,
            error: action.error,
          },
        },
      };
    case SET_SEARCH_DESTINATION_TEXT_HELPER:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchDestination: {
            ...state.TimelineWizard.searchDestination,
            textHelper: action.text_helper,
          },
        },
      };
    case SET_SEARCH_DESTINATION_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchDestination: {
            ...state.TimelineWizard.searchDestination,
            open: action.open,
          },
        },
      };

    /*  search ESTABLISHMENT actions */
    case SET_SEARCH_ESTABLISHMENT_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchEstablishment: {
            ...state.TimelineWizard.searchEstablishment,
            establishmentSelected: action.establishment_selected,
          },
        },
      };
    case FETCH_SEARCH_ESTABLISHMENT_DATA:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchEstablishment: {
            ...state.TimelineWizard.searchEstablishment,
            searchEstablishmentSet: action.search_establishment_set,
          },
        },
      };
    case FETCH_SEARCH_ESTABLISHMENT_API_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchEstablishment: {
            ...state.TimelineWizard.searchEstablishment,
            errorAPI: action.error_api,
          },
        },
      };
    case SET_SEARCH_ESTABLISHMENT_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchEstablishment: {
            ...state.TimelineWizard.searchEstablishment,
            error: action.error,
          },
        },
      };
    case SET_SEARCH_ESTABLISHMENT_TEXT_HELPER:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchEstablishment: {
            ...state.TimelineWizard.searchEstablishment,
            textHelper: action.text_helper,
          },
        },
      };
    case SET_SEARCH_ESTABLISHMENT_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          searchEstablishment: {
            ...state.TimelineWizard.searchEstablishment,
            open: action.open,
          },
        },
      };
    /* end search establishment */
    case SET_TIMELINE_ACTIVE_TRIP_ID:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripId: action.trip_id,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_NAME:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripName: action.trip_name,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_START_DATE:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripStartDate: action.trip_start_date,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_DURATION:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDuration: action.trip_duration,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_DESCRIPTION:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDescription: action.trip_description,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_AUTH_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripAuthStatus: action.trip_auth_status,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_IMAGE:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripImage: action.trip_image,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_COST:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripCost: {
              ...state.TimelineWizard.activeTrip.tripCost,
              cost: action.trip_cost,
            },
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_COST_FETCH_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripCost: {
              ...state.TimelineWizard.activeTrip.tripCost,
              fetching: action.status,
            },
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_NO_DATE:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripNoDate: action.trip_no_date,
          },
        },
      };
    case SET_TIMELINE_ACTIVE_TRIP_DESTINATIONS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinations: action.trip_destinations,
            tripDestinationsOpenStatus: action.trip_destinations.map((d) => {
              return {
                tripDestinationId: d.td_id,
                openStatus: d.openStatus,
              };
            }),
          },
        },
      };
    case SET_TIMELINE_WIZARD_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          status: action.status,
        },
      };
    case SET_TIMELINE_WIZARD_DELETE_ALART_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          alartModalStatusDeleting: action.status,
        },
      };
    case SET_TIMELINE_WIZARD_DRAGGING_ESTABLISHMENT:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          draggingEstablishment: {
            ...state.TimelineWizard.draggingEstablishment,
            draggingEstablishmentDestinationId: action.destination_id,
            draggingEstablishment: action.dragging_establisment,
          },
        },
      };
    case SET_TIMELINE_WIZARD_DRAGGING_ACTIVITY:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          draggingActivity: {
            ...state.TimelineWizard.draggingActivity,
            dragActivityIndexInDay: action.dragging_activity_index_in_day,
            dragActivityInDestination: action.dragging_activity_in_destination,
            dragDeleteActivity: action.drag_delete_activity,
            draggingActivity: action.dragging_activity,
            dragActivityIdsInDay: action.drag_ctivity_ids_in_day,
          },
        },
      };
    case SET_TIMELINE_WIZARD_FETCHING_ESTABLISHMENTS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          isFetchingEstablishments: action.is_fetching_establishments,
        },
      };
    case SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinationsOpenStatus:
              state.TimelineWizard.activeTrip.tripDestinationsOpenStatus.map(
                (destination) => {
                  if (
                    destination.tripDestinationId !== action.trip_destination_id
                  ) {
                    return destination;
                  }
                  return {
                    ...destination,
                    openStatus: !destination.openStatus,
                  };
                }
              ),
          },
        },
      };
    case SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_OTHERS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinationsOpenStatus:
              state.TimelineWizard.activeTrip.tripDestinationsOpenStatus.map(
                (destination) => {
                  if (
                    destination.tripDestinationId === action.trip_destination_id
                  ) {
                    return {
                      ...destination,
                      openStatus: true,
                    };
                  }
                  return {
                    ...destination,
                    openStatus: false,
                  };
                }
              ),
          },
        },
      };
      case SET_TIMELINE_TRIP_DESTINATIONS_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinationsOpenStatus: action.status
          },
        },
      };
    case SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_CLOSE_ALL:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinationsOpenStatus:
              state.TimelineWizard.activeTrip.tripDestinationsOpenStatus.map(
                (destination) => {
                  return {
                    ...destination,
                    openStatus: false,
                  };
                }
              ),
          },
        },
      };
    case SET_TIMELINE_TRIP_DESTINATION_OPEN_STATUS_OPEN_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinationsOpenStatus:
              state.TimelineWizard.activeTrip.tripDestinationsOpenStatus.map(
                (destination) => {
                  if (
                    destination.tripDestinationId === action.trip_destination_id
                  ) {
                    return {
                      ...destination,
                      openStatus: true,
                    };
                  }
                  return {
                    ...destination,
                  };
                }
              ),
          },
        },
      };
    case SWRAP_TRIP_DESTINATION_IN_TRIP:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinations:
              state.TimelineWizard.activeTrip.tripDestinations.map(
                (destination) => {
                  if (
                    destination.td_id ===
                    action.trip_destination_drag_object.td_id
                  ) {
                    console.log("drag", action.trip_destination_drag_object);
                    return {
                      ...action.trip_destination_drop_object,
                    };
                  }
                  if (
                    destination.td_id ===
                    action.trip_destination_drop_object.td_id
                  ) {
                    console.log("drop", action.trip_destination_drop_object);
                    return {
                      ...action.trip_destination_drag_object,
                    };
                  }
                  return {
                    ...destination,
                  };
                }
              ),
          },
        },
      };
    case DRAG_DROP_TRIP_DESTINATION_IN_TRIP:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinations: action.trip_destinations_rearrange,
          },
        },
      };
    case UPDATE_TRIP_DESTINATION_IN_TRIP:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinations: action.trip_destinations_rearrange,
          },
        },
      };
    case ADD_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinationsOpenStatus: [
              ...state.TimelineWizard.activeTrip.tripDestinationsOpenStatus,
              action.trip_destination_open_status,
            ],
          },
        },
      };
    case DELETE_TRIP_DESTINATION_IN_TRIP_OPEN_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripDestinationsOpenStatus:
              state.TimelineWizard.activeTrip.tripDestinationsOpenStatus.filter(
                (d) => d.tripDestinationId !== action.trip_destination_id
              ),
          },
        },
      };
    case SET_ACTIVE_TRIP_DESTINATION:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTripDestinationId: action.trip_destination_id,
          activeDestinationId: action.destination_id,
        },
      };
    case SET_ACTIVE_DAY_IN_TRIP:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeDay: {
            ...state.TimelineWizard.activeDay,
            tripDestinationId: action.trip_destination_id,
            dayInTrip: action.day_in_trip,
            dayInTripDestination: action.day_in_trip_destination,
          },
        },
      };
    case SET_ADDED_ACTIVITY_IN_TRIP:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            addedActivityID: action.activityID,
          },
        },
      };
    case FETCH_ACTIVE_ACTIVITIES_IN_TRIP:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            activities: action.activities,
          },
        },
      };
    case ADD_ACTIVITY_IN_ACTIVE_TRIP:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            activities: [
              ...state.TimelineWizard.activeTrip.activities,
              action.activity,
            ],
          },
        },
      };
    case FETCH_PLACES_NEAR_BY_CENTER:
      return {
        ...state,
        Map: {
          ...state.Map,
          placesNearByCenter: action.places_near_by_center,
        },
      };

    case SET_FEATURE_FORMAT:
      let feature_id = "";
      let featureColor = "#19BC9B";

      if (
        action.featureSelected !== undefined &&
        action.featureSelected !== null
      ) {
        if (
          action.featureSelected.hasOwnProperty("feature_id") &&
          action.featureSelected.feature_id.length > 0
        ) {
          feature_id = action.featureSelected.feature_id;
          featureColor = feature_color[Number(feature_id) - 1].color;
        }

        if (
          action.featureSelected.hasOwnProperty("featureId") &&
          action.featureSelected.featureId.length > 0
        ) {
          feature_id = action.featureSelected.featureId;
          featureColor = feature_color[Number(feature_id) - 1].color;
        }
        if (
          action.featureSelected?.hasOwnProperty("id") &&
          action.featureSelected.id.length > 0
        ) {
          feature_id = action?.featureSelected?.id;
          featureColor = feature_color[Number(feature_id) - 1].color;
        }
      } else {
        if (action.featureSelected?.hasOwnProperty("id")) {
          feature_id = action.featureSelected.id;
        } else {
          feature_id = "";
        }
      }

      return {
        ...state,
        FeatureFormat: {
          ...state.FeatureFormat,
          featureId: feature_id,
          slug:
            action.featureSelected !== null ? action.featureSelected.slug : "",
          title:
            action.featureSelected !== null ? action.featureSelected.title : "",
          color: featureColor,
          disable:
            action.featureSelected !== null
              ? action.featureSelected.disable
              : "",
          sorting:
            action.featureSelected !== null
              ? action.featureSelected.sorting
              : "",
        },
      };
    case FETCH_TIMELINE_MUV_HOTELS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            hotels: action.hotels,
          },
        },
      };
    /* leaving from airport  */
    case SET_LEAVING_FROM_AIRPORT_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            leavingFromAirport: {
              ...state.TimelineWizard.activeTrip.leavingFromAirport,
              airportSelected: action.airport_selected,
            },
          },
        },
      };
    case FETCH_LEAVING_FROM_AIRPORT_DATA:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            leavingFromAirport: {
              ...state.TimelineWizard.activeTrip.leavingFromAirport,
              airportResource: action.airport_resource,
            },
          },
        },
      };
    case FETCH_LEAVING_FROM_AIRPORT_API_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            leavingFromAirport: {
              ...state.TimelineWizard.activeTrip.leavingFromAirport,
              errorAPI: action.error_api,
            },
          },
        },
      };
    case SET_LEAVING_FROM_AIRPORT_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            leavingFromAirport: {
              ...state.TimelineWizard.activeTrip.leavingFromAirport,
              error: action.error,
            },
          },
        },
      };
    case SET_LEAVING_FROM_AIRPORT_TEXT_HELPER:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            leavingFromAirport: {
              ...state.TimelineWizard.activeTrip.leavingFromAirport,
              textHelper: action.text_helper,
            },
          },
        },
      };
    case SET_LEAVING_FROM_AIRPORT_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            leavingFromAirport: {
              ...state.TimelineWizard.activeTrip.leavingFromAirport,
              open: action.open,
            },
          },
        },
      };
    /* leaving from airport  */
    case SET_RETURNING_TO_AIRPORT_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            returningToAirport: {
              ...state.TimelineWizard.activeTrip.returningToAirport,
              airportSelected: action.airport_selected,
            },
          },
        },
      };
    case FETCH_RETURNING_TO_AIRPORT_DATA:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            returningToAirport: {
              ...state.TimelineWizard.activeTrip.returningToAirport,
              airportResource: action.airport_resource,
            },
          },
        },
      };
    case FETCH_RETURNING_TO_AIRPORT_API_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            returningToAirport: {
              ...state.TimelineWizard.activeTrip.returningToAirport,
              errorAPI: action.error_api,
            },
          },
        },
      };
    case SET_RETURNING_TO_AIRPORT_ERROR:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            returningToAirport: {
              ...state.TimelineWizard.activeTrip.returningToAirport,
              error: action.error,
            },
          },
        },
      };
    case SET_RETURNING_TO_AIRPORT_TEXT_HELPER:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            returningToAirport: {
              ...state.TimelineWizard.activeTrip.returningToAirport,
              textHelper: action.text_helper,
            },
          },
        },
      };
    case SET_RETURNING_TO_AIRPORT_AUTOCOMPLETE_STATUS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            returningToAirport: {
              ...state.TimelineWizard.activeTrip.returningToAirport,
              open: action.open,
            },
          },
        },
      };
    case FETCH_TRIP_TYPES_SETS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripType: {
              ...state.TimelineWizard.activeTrip.tripType,
              sets: action.sets,
            },
          },
        },
      };
    case SET_TRIP_TYPE_SELECTED:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripType: {
              ...state.TimelineWizard.activeTrip.tripType,
              selectedTypeId: action.type_id,
            },
          },
        },
      };
    case FETCH_TRIP_COMPANION_SETS:
      return {
        ...state,
        TimelineWizard: {
          ...state.TimelineWizard,
          activeTrip: {
            ...state.TimelineWizard.activeTrip,
            tripCompanion: {
              ...state.TimelineWizard.activeTrip.tripCompanion,
              sets: action.sets,
            },
          },
        },
      };

    case SET_LAST_NAME_COMPANION:
      return {
        ...state,
        lastNameTextFieldCompanion: {
          ...state.lastNameTextFieldCompanion,
          lastName: action.last_name,
        },
      };
    case SET_LAST_NAME_ERROR_COMPANION:
      return {
        ...state,
        lastNameTextFieldCompanion: {
          ...state.lastNameTextFieldCompanion,
          error: action.error,
        },
      };
    case SET_LAST_NAME_TEXT_HELPER_COMPANION:
      return {
        ...state,
        lastNameTextFieldCompanion: {
          ...state.lastNameTextFieldCompanion,
          textHelper: action.text_helper,
        },
      };
    case SET_FIRST_NAME_COMPANION:
      return {
        ...state,
        firstNameTextFieldCompanion: {
          ...state.firstNameTextFieldCompanion,
          firstName: action.first_name,
        },
      };
    case SET_FIRST_NAME_ERROR_COMPANION:
      return {
        ...state,
        firstNameTextFieldCompanion: {
          ...state.firstNameTextFieldCompanion,
          error: action.error,
        },
      };
    case SET_FIRST_NAME_TEXT_HELPER_COMPANION:
      return {
        ...state,
        firstNameTextFieldCompanion: {
          ...state.firstNameTextFieldCompanion,
          textHelper: action.text_helper,
        },
      };
    /* email companion */
    case CEHCK_EMAIL_EXIST_FAILURE_COMPANION:
      return {
        ...state,
        emailAddressTextFieldCompanion: {
          ...state.emailAddressTextFieldCompanion,
          checkEmailExsit: action.check_email_exsit,
          check_mail_exsit_axios_error: action.axios_error,
        },
      };
    case CEHCK_EMAIL_EXIST_SUCCESS_COMPANION:
      return {
        ...state,
        emailAddressTextFieldCompanion: {
          ...state.emailAddressTextFieldCompanion,
          checkEmailExsit: action.check_email_exsit,
          check_mail_exsit_axios_error: action.axios_error,
        },
      };
    case SET_EMAIL_ADDRESS_COMPANION:
      return {
        ...state,
        emailAddressTextFieldCompanion: {
          ...state.emailAddressTextFieldCompanion,
          emailAddress: action.email_address,
        },
      };
    case SET_EMAIL_ADDRESS_ERROR_COMPANION:
      return {
        ...state,
        emailAddressTextFieldCompanion: {
          ...state.emailAddressTextFieldCompanion,
          error: action.error,
        },
      };
    case SET_EMAIL_ADDRESS_TEXT_HELPER_COMPANION:
      return {
        ...state,
        emailAddressTextFieldCompanion: {
          ...state.emailAddressTextFieldCompanion,
          textHelper: action.text_helper,
        },
      };
    default:
      return {
        ...state,
      };
  }
};
