import * as Actions from '../../../../redux/actions'
import { EllipsisVIcon, TimesIcon } from "../SVGBank"

import Activities from './Activities'
import DeleteActivityDay from './DeleteActivityDay'
import {
    MAX_ACTIVITY_IN_DAY, URL_REPLACE,
} from "../RegExValidate"
import React from 'react'
import { connect, useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import TimeToReach from './TimeToReach'
import { SearchPlaceTextField, SearchAddressTextField } from '../FormComponentsBank'
import PlaceDuration from "./PlaceDuration"
import throttle from 'lodash/throttle';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';

const ActivitiesDay_ = ({
    setDestinationId,
    fetchDestination,
    fetchDestinationPhotoGallery,
    fetchEstablishmentSuggestedTrips,
    fetchDestinationFeaturesAndTypes,
    fetchEstablishmentSortingOptions,
    fetchEstablishmentInfluencers,
    listDestinationId,
    searchAddress,
    savedTrip,
    searchPlace,
    setSearchAddressSelected,
    addressSelected = searchAddress.addressSelected,
    setActiveTripDestinationId,
    destination, dragDropActivityInActivitiesOfTripDestinationInTrip,
    activitiesInDestinationDay, draggingActivity, dayInTripDestination, lengthActivitiesInDestinationDay,
    lastActivity, activitiesInDestinationDayEstablishmentIds, last_establishmentId, dayInTrip, startingDate,
    activitiesInDestinationDayIds, startingDateInTrip, lengthDestinationDays,
    addActivityToDayOfTripDestinationInTrip, addCustomActivityToDayOfTripDestinationInTrip,
    activeTrip, activeDay, uuid_auth, activeDestinationId,
    draggingEstablishment, lang, switchFeatureContainer }) => {

    const [establishmentPlaceHolder, setEstablishmentPlaceHolder] = React.useState(false)
    const [activityPlaceHolder, setActivityPlaceHolder] = React.useState(false)
    const history = useHistory()

    const tripId = activeTrip.tripId
    const destinations = activeTrip.tripDestinations
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const establishmentIdDataTransfer = draggingEstablishment.draggingEstablishment !== null && draggingEstablishment.draggingEstablishment !== undefined ? draggingEstablishment.draggingEstablishment.establishmentID : ""
    const activityEstablishmentIdDataTransfer = draggingActivity.draggingActivity !== null && draggingActivity.draggingActivity !== undefined ? draggingActivity.draggingActivity.activityEstabID : ""

    const onDropEstablishmentToDay = (event, selectedDestination, dayInTripDestination) => {
        const checkIsMaxDay = draggingActivity.draggingActivity !== undefined && draggingActivity.draggingActivity !== null && Number(dayInTripDestination) !== Number(draggingActivity.draggingActivity.activityDayInDestination) && MAX_ACTIVITY_IN_DAY === activitiesInDestinationDayIds.length

        const checkValidationAddEstablishment = establishmentIdDataTransfer !== undefined
            && establishmentIdDataTransfer !== ""
            && establishmentIdDataTransfer !== last_establishmentId
        checkValidationAddEstablishment && addActivityToDayOfTripDestinationInTrip(lang, uuid, selectedDestination.td_id, dayInTripDestination, establishmentIdDataTransfer, tripId)
        setEstablishmentPlaceHolder(false)

        const checkValidationDragActivity = activityEstablishmentIdDataTransfer !== undefined
            && activityEstablishmentIdDataTransfer !== ""
            && activityEstablishmentIdDataTransfer !== last_establishmentId && !checkIsMaxDay
        checkValidationDragActivity && dragDropActivityInActivitiesOfTripDestinationInTrip(lang, uuid, selectedDestination.td_id, tripId, draggingActivity.draggingActivity.activityID, dayInTripDestination, draggingActivity.draggingActivity.activityDayInDestination, activitiesInDestinationDayIds, activitiesInDestinationDayIds.length, draggingActivity.dragActivityIndexInDay, draggingActivity.draggingActivity.tripDestinationId, draggingActivity.dragActivityIdsInDay)
        setActivityPlaceHolder(false)
    }

    const onDragEnterDay = (event) => {
        const checkIsMaxDay = draggingActivity.draggingActivity !== undefined && draggingActivity.draggingActivity !== null && Number(dayInTripDestination) !== Number(draggingActivity.draggingActivity.activityDayInDestination) && MAX_ACTIVITY_IN_DAY === activitiesInDestinationDayIds.length

        const checkValidationAddEstablishment = establishmentIdDataTransfer !== undefined && establishmentIdDataTransfer !== "" && establishmentIdDataTransfer !== last_establishmentId
        checkValidationAddEstablishment && setEstablishmentPlaceHolder(true)
        const checkValidationDragDropActivity = activityEstablishmentIdDataTransfer !== undefined && activityEstablishmentIdDataTransfer !== "" && activityEstablishmentIdDataTransfer !== last_establishmentId
        checkValidationDragDropActivity && !checkIsMaxDay && setActivityPlaceHolder(true)
        event.preventDefault()
        event.stopPropagation()
    }

    const onDragOverDay = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    React.useEffect(() => {
        setEstablishmentPlaceHolder(false)
    }, [draggingEstablishment.draggingEstablishment])

    React.useEffect(() => {
        setActivityPlaceHolder(false)
    }, [draggingActivity.draggingActivity])

    const onDragLeaveDay = (event) => {
        setEstablishmentPlaceHolder(false)
        setActivityPlaceHolder(false)
        event.preventDefault()
        event.stopPropagation()
    }
    const fetchData = (id, lang, destination, dayInTrip, dayInTripDestination) => {
        // if ((destinations.length === 1 && activeDestinationId !== listDestinationId) ||
        //     (destinations.length > 1 && activeDestinationId !== id)) {
        //     setDestinationId(id)
        //     fetchDestination(id, lang)
        //     fetchDestinationPhotoGallery(id, lang)
        //     fetchEstablishmentSuggestedTrips(id, lang)
        //     fetchDestinationFeaturesAndTypes(id, lang)
        //     fetchEstablishmentSortingOptions(lang)
        //     fetchEstablishmentInfluencers(id, lang)
        // }
        setActiveTripDestinationId(destination, dayInTrip, dayInTripDestination)
        // const url = "/destination/" + destination?.partialUri + "/" + 1 + "/" + id;
        // history.push(url.toLowerCase())
    }

    const [openPlace, setOpenPlace] = React.useState(false);
    const [placeName, setPlaceName] = React.useState("")
    const [selectedPlace, setSelectedPlace] = React.useState()
    const [isCustom, setCustom] = React.useState(false)
    const [placeDuration, setPlaceDuration] = React.useState("01:30")
    const AddPlace = () => {
        //console.log(`ActivitiesDay Adding a place to visit in Day ${dayInTrip}`)

        setOpenSearch("OPEN");
        setOpenPlace(!openPlace)
    }

    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    let newdate = year + "-" + month + "-" + day + "," + "12:55 PM";
    /*const [startTime, setStartTime] = React.useState(newdate)
    const handleStartTime = event => {
        let starttime = event.target.value
        setStartTime(starttime)
        //console.log(`ActivitiesDay starttime ${starttime}`)
    };*/

    const geocoder = new window.google.maps.Geocoder();
    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                geocoder.geocode(request, callback);
            }, 300),
        [],
    );


    React.useEffect(() => {

        if (isCustom) {

            const query = placeName;
            if (!query || query === '') {
                return undefined;
            }

            fetch({
                'address': query
            }, (results, status) => {

                if (status === window.google.maps.GeocoderStatus.OK && results.length > 0) {
                    //console.log (results)
                    const addressArray = results[0].formatted_address.split(",")
                    let city = ""
                    let state = ""
                    let country = ""
                    const length = addressArray.length
                    if (addressArray.length > 3) {
                        city = addressArray[length - 3]
                        state = addressArray[length - 2]
                        country = addressArray[length - 1]
                    }
                    else if (addressArray.length === 3) {
                        city = addressArray[0]
                        state = addressArray[1]
                        country = addressArray[2]
                    } else if (addressArray.length === 2) {
                        city = addressArray[0]
                        country = addressArray[1]
                    } else {
                        city = addressArray[0]
                    }

                    let placeAddress = {}

                    if (selectedPlace && !selectedPlace.source) {
                        placeAddress = {
                            //lengthOfStay: placeDuration,
                            googlePlaceName: placeName,
                            customPlaceNameByGoogle: placeName,
                            customPlaceTypeId: results[0].address_components[0].types[0],
                            googlePlaceId: selectedPlace.googlePlaceId ? selectedPlace.googlePlaceId : results[0].place_id ? results[0].place_id.trim() : "",
                            googlePlaceLatitude: selectedPlace.latitude ? selectedPlace.latitude : results[0].geometry.location.lat(),
                            googlePlaceLongitude: selectedPlace.longitude ? selectedPlace.longitude : results[0].geometry.location.lng(),
                            googlePlaceCity: selectedPlace.cityName ? selectedPlace.cityName : "",
                            googlePlaceState: selectedPlace.stateName ? selectedPlace.stateName : "",
                            googlePlaceCountry: selectedPlace.countryName ? selectedPlace.countryName : "",
                            googlePlaceFormattedAddress: selectedPlace.address ? selectedPlace.address : results[0].formatted_address ? results[0].formatted_address.trim() : "",
                            name: selectedPlace.address ? selectedPlace.address : results[0].formatted_address ? results[0].formatted_address.trim() : "",
                        }
                    }
                    else {
                        placeAddress = {
                            //lengthOfStay: placeDuration,
                            googlePlaceName: placeName,
                            customPlaceNameByGoogle: results[0].address_components[0] ? results[0].address_components[0].short_name.trim() : "",
                            customPlaceTypeId: results[0].address_components[0].types[0],
                            googlePlaceId: results[0].place_id ? results[0].place_id.trim() : "",
                            googlePlaceLatitude: results[0].geometry.location.lat(),
                            googlePlaceLongitude: results[0].geometry.location.lng(),
                            googlePlaceCity: city ? city.trim() : "",
                            googlePlaceState: state ? state.trim() : "",
                            googlePlaceCountry: country ? country.trim() : "",
                            googlePlaceFormattedAddress: results[0].formatted_address ? results[0].formatted_address.trim() : "",
                            name: results[0].formatted_address ? results[0].formatted_address.trim() : "",
                        }

                    }


                    setSearchAddressSelected(placeAddress)
                }
            });
        }

    }, [placeName])



    const handleSubmit = async () => {
        let tripDestination = activeTrip.tripDestinations
        let destinationId = destination.destId
        const activeTripDestination = tripDestination.find(td => parseInt(td.destId) === parseInt(destinationId));
        //console.log(activeTripDestination)
        const activeTripDestinationId = activeTripDestination.td_id
        //console.log(activeTripDestinationId)


        const customName = placeName;
        let geocoder = new window.google.maps.Geocoder();

        if (addressSelected && addressSelected?.name) {

            addressSelected && addressSelected.name && geocoder.geocode({
                'address': addressSelected.name
            }, async function (results, status) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    let latitude = results[0] !== undefined && results[0].geometry.location.lat()
                    let longitude = results[0] !== undefined && results[0].geometry.location.lng()
                    //console.log(`latitude = ${latitude}`)
                    //console.log(`longitude = ${longitude}`)

             
                    if(selectedPlace?.id){
                        await addActivityToDayOfTripDestinationInTrip(lang, uuid, parseInt(activeTripDestinationId), parseInt(dayInTripDestination), selectedPlace?.id, parseInt(activeTrip.tripId))
                    }
                    else{
                         await  addCustomActivityToDayOfTripDestinationInTrip(
                            placeDuration,
                            customName.trim(),
                            addressSelected.googlePlaceName || customName.trim(),
                            addressSelected.googlePlaceId,
                            latitude,
                            longitude,
                            addressSelected.googlePlaceCity,
                            addressSelected.googlePlaceState,
                            addressSelected.googlePlaceCountry,
                            addressSelected.name,
                            lang,
                            uuid,
                            parseInt(activeTripDestinationId),
                            parseInt(dayInTripDestination),
                            parseInt(activeTrip.tripId),
                        )

                    }
                    setSelectedPlace({})//clear selected
                    setOpenSearch("CLOSE");

                    setPlaceName("")
                    setOpenPlace(false)

             


                 

                }
            })


        }


    };

    const isRoadTrip = savedTrip && savedTrip?.trip && savedTrip?.trip?.tripTravelCategory?.code === "road";


    const canSubmit = !!(addressSelected && addressSelected?.name && (addressSelected.googlePlaceName || placeName))

    const timeToReachColor = "#19BC9B"
    const timeToReachFillIcon = "#7F7F7F"

    //const [selectedDate, setSelectedDate] = React.useState(String (new Date('2020-01-01 00:30')))
    //const handleDateChange = date => {
    //     const new_date = date === null ? new Date('2020-01-01 00:30') : date
    //     setSelectedDate(new_date)
    // }

    /*const [selectedValue, setSelectedValue] = React.useState('');
    const handleSelect = (event) => {
        const name = event.target.name;
        console.log(`handleSelect ${name}`)
    }*/


    const [checked, setChecked] = React.useState(false);
    const [openSearch, setOpenSearch] = React.useState("CLOSE")
    const [openPlaceSearch, setOpenPlaceSearch] = React.useState("CLOSE")
    const handleCheckChange = (event) => {
        setChecked(event.target.checked);
    };
    const timelineHotels = useSelector(state => state.ActiveTrip.timelineHotels);
    const muvHotels = timelineHotels && timelineHotels.muvHotels ? timelineHotels.muvHotels : null;
    const customHotels = timelineHotels && timelineHotels.customHotels ? timelineHotels.customHotels : null;
    const hotelList = muvHotels && muvHotels?.length > 0 ? muvHotels : customHotels;

    const destinationHotels = hotelList && hotelList?.length > 0 ?
        hotelList?.find((hotel) => (hotel?.trip_destinations_id === destination?.td_id || hotel?.tripDestinationId === destination?.td_id)) : null;





    const [isDraggingId, setDraggingId] = React.useState(null)
    const [isDropId, setDropId] = React.useState(null)


    const [startDragging, setDragging] = React.useState(null)

    const addressRef = React.createRef()


    return (
        <React.Fragment>
            <div style={{
                backgroundColor: "var(--mainWhite)",
            }}>
                {dayInTrip !== "" && <React.Fragment >
                    <div
                        id={destination.td_id + "_" + dayInTripDestination + "_" + dayInTrip}
                        onDragOver={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragOverDay(e) : {}}

                        onDrop={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDropEstablishmentToDay(e, destination, dayInTripDestination) : {}}
                        onDragEnter={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragEnterDay(e) : {}}

                        style={{
                            paddingTop: "15px", textAlign: "center",
                            paddingBottom: startingDateInTrip !== "" ? "15px" : "20px",
                            borderBottom: activeDay !== undefined && activeDay.tripDestinationId === destination.td_id && activeDay.dayInTripDestination === dayInTripDestination ? "4px solid var(--mainGreen)" : "4px solid #fff",
                            position: "relative",
                        }}>
                        {<div style={{
                            font: "18px/21px Futura Hv BT",
                            color: "var(--bgBlack)",
                            cursor: "pointer",
                            display: "inline-block",
                        }}
                            className="bgGreenHover"
                            onClick={() => { fetchData(destination.id, lang, destination, dayInTrip, dayInTripDestination) }}
                        >{startingDate}</div>}

                        {!isRoadTrip && startingDateInTrip !== "" && <div style={{
                            marginTop: "5px",
                            font: "12px/18px Futura Md BT",
                            color: "#B1B1B1",
                        }}>
                            {startingDateInTrip}
                        </div>}
                        {/* {isRoadTrip && startingDateInTrip && <div style={{
                            font: "18px/21px Futura Hv BT",
                            color: "var(--bgBlack)",
                            cursor: "pointer",
                            display: "inline-block",
                        }}
                        onClick={() => { fetchData(destination.id, lang, destination, dayInTrip, dayInTripDestination) }}
                            className="bgGreenHover"
                        >{startingDateInTrip}</div>} */}


                        {lengthDestinationDays > 1 && lengthActivitiesInDestinationDay === 0
                            && <DeleteActivityDay
                                destination={destination}
                                dayInTripDestination={dayInTripDestination}
                            />}
                    </div>


                    <div onDragLeave={(e) => onDragLeaveDay(e)}>
                        {lengthActivitiesInDestinationDay > 0 && activitiesInDestinationDay.map((activity, index_activity) => {
                            return (
                                <Activities key={activity.activityID}
                                    tripDestinationId={destination.td_id}
                                    dayInTrip={dayInTrip}
                                    dayInTripDestination={dayInTripDestination}
                                    destination={destination}
                                    activity={activity}
                                    prevActivity={activitiesInDestinationDay?.[index_activity - 1]}
                                    lengthDestinationDays={lengthDestinationDays}
                                    indexActivity={index_activity}
                                    startDragging={startDragging}
                                    setDragging={setDragging}
                                    isDraggingId={isDraggingId}
                                    isDropId={isDropId}
                                    setDraggingId={setDraggingId}
                                    setDropId={setDropId}
                                    hasDestinationHotel={destinationHotels}
                                    activitiesInDestinationDayEstablishmentIds={activitiesInDestinationDayEstablishmentIds}
                                    activitiesInDestinationDayIds={activitiesInDestinationDayIds}
                                    lengthActivitiesInDestinationDay={lengthActivitiesInDestinationDay}
                                />
                            )
                        })}
                    </div>
                    {/* <TimeToReach activity={null} fillIcon={timeToReachFillIcon} color={timeToReachColor} /> */}

                    {activityPlaceHolder && draggingActivity.draggingActivity !== null && <div style={{
                        border: "1px solid rgba(25, 188, 155, 0.5)", display: "flex", margin: "20px", backgroundColor: "rgba(25, 188, 155, 0.1)", height: "80px"
                    }}
                        draggable={true}
                        onDragLeave={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragLeaveDay(e) : {}}
                        onDrop={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDropEstablishmentToDay(e, destination, dayInTripDestination, lastActivity) : {}}
                        onDragOver={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragOverDay(e) : {}}
                    />}

                    {openPlace && openSearch !== "CLOSE" && <div style={{ border: "1px solid #E8E8E8", background: '#F8F8F8', margin: "0px", marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ height: "20px", textAlign: "right", font: "15px/18px Futura Md BT", color: "#000000", marginTop: "20px", paddingRight: "10px" }} onClick={AddPlace}><TimesIcon style={{ marginTop: "15px" }} width={'15'} height={'15'} /></div>
                        <div style={{ height: "20px", textAlign: "left", font: "15px/18px Futura Md BT", color: "#000000", marginTop: "20px", paddingLeft: "10px" }}>{`+ Add a place on Day ${dayInTrip}`}</div>

                        <div style={{ height: "100px", border: "1px solid #E8E8E8", background: 'white', margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                            <p style={{ font: "14px/16px Futura Md BT", color: "#000000" }}>Place</p>

                            <SearchPlaceTextField label="Search Place"
                                required={false}
                                id="search_place"
                                addressRef={addressRef}
                                setOpenSearch={setOpenPlaceSearch} placeName={placeName} setPlaceName={setPlaceName} setCustom={setCustom}
                                setSelectedPlace={setSelectedPlace} />


                        </div>
                        <div style={{ height: "100px", border: canSubmit ? "1px solid #E8E8E8" : "1px solid red", background: 'white', margin: "auto", marginTop: "20px", padding: "20px", textAlign: "left" }}>
                            <p style={{ font: "14px/16px Futura Md BT", color: "#000000" }}>Addresss</p>


                            <SearchAddressTextField label="Search Address"
                                required={false}
                                e
                                id="search_destination"
                                setOpenSearch={setOpenSearch}
                                addressRef={addressRef} />

                        </div>
                        {/*<div style={{height: "82px", border: "1px solid #E8E8E8", margin: "auto", marginTop:"10px", padding: "20px", textAlign: "left"}}>                     
                                        <TextField
                                            id="datetime-local_checkout"
                                            label="Start time"
                                            type="datetime-local"
                                            InputProps={{ 
                                                disableUnderline: true
                                            }}
                                            InputLabelProps={{
                                                 shrink: true,
                                            }}
                                            onChange={handleStartTime}
                                            value={startTime}
                                        />                      
                                        </div> */}
                        <div style={{ height: "82px", border: "1px solid #E8E8E8", background: 'white', margin: "auto", marginTop: "10px", padding: "20px", textAlign: "left" }}>
                            <PlaceDuration setPlaceDuration={setPlaceDuration} />
                        </div>
                        <div style={{
                            font: "14px Futura Md BT", color: "var(--mainWhite)", textAlign: "center", paddingTop: "15px", height: "45px", border: "1px solid #E8E8E8", margin: "auto", marginTop: "10px",

                            backgroundColor: canSubmit ? "var(--mainGreen)" : "#8F8F8F"
                        }}
                            onClick={canSubmit ? handleSubmit : null}>
                            Submit
                        </div>
                        <div style={{ display: "flex", flexDirection: "horizontal", justifyContent: "flex-end", paddingTop: "5px", height: "40px", border: "1px solid #E8E8E8", margin: "auto" }}>
                            <div style={{ font: "14px/16px Futura Lt BT", color: "#707070", marginTop: "5px" }}>Suggest this place to Muv</div>
                            <div style={{ marginLeft: "10px" }}>
                                <Checkbox
                                    checked={checked}
                                    onChange={handleCheckChange}
                                    color="default"
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                />
                            </div>
                        </div>
                    </div>}
                    <div style={{ textAlign: "center", font: "14px/16px Futura Hv BT", color: "#19BC9B" }} onClick={AddPlace}> + Add a place </div>
                </React.Fragment>}
            </div>

            {establishmentPlaceHolder && draggingEstablishment.draggingEstablishment !== null && <div style={{
                border: "1px solid rgba(25, 188, 155, 0.5)", display: "flex", margin: "20px", backgroundColor: "rgba(25, 188, 155, 0.1)", height: "80px"
            }}
                draggable={true}
                onDragLeave={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragLeaveDay(e) : {}}
                onDrop={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDropEstablishmentToDay(e, destination, dayInTripDestination, lastActivity) : {}}
                onDragOver={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragOverDay(e) : {}}
            />}

            {lengthActivitiesInDestinationDay > 0 && <div style={{ height: "35px", margin: "0px 12px", }}
                onDragOver={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragOverDay(e) : {}}

                onDrop={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDropEstablishmentToDay(e, destination, dayInTripDestination) : {}}

                onDragEnter={(e) => (destination.id === draggingEstablishment.draggingEstablishmentDestinationId && lengthActivitiesInDestinationDay < MAX_ACTIVITY_IN_DAY) || (destination.id === draggingActivity.dragActivityInDestination && draggingActivity.draggingActivity !== null) ? onDragEnterDay(e) : {}}>
            </div>}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        searchPlace: state.FormBank.TimelineWizard.searchPlace,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeDay: state.FormBank.TimelineWizard.activeDay,
        lang: state.Setting.lang,
        uuid_auth: state.Member.authModal.uuid,
        savedTrip: state.ActiveTrip,
        activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
        draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
        draggingEstablishment: state.FormBank.TimelineWizard.draggingEstablishment,
        switchFeatureContainer: state.FormBank.FeatureFormat,
        searchAddress: state.FormBank.TimelineWizard.searchAddress,
        listDestinationId: state.Destination.destination.activeId,
    }
}

const mapDispatchToProps = {
    dragDropActivityInActivitiesOfTripDestinationInTrip: Actions.dragDropActivityInActivitiesOfTripDestinationInTrip,
    addActivityToDayOfTripDestinationInTrip: Actions.addActivityToDayOfTripDestinationInTrip,
    addCustomActivityToDayOfTripDestinationInTrip: Actions.addCustomActivityToDayOfTripDestinationInTrip,
    setDestinationId: Actions.setDestinationId,
    setSearchAddressSelected: Actions.setSearchAddressSelected,
    fetchDestination: Actions.fetchDestination,
    fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
    fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    fetchEstablishmentSortingOptions: Actions.fetchEstablishmentSortingOptions,
    fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    setActiveTripDestinationId: Actions.setActiveTripDestinationId,
}

const ActivitiesDay = connect(mapStateToProps, mapDispatchToProps)(ActivitiesDay_)
export default ActivitiesDay
