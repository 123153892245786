import { AirplaneDestination, TrainDestination, HighwayDestination, BusIcon, CarIcon, DirectionsIcon, FlightShapeIcon, TrainDuotoneIcon, WalkIcon, BicycleIcon } from "../SVGBank"

import Divider from '@material-ui/core/Divider'
import React from 'react'
import { connect } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { member_area_router, list_container_mode } from "../RegExValidate"
import moment from 'moment'
import { CircularProgress, ClickAwayListener, Fab, Popper } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles'
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import BusLogo from '../../../../assets/images/Bus.png';
import { secondsToHms, timeToHM, toHHMMSS } from "../../../../utility/utility"


const TimeToReachDestinations_ = ({ currency, transportModes, uuid, setTransportMode, fetchTransportModes, savedTrip, destination, activeTripDestinationId, setSwitchContainer, setTripDisplayMode, activeTrip, index_destination }) => {
    const classes = useStyles();
    const defaultMode = destination !== undefined && destination.transportMode !== undefined && destination.transportMode !== null ? destination.transportMode : ""
    const [open, setOpen] = React.useState(false);
    const [transportMode, setMode] = React.useState(defaultMode);
    const [loadingOptions, setLoading] = React.useState(false);


    const options = transportModes && transportModes?.length > 0 ? [...transportModes[0]?.transitOptions] : null;
    const anchorRef = React.useRef(null);
    let destinationTimeToReachSlice =
        (destination.destinationTimeToReach !== undefined && destination.destinationTimeToReach !== null) ?
            (destination.destinationTimeToReach).slice() : ""
    // const destinationTimeToReach = "020:01:03"
    // let destinationTimeToReachSplit = "020:01:03"
    let destinationTimeToReachSplit = destinationTimeToReachSlice !== "" ? destinationTimeToReachSlice.split(":") : []
    let timeToReachDestinations = ""

    if (destinationTimeToReachSplit.length === 3) {
        // console.log("It has day")
        const destinationTimeToReachWithDay = destinationTimeToReachSplit[1] + ":" + destinationTimeToReachSplit[2]
        const day = destinationTimeToReachSplit[0] === "0" ? "" : destinationTimeToReachSplit[0].replace(/^0/, "")
        timeToReachDestinations = moment(destinationTimeToReachWithDay, "HHmm").format('H') === "0" ?
            moment(destinationTimeToReachWithDay, "HHmm").format('m') + "min" :
            (moment(destinationTimeToReachWithDay, "HHmm").format('m') === "0" ?
                moment(destinationTimeToReachWithDay, "HHmm").format('H') + "h" :
                moment(destinationTimeToReachWithDay, "HHmm").format('H') + "h" + moment(destinationTimeToReachWithDay, "HHmm").format('m') + "min")
        timeToReachDestinations = day + "day" + timeToReachDestinations
    } else if (destination.destinationTimeToReach !== null) {
        // console.log("It has no day")
        timeToReachDestinations = moment(destination.destinationTimeToReach, "HHmm").format('H') === "0" ?
            moment(destination.destinationTimeToReach, "HHmm").format('m') + "min" :
            (moment(destination.destinationTimeToReach, "HHmm").format('m') === "0" ?
                moment(destination.destinationTimeToReach, "HHmm").format('H') + "h" :
                moment(destination.destinationTimeToReach, "HHmm").format('H') + "h" + moment(destination.destinationTimeToReach, "HHmm").format('m') + "min")

    }
    const color = activeTripDestinationId === destination.td_id ? "#8F8F8F" : "#8F8F8F"
    const fillIcon = activeTripDestinationId === destination.td_id ? "#8F8F8F" : "#8F8F8F"
    const daysInDestination = destination && destination.spanningDays ? destination.spanningDays.split(",") : []
    const firstDayInDestination = daysInDestination.length > 0 ? parseInt(daysInDestination[0]) : 0
    const tripDestinations = activeTrip.tripDestinations
    const previousDestination = tripDestinations.find((d => {
        let spanDays = d.spanningDays.split(",")
        let lastDays = parseInt(spanDays[spanDays.length - 1])
        return lastDays === firstDayInDestination
    }))



    let transitLabel = ""
    let destinationCity = ""
    let previousDestinationCity = ""

    if (previousDestination && destination) {


        try {
            destinationCity = destination?.name?.split(",")[0]?.split("-")[0]
            previousDestinationCity = previousDestination?.name?.split(",")[0]?.split("-")[0]
            transitLabel = previousDestinationCity + " - " + destinationCity + "  :  " + timeToReachDestinations
        }
        catch {

        }
    } else if (!previousDestination) {

        try {
            destinationCity = destination?.name?.split(";")[0]?.split("-")[0]
            transitLabel = "Home  - " + destinationCity + "  :  " + timeToReachDestinations
        }
        catch {

        }

    }


    const isRoadTrip = savedTrip && savedTrip?.trip && savedTrip?.trip?.tripTravelCategory?.code === "road";

    const handleToggle = async () => {
        if (!open) {
            setLoading(true)
            const tripId = activeTrip?.tripId;
            const destId = destination?.td_id
            if (tripId && uuid && destId) {
                await fetchTransportModes(uuid, tripId, destId)
            }
            setTimeout(() => {
                setLoading(false)
            }, 1500);
        }
        setOpen(prevOpen => !prevOpen);
    };
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => {
        // const tripId = activeTrip?.tripId;
        // if (tripId && uuid && activeTripDestinationId) {
        //     fetchTransportModes(uuid, tripId, activeTripDestinationId)
        // }
    }, [activeTripDestinationId])

    // const dest = destination? `${destination?.name},${destination?.countryName}`:'';


    // if(previousDestination && dest ){
    // const origin = previousDestination? `${previousDestination?.name},${previousDestination?.countryName}`:'';
    // fetchTransportModes(uuid, origin,dest)
    // }


    const getIcon = (option) => {


        if (option.code === "walk" || option.code === "walking") {
            return <WalkIcon fill="#0D3A7D" width={33} height={33} />
        }
        if (option.code === "bus") {
            return <img src={BusLogo} style={{ height: 33 }} alt="bus" />;
        }
        if (option.code === "car") {
            return <HighwayDestination width={33} height={33} fill="#0D3A7D" />
        }
        if (option.code === "plane") {
            return <AirplaneDestination width={33} height={33} fill="#0D3A7D" />
        }
        if (option.code === "train") {
            return <TrainDestination width={33} height={33} fill="#0D3A7D" />
        }
    }
    const activityTimeToReach = (time) => {
        const timeToreach = moment(time, "HHmm").format('H') === "0" ?
            moment(time, "HHmm").format('m') + "min" :
            (moment(time, "HHmm").format('m') === "0" ?
                moment(time, "HHmm").format('H') + "h" :
                moment(time, "HHmm").format('H') + "h" + moment(time, "HHmm").format('m') + "min")
        return timeToreach;
    }

    const setTimeToReach = (option) => {
        const destId = destination?.td_id
        if (destId && option?.code) {
            const data = {
                code: option?.code,
                cost: option?.routes?.cost.price,
                full_json: option?.routesFullJson,
                duration: option?.routes?.duration,
                currency: currency?.selected?.name
            };
            setTransportMode(uuid, destId, data)
        }
        setMode(option?.code)
        setOpen(false);
    }

    const savedTransitDuration = destination?.savedTransit?.overall_duration ? timeToHM(destination?.savedTransit?.overall_duration) : "";

    return (
        <div>
            {index_destination && index_destination !== 0 ?  <div style={{ textAlign: "center", backgroundColor: "#FFFFFF" }}
            >
                <div style={{ display: "inline-block", }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>

                        {<div style={{ height: 90 }}>
                            <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "90px" }} />
                            <div style={{
                                opacity: "1",
                                position: "relative", top: -75, padding: '6px', background: 'white'
                            }}>
                                {/* {isRoadTrip && <div>
                                    <HighwayDestination width={38} height={42} />
                                </div>} */}
                                {<div
                                    ref={anchorRef}
                                    aria-controls={open ? 'menu_ellipsis' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle}
                                    className={classes.button_text}
                                    style={{ cursor: "pointer", }}
                                >
                                    {(transportMode === "bus" || transportMode === "public_transport") && <div>
                                        <img src={BusLogo} style={{ height: 33 }} alt="bus" /></div>}

                                    {transportMode === "car" && <HighwayDestination width={38} height={42} fill="#0D3A7D" />}
                                    {transportMode === "plane" && <AirplaneDestination width={38} height={42} fill="#0D3A7D" />}
                                    {transportMode === "train" && <TrainDestination width={38} height={42} fill="#0D3A7D" />}
                                    {(transportMode === "walking" || transportMode === "walk") && <WalkIcon fill="#0D3A7D" width={38} height={42} />}

                                </div>}

                                {savedTransitDuration && <div style={{
                                    font: "11px/16px Futura Md BT",
                                    color: "var(--mainBlue)",
                                    position: "absolute",
                                    top: 20,
                                    left: 50,
                                }}>
                                    <div>
                                        {savedTransitDuration}
                                    </div>
                                </div>}

                            </div>

                            {loadingOptions ?
                                <div>

                                    <CircularProgress color="inherit" style={{
                                        marginLeft: 40,
                                        width: 30, height: 30, zIndex: 1, position: "absolute", color: " #19bc9b", top: 30
                                    }} />
                                </div>
                                :
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Popper
                                        load
                                        open={open}
                                        anchorEl={anchorRef.current}
                                        className={classes.positionZIndex}

                                        role={undefined}
                                        transition
                                        disablePortal
                                        placement={"bottom-end"}
                                        modifiers={{
                                            flip: {
                                                enabled: false,
                                            },
                                        }}

                                    >
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement, }}
                                                {...({ timeout: 1000 })}
                                            >
                                                <Paper >
                                                    <MenuList
                                                        load
                                                        autoFocusItem={false}
                                                        disableListWrap={true}
                                                        dense={false}
                                                        id="menu_ellipsis"
                                                    >

                                                        {options && options.map((option, option_index) => {

                                                            const duration = option?.routes?.duration ? timeToHM(option?.routes?.duration) : "";
                                                            const cost = option?.routes?.cost ? `${"$"}${option?.routes?.cost.price || ""}+` : "";
                                                            const showOption = option?.code === "bus" || option?.code === "car" || option?.code === "train" || option?.code === "plane";
                                                            if (showOption) {
                                                                return <MenuItem className={classes.backgroundSelection} key={option_index} onClick={() => setTimeToReach(option)} >
                                                                    <Fab size="small" disabled={true}
                                                                        style={{
                                                                            outline: "0px",
                                                                            backgroundColor: "transparent",
                                                                        }}>

                                                                        {getIcon(option)}
                                                                    </Fab>

                                                                    <div style={{
                                                                        font: "12px/16px Futura Lt BT",
                                                                        color: "var(--mainBlue)",
                                                                        display: "block",
                                                                        marginLeft: "5px",
                                                                    }}>
                                                                        <div>
                                                                            {duration}
                                                                        </div>
                                                                        <div style={{ color: "var(--mainGreen)", }}>
                                                                            {cost}
                                                                        </div>

                                                                    </div>


                                                                </MenuItem>
                                                            }
                                                            return null

                                                        })}
                                                    </MenuList>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>

                                </ClickAwayListener>}

                        </div> }


                        {/* {!isRoadTrip && <div>
                                <Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "20px" }} />
                                <div style={{
                                    width: "320px",
                                    height: "45px",
                                    outline: "0px",
                                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                                    border: "1px solid #8F8F8F",
                                    borderRadius: "36px",
                                    opacity: "1",
                                    position: "relative",
                                }}>
                                    <div style={{ position: "absolute", top: "10px", left: "20px" }}>
                                        {transportMode === "walking" && <WalkIcon fill={fillIcon} />}
                                        {transportMode === "bus" && <BusIcon fill={fillIcon} />}
                                        {transportMode === "car" && <CarIcon fill={fillIcon} />}
                                        {transportMode === "plane" && <FlightShapeIcon fill={fillIcon} />}
                                        {transportMode === "train" && <TrainDuotoneIcon fill={fillIcon} />}
                                    </div>
                                    <div style={{ position: "absolute", top: "12px", left: "55px" }}>{transitLabel}</div>
                                    <div style={{ position: "absolute", top: "12px", right: "20px" }}>
                                        <DirectionsIcon fill={fillIcon} />
                                    </div>
                                </div>
                            </div>} */}
                        {/*<Divider orientation="vertical" style={{ display: "inline-block", backgroundColor: color, height: "20px" }} />
                        <div style={{ position: "absolute", top: "30px", left: "50px" }}>{timeToReachDestinations}</div>*/}
                    </div>
                </div>
            </div>:null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    savedTrip: state.ActiveTrip,
    uuid: state.Member.authModal.uuid,
    transportModes: state.FormBank.TimelineWizard.transportModes,
    currency: state.Setting.currency,
})

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    fetchTransportModes: Actions.fetchTransportModes,
    setTransportMode: Actions.setTransportMode
}

const TimeToReachDestinations = connect(mapStateToProps, mapDispatchToProps)(TimeToReachDestinations_)
export default TimeToReachDestinations



/*  inject css to menu component */
const useStyles = makeStyles((theme) => {

    return {
        root: {
            display: 'inline-block',
        },
        button_text: {
            color: "var(--mainGreen)",
            textAlign: "left",
            font: "16px/22px Futura Md BT",
            letterSpacing: "0",
            '&:focus': {
                border: "0",
                outline: "0px"
            }
        },
        positionZIndex: {
            top: "-210px !important",
            left: "81px !important",
            zIndex: "34 !important",
            width: 137



        },



        paddingLeft30: {
            paddingLeft: "30px"
        },
        backgroundSelection: {
            '&:hover': {
                backgroundColor: ""
            },
            minHeight: "10px",
        },
        paper: {
            marginRight: theme.spacing(2),
        },

        hr: {

            width: "100%",
            height: "1px",
            display: "block",
            position: "relative",
            marginRight: "10%",
            marginTop: "5px",
            marginBottom: "5px",
            border: "1px solid rgba(0,0,0,.05)",
        },
        timesDiv: {
            marginRight: "10px",
            marginLeft: "115px",
            display: "inline-block",
            textAlign: "right",
        },

    }
});