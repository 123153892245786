import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'
import { validate, validate_fields } from "../Common/RegExValidate"

import Banner from '../Common/Banner.jsx'
import FeatureContainer from "./FeatureContainer"
import Header from '../Header/Header'
import ItineraryMap from "../Common/Trip/ItineraryMap"
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../Common/MemberActions/MemberActionsInitial"
import MemberAreaContainer from "../SocialPage/MemberAreaContainer"
import PageNotFound from '../ErrorPage/PageNotFound'
import Profile from '../Common/Profile/ProfileBar.jsx'
import { Redirect } from "react-router-dom"
import TimelineWizardContainer from "../Common/TimelineWizardContainer"
import TripComtainer from "../Common/Trip/TripContainer"
import { connect, useDispatch } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { member_area_router } from "../Common/RegExValidate"
import { useParams } from 'react-router-dom';
import { capitalizeWord } from '../../../utility/utility'
import "./FeaturePage.css"

import TimelinePageContainer from '../TimelinePage/TimelinePageContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { ListIcon, MapDuotoneIcon } from '../Common/SVGBank'
import { HeaderMobile } from '../Header/HeaderMobile'
import { deleteTrip, fetchActiveTripByTripId, setTimeLineStatus, setTripToTimeline } from '../../../redux/actions'
import { BookingFormHeader } from '../Header/BookingFormHeader'
import { environment } from '../../../Environments/environment'
import AiChatBox from '../Common/AiChatBox/AiChatBox'

const FeaturePage_ = ({ closeTripTimelineWizard,uuid,lang,timelineStatus, is_mobile, activeTrip, loading, preload, switch_container, featureListDisplayFormat, tripMode, setSwitchContainer, ActiveTrip, feature
    , setPageYOffset, appMenuBookingButtonStatus,setAppMenuBookingButtonStatus, setTripDisplayMode, setScreenSize, setBodyClientSize, setAppMenu, clearFeatureFormat,
    setSwitchFeatureTypeContainerOfFeature, fetchDestinationPhotoGallery, fetchEstablishmentSuggestedTrips, fetchEstablishmentInfluencers, setDestinationId, fetchDestination,
    addSelectedSubTypeFeature, selectedSubtypes, setSortingOptionsFeature, fetchDestinationFeaturesAndTypes, subtypes, fetchSortingOptionsByFeature, setSwitchFeatureListContainer, setFeatureId, fetchDestinationFilterByFeature, addSelectedDestinationsFeature, fetchTypesAndSubtypesFeature, fetchActiveTripFromCookies
}) => {

    /* update screen size */
    const updateDimensions = () => {
        setScreenSize(window.screen.width, window.screen.height)
        setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        setAppMenu(document.body.clientWidth)
    }

    const params = useParams()
    const dispatch = useDispatch()
    const props = {
        match: {
            params: params || null
        }
    }
    const activeTripId = activeTrip?.tripId


    const featureID = props.match.params.featureID
    const destinationID = props.match.params.destinationID
    const tripID = props.match.params.tripID
    const typeID = props.match.params.typeID;
    const filterType = props.match.params.type;
    const pageDesc = props.match.params.desc
    const selectedDestinations = feature ? feature.loading.selectedDestinations : [];


    const setDefaultTypeTag = (types) => {  //see type default
        if (types) {
            var defaultType = types.find(obj => {
                return obj.typeID === "1"
            })
            if (defaultType) {
                const type = {
                    typeID: defaultType.typeID
                }
                setSwitchFeatureTypeContainerOfFeature(featureID, lang, types, type)
            }
        }
    }

    const setTypeTag = (types) => {
        if (filterType !== null && typeID !== undefined &&
            typeID !== null &&
            typeID !== "" && filterType !== undefined &&
            filterType !== "" && filterType === 'type') {
            const type = {
                typeID: typeID
            }
            setSwitchFeatureTypeContainerOfFeature(featureID, lang, types, type)
        }
    }
    const setFeaturePage = () => {
        if (featureID !== undefined &&
            featureID !== null &&
            featureID !== "") {
            fetchSortingOptionsByFeature(featureID, lang)
            if (destinationID) {
                fetchTypesAndSubtypesFeature(featureID, lang, setDefaultTypeTag, true)
            }
            else {
                fetchTypesAndSubtypesFeature(featureID, lang, setTypeTag)
            }
            fetchDestinationFilterByFeature(featureID, lang)
            setFeatureId(Number(params.featureID))
        }
    }


    const setTrendingPage = () => {
        if (typeID !== undefined &&
            typeID !== null &&
            typeID !== "" && filterType !== undefined && filterType !== "") {
            if (filterType === 'destination') {
                addSelectedDestinationsFeature(typeID, [])  //filters page by id
            }
            if (filterType === 'subtype') {
                const title = params.desc ? params.desc.replace(/-/g, ' ') : '';
                const subtype = {
                    id: typeID,
                    title: capitalizeWord(title)
                }
                addSelectedSubTypeFeature(subtype, selectedSubtypes)
            }
        }

    }

    const setDestinationPage = () => {
        if (destinationID !== undefined &&
            destinationID !== null &&
            destinationID !== "") {
            setDestinationId(destinationID)
            fetchDestination(destinationID, lang)
            fetchDestinationPhotoGallery(destinationID, lang,1)
            fetchEstablishmentSuggestedTrips(destinationID, lang)
            fetchEstablishmentInfluencers(destinationID, lang)
            setTripDisplayMode(list_container_mode.timeline)
            fetchDestinationFeaturesAndTypes(destinationID, lang)
            setSwitchFeatureListContainer(list_container_mode.list)

        }

        if(tripID && uuid){
            // setTimeout(() => {
            //     closeTripTimelineWizard()
            // }, 200);
             dispatch(setTripToTimeline(tripID, uuid))
             setTimeout(() => {
                dispatch(setTimeLineStatus(true))
            }, 500);
            // setTimeout(() => {
            //     dispatch(setTripToTimeline(tripID, uuid))
            //     dispatch(setTimeLineStatus(true))
            // }, 500);
        }

    }


    React.useEffect(() => {
        setFeaturePage()
        setDestinationPage()  //use as destination page
        setTrendingPage()  //use as trending page

        if(!tripID){
            fetchActiveTripFromCookies(destinationID, lang)
        }

        // handleMap()
    }, [destinationID,featureID,typeID,tripID,uuid])



 





    // console.log(loading)

    const goToMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }

    const goToList = () => {
        setTripDisplayMode(list_container_mode.timeline)
        setPageYOffset(0)
    }


    const goToTripTimeline = () => {
        setTripDisplayMode(list_container_mode.mobileTimeline)
        setPageYOffset(0)
    }


    const handleMap = () => {
        const isSuggested = params?.desc && params?.desc?.includes("suggested")
        const savedActiveTrip = ActiveTrip;
        const isRoadTrip = savedActiveTrip && savedActiveTrip.trip ? savedActiveTrip.trip?.tripTravelCategory?.code === "road" || savedActiveTrip.isRoadTrip : false;
        if ((params && params?.desc === "plan-trip" && isRoadTrip) || isSuggested) {
            setTripDisplayMode(list_container_mode.map)
            setPageYOffset(0)
        }


   
    }

    React.useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        updateDimensions()
        setSwitchContainer(member_area_router.feature)

        setPageYOffset(0)

        setAppMenuBookingButtonStatus(true)
        setTripDisplayMode(list_container_mode.timeline)
        handleMap()
        return () => {
            window.removeEventListener("resize", updateDimensions)
            clearFeatureFormat()
        }
    }, [])

    const isMobileTimeline = false;

 
    return (
        validate(lang, validate_fields.languages) ?
            <div style={{
                height: "auto",
            }} className="featurePageBody" >
                {preload ? <Loader
                    type="Rings"
                    color="#19BC9B"
                    className="se-pre-con"
                    height={150}
                    width={150}
                    style={{
                        backgroundColor: "#FFFFFF"
                    }} /> : ""}


                {switch_container === member_area_router.feature &&
                    <React.Fragment>

                        {is_mobile && <div style={{
                            position: "fixed", bottom: "80px", left: "53%",
                            transform: "translateX(-50%)", zIndex: 10,
                            backgroundColor: "#274674",
                            color: "var(--mainWhite)",
                            font: "18px/18px Futura Lt BT",
                            textAlign: "center",
                            padding: 10,
                            borderRadius: 30
                        }}>
                            {tripMode === list_container_mode.timeline && <React.Fragment>
                                {activeTripId && <span onClick={() => goToTripTimeline()} >
                                    <FontAwesomeIcon icon={faRoute} size="1x"
                                        style={{ color: "#FFFFFF" }}/> {` `} Trip </span>}
                                {activeTripId && <span style={{ color: "var(--mainGrey)", }}>|</span>}
                                <span onClick={() => goToMap()}> <MapDuotoneIcon width={20} height={20} fill={"#FFFFFF"} /> {` `} Map </span>
                            </React.Fragment>}
                            {tripMode === list_container_mode.map &&  <React.Fragment>
                                { activeTripId &&<span onClick={() => goToTripTimeline()} >
                                    <FontAwesomeIcon icon={faRoute} size="1x"
                                        style={{ color: "#FFFFFF" }} /> {` `} Trip </span>}
                                {activeTripId && <span style={{ color: "var(--mainGrey)", }}>|</span>}
                                <span onClick={() => goToList()}> <ListIcon width={20} height={20} fill={"#FFFFFF"} /> {` `} List </span>
                            </React.Fragment>}
                            {tripMode === list_container_mode.mobileTimeline &&  <React.Fragment>
                                <span onClick={() => goToMap()}> <MapDuotoneIcon width={20} height={20} fill={"#FFFFFF"} /> {` `} Map </span>
                                <span style={{ color: "var(--mainGrey)", }}>|</span>
                                <span onClick={() => goToList()}> <ListIcon width={20} height={20} fill={"#FFFFFF"} /> {` `} List </span>
                            </React.Fragment>}
                        </div>}


                        {tripMode === list_container_mode.mobileTimeline ? <React.Fragment>
                            {/* <HeaderMobile useTransparent bg_color={"transparent"} text_color={"var(--mainBlack)"} /> */}
                            {/* {appMenuBookingButtonStatus && <BookingFormHeader />} */}
                            <TimelinePageContainer />
                        </React.Fragment> :

                            <React.Fragment>

                                {tripMode === list_container_mode.schedule && <TripComtainer />}
                                {tripMode === list_container_mode.calendar && <TripComtainer />}

                                {tripMode === list_container_mode.map && is_mobile && <HeaderMobile bg_color={"transparent"} text_color={"var(--mainBlack)"} useTransparent />}
                                {tripMode === list_container_mode.map && !is_mobile && <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"}  header_height={"half"}/>}
                                {tripMode === list_container_mode.map && <TimelineWizardContainer listDisplayFormat={list_container_mode.map} />}
                                {tripMode === list_container_mode.map && window.google !== undefined && <ItineraryMap />}


                                {tripMode === list_container_mode.timeline && is_mobile&& <HeaderMobile useTransparent bg_color={"transparent"} text_color={"var(--mainBlack)"} />}
                                {tripMode === list_container_mode.timeline && !is_mobile &&  <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"}  />}
                                {tripMode === list_container_mode.timeline && <TimelineWizardContainer listDisplayFormat={featureListDisplayFormat} />}
                                {tripMode === list_container_mode.timeline && <FeatureContainer props={props} setFeaturePage={setFeaturePage} />}
                            </React.Fragment>
                        }






                    </React.Fragment>
                }
                {switch_container === member_area_router.memberAreaTrip &&
                    <React.Fragment>
                        {tripMode === list_container_mode.map ?
                            <React.Fragment>
                                <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} header_height={"half"} />
                                <TimelineWizardContainer listDisplayFormat={list_container_mode.map} />
                                {window.google !== undefined && <ItineraryMap />}
                            </React.Fragment> :
                            <TripComtainer />}
                    </React.Fragment>}
                {(switch_container === member_area_router.trips
                    || switch_container === member_area_router.bucketList
                    || switch_container === member_area_router.favorites
                    || switch_container === member_area_router.personalInfo
                    || switch_container === member_area_router.muvTribe)
                    &&
                    <React.Fragment>
                        <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} />
                        <Banner />
                        <Profile />
                        <MemberAreaContainer />
                    </React.Fragment>}
                <MemberActionsInitial />
            </div>
            :
            <Redirect to={"error"} component={PageNotFound} />
    )

}


const mapStateToProps = state => {
    return {
        featureListDisplayFormat: state.Feature.feature.featureListDisplayFormat,
        preload: state.Setting.preload,
        switch_container: state.Member.switch_container,
        lang: state.Setting.lang,
        appMenuBookingButtonStatus: state.Setting.appMenu.appMenuBookingButtonStatus,
        tripMode: state.Member.tripMode,
        uuid: state.Member.authModal.uuid,
        is_mobile: state.Setting.is_mobile,
        ActiveTrip: state.ActiveTrip,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        feature: state.Feature.feature,
        subtypes: state.Feature.feature.subtypes,
        selectedSubtypes: state.Feature.feature.loading.selectedSubtypes,
        timelineStatus: state.FormBank.TimelineWizard.status,
        loading: state.Feature.feature.loading,
    }
}

const mapDispatchToProps = {
    setBodyClientSize: Actions.setBodyClientSize,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

    setPageYOffset: Actions.setPageYOffset,

    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    clearFeatureFormat: Actions.clearFeatureFormat,
    fetchSortingOptionsByFeature: Actions.fetchSortingOptionsByFeature,
    fetchTypesAndSubtypesFeature: Actions.fetchTypesAndSubtypesFeature,
    setSwitchFeatureListContainer: Actions.setSwitchFeatureListContainer,
    fetchDestinationFilterByFeature: Actions.fetchDestinationFilterByFeature,
    fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,
    addSelectedDestinationsFeature: Actions.addSelectedDestinationsFeature,
    addSelectedSubTypeFeature: Actions.addSelectedSubTypeFeature,

    fetchDestination: Actions.fetchDestination,
    fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
    fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    closeTripTimelineWizard: Actions.closeTripTimelineWizard,
    setFeatureId: Actions.setFeatureId,
    setDestinationId: Actions.setDestinationId,
    setSortingOptionsFeature: Actions.setSortingOptionsFeature,
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,

}

const FeaturePage = connect(mapStateToProps, mapDispatchToProps)(FeaturePage_)
export default FeaturePage
