import * as Actions from '../../../redux/actions'

import React, { Component } from 'react'

import HotelListingsListContainer from './HotelListingsListContainer'
import Fab from '@material-ui/core/Fab'
import { MapDuotoneIcon } from "../Common/SVGBank"
import { connect, useSelector } from 'react-redux'
import { list_container_mode } from '../Common/RegExValidate'
import { useHistory } from "react-router-dom"
import { decryptID, isNumeric } from '../HotelPage/hotelServices'


const HotelListingsContainer_ = ({ setTimeLineStatus,props, lang, fetchDestination, setPageYOffset, fetchActiveTripFromCookies, 
                                   setDestinationId, fetchActiveTripByTripId, fetchDestinationPhotoGallery, 
                                   fetchDestinationFeaturesAndTypes, fetchEstablishmentSortingOptions, 
                                   fetchEstablishmentInfluencers, fetchEstablishmentSuggestedTrips, setBookingFormStatus,
                                   setTripDisplayMode, is_mobile, lazyLoading, hotelSearchForm, bookingForm,
                                   getPriceLineHotels, setHotelSearchState,}) => {
    /* fetch destination */

   /* React.useEffect(() => {
        if (props.match.params.destinationID !== undefined &&
            props.match.params.tripID === undefined &&
            props.match.params.destinationID !== null &&
            props.match.params.destinationID !== "") {
                const id = props.match.params.destinationID
                fetchDestination(id, lang)
                fetchDestinationPhotoGallery(id, lang)
                fetchEstablishmentSuggestedTrips(id, lang)
                fetchDestinationFeaturesAndTypes(id, lang)
                fetchEstablishmentSortingOptions(lang)
                fetchEstablishmentInfluencers(id, lang)
                setDestinationId(id)
                fetchActiveTripFromCookies(id, lang)
                setTripDisplayMode(list_container_mode.timeline)
        }
        if (props.match.params.tripID !== undefined &&
            props.match.params.tripID !== null &&
            props.match.params.tripID !== "") {
                fetchActiveTripByTripId(props.match.params.tripID, lang)

        }
    }, [props.match.params.tripID, props.match.params.destinationID])
*/

    /* fetch search results */

    // Log the values
    var searchParams = new URLSearchParams(document.location.search.substring(1));

    searchParams.forEach((value, key) => {
        //console.log(value, key);
    });
    const history = useHistory()
    const searchUrl = history?.location?.search
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency =  selectedCurrency ? selectedCurrency.toUpperCase(): "CAD";
    
    React.useEffect(() => {
        const encodedCityID = searchParams.get("city_id");
        const decryptedCityId = decryptID(encodedCityID)
        let city_id =decryptedCityId;
        const check_in = searchParams.get("check_in")
        const check_out = searchParams.get("check_out")
        const rooms = searchParams.get("rooms")
        const city_latitude = searchParams.get("city_lat")
        const city_longitude = searchParams.get("city_lng")
        const adults = searchParams.get("guests")
        const where = searchParams.get("where")?.replaceAll("_", " ")
        const featuredHotels = searchParams.get("hotels") ?? null;
        const isSearching = hotelSearchForm.isSearching!==undefined ? hotelSearchForm.isSearching : true
        const promoCode = searchParams.get("promo-code") ||  searchParams.get("PROMO-CODE");
        city_id = promoCode?encodedCityID: city_id;

     
        if (city_id !== undefined && city_id !== null && city_id !== "" &&
            check_in !== undefined && check_in !== null && check_in !== "" && 
            check_out !== undefined && check_out !== null && check_out !== "" && 
            rooms !== undefined && rooms !== null && rooms !== "" && 
            adults !== undefined && adults !== null && adults !== "" && 
            where !== undefined && where !== null && where !== "") {
                
                const validReq = isNumeric(city_id)
                // if hotelSearchForm unchanged, do not reload rsults
                if( hotelSearchForm.isSearching && validReq )  {
                        setHotelSearchState({city_id, check_in, check_out, rooms, adults, where, city_latitude, city_longitude, isSearching})
                        
                        //console.log("getPriceLineHotels",city_id, check_in, check_out, rooms, adults, where);
    
                        getPriceLineHotels({city_id, check_in, check_out, rooms, adults, where, city_latitude, city_longitude, isSearching}, lazyLoading,fullCurrency)     // FormBankAction  [fetchPricelineHotels]
                        
                        fetchEstablishmentSortingOptions(lang)
                }                
        }


        if((featuredHotels !== null && featuredHotels !== undefined &&  featuredHotels !== "undefined" && featuredHotels)){
           // setTimeLineStatus(true)
           
        }
    }, [searchUrl])


    const handleMap = () => {
        setTripDisplayMode(list_container_mode.map)
        setPageYOffset(0)
    }
    return (
        <div style={{ position: "relative" }}>
            <HotelListingsListContainer />
            <div style={{ position: "fixed", top: "50%", right: is_mobile ? "10px" : "100px", zIndex: "5" }}>

                <Fab size="large" aria-label="back to list" style={{
                    outline: "0px",
                    backgroundColor: "#F8F8F8"
                }} onClick={() => handleMap()}>
                    <MapDuotoneIcon width={30} height={30} fill={"#0D3A7D"} style={{ marginLeft: "3px" }} />
                </Fab>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        is_mobile: state.Setting.is_mobile,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        bookingForm: state.FormBank.BookingForm,
        
    }
}

const mapDispatchToProps = {
    setTripDisplayMode: Actions.setTripDisplayMode,
    setPageYOffset: Actions.setPageYOffset,

    // destination
    //fetchDestination: Actions.fetchDestination,
    //fetchDestinationPhotoGallery: Actions.fetchDestinationPhotoGallery,
    //fetchDestinationFeaturesAndTypes: Actions.fetchDestinationFeaturesAndTypes,
    //fetchEstablishmentInfluencers: Actions.fetchEstablishmentInfluencers,
    fetchEstablishmentSortingOptions: Actions.fetchHotelListingSortingOptions,
    //fetchEstablishmentSuggestedTrips: Actions.fetchEstablishmentSuggestedTrips,
    //setDestinationId: Actions.setDestinationId,
    //fetchActiveTripFromCookies: Actions.fetchActiveTripFromCookies,

    getPriceLineHotels: Actions.getPriceLineHotels,
    setHotelSearchState: Actions.setHotelSearchState,

    //destination explore    
    fetchActiveTripByTripId: Actions.fetchActiveTripByTripId,
    setBookingFormStatus: Actions.setBookingFormStatus,
    setTimeLineStatus: Actions.setTimeLineStatus,
}

const HotelListingsContainer = connect(mapStateToProps, mapDispatchToProps)(HotelListingsContainer_)
export default HotelListingsContainer
