import {
    DeleteIcon,
    RightArrowIcon,
    StarIcon,
} from "../SVGBank"

import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../../../redux/actions'
import { Grid } from "@material-ui/core"
import { image_boxing, URL_REPLACE } from "../RegExValidate"
import { lookUpSavedHotel, updateBookingDestination } from "../Trip/ActiveTrip.slice"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { deleteHotelTimeline, getNearByAirport, searchHotelByNameThunk } from "../../BookingPage/PaymentPage/payment.thunk"
import { setActiveTripDestinationId, setTimelineTripDestinationOpenStatus } from "../../../../redux/actions"
import { encryptID } from "../../HotelPage/hotelServices"

const TimelineSavedHotel_ = ({ destination,setTimeLineStatus, HotelSearch, hotel, lazyLoading, hotelSearchForm, switchFeatureContainer, getPriceLineHotels }) => {
    const isSearching = hotelSearchForm.isSearching !== undefined ? hotelSearchForm.isSearching : true;
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const dispatch = useDispatch()
    const history = useHistory()
    const [savedHotelData, setHotelData] = React.useState();
    const { uuid } = useSelector(state => state.Member.authModal);
    const activeTrip = useSelector(state => state.FormBank.TimelineWizard.activeTrip);
    const activeTripId = activeTrip?.tripId;

    React.useEffect(() => {
        if (HotelSearch?.establishments && HotelSearch?.establishments.length > 0) {
            setHotelData(HotelSearch?.establishments[0])
        }
        return () => {
        }
    }, [HotelSearch.establishments])


const selectDestination = () => {
    dispatch(setActiveTripDestinationId(destination))
    dispatch(updateBookingDestination(destination))

}

    const isMatching = function (str1, str2) {
        return new RegExp("\\b(" + str1.match(/\w+/g).join('|') + ")\\b", "gi").test(str2);
    };

    const bookNow = async () => {
        setTimeLineStatus(true);
        selectDestination() //setactivedestination

        const city = hotel?.cityName ? hotel.cityName + ', ' : ""
        const state = hotel?.stateName ? hotel.stateName + ', ' : ""
        const country = hotel?.countryName ? hotel.countryName : ""
        const where = city + state + country;

        const city_id = hotel?.pricelineCityId;
        const check_in = hotel?.check_in;
        const check_out = hotel?.check_out;
        const rooms = 1;
        const adults = 1;
        const featuredHotels = hotel?.pricelineHotelId;
        const city_latitude = hotel?.latitude;
        const city_longitude = hotel?.longitude;

        const getData = await getPriceLineHotels({ city_id, check_in, check_out, rooms, adults, where, city_latitude, city_longitude, isSearching }, lazyLoading, fullCurrency, featuredHotels)     // FormBankAction  [fetchPricelineHotels]

        const params = {
            check_in: moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            check_out: hotel?.activityDuration ? moment(new Date(), 'YYYY-MM-DD').add(parseInt(hotel?.activityDuration, 10), 'days').format('YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
            lat: hotel?.activityLatitude,
            lng: hotel?.activityLongitude,
        }



        if (savedHotelData) {
            const encryptedId =  encryptID( hotel?.pricelineHotelId)
            const url = "/hotel-" + savedHotelData?.establishmentName.replace(URL_REPLACE, "_") + city + state + country + "-" + encryptedId
                + "?city_id=" + hotel?.pricelineCityId
                + "&check_in=" + hotel?.check_in
                + "&check_out=" + hotel?.check_out
                + "&rooms=" + 1
                + "&guests=" + 1
                + "&hotels=" + hotel?.pricelineHotelId
                + "&city_lat=" + hotel?.latitude
                + "&city_lng=" + hotel?.longitude
                + "&where=" + (where.replace(URL_REPLACE, "_"))
                + "&ppn_bundle=" + (savedHotelData?.ppn_bundle ? savedHotelData?.ppn_bundle : null);
           history.push(url.toLowerCase());
        }
        else {

            if (hotel?.isCustom === "1") {
                const city = hotel?.cityName ? hotel.cityName + ', ' : ""
                const state = hotel?.stateName ? hotel.stateName + ', ' : ""
                const country = hotel?.countryName ? hotel.countryName : ""
                const where = city + state + country;

                const encryptedId =  encryptID(hotel?.pricelineCityId)
                const url = "/hotel-search?city_id=" +encryptedId
                    + "&check_in=" + hotel?.check_in
                    + "&check_out=" + hotel?.check_out
                    + "&rooms=" + 1
                    + "&guests=" + 1
                    + "&hotels=" + hotel?.pricelineHotelId
                    + "&city_lat=" + hotel?.latitude
                    + "&city_lng=" + hotel?.longitude
                    + "&where=" + (where.replace(URL_REPLACE, "_"))
                history.push(url.toLowerCase());

            }
            else {
                const hotelName = hotel?.activityName;
                const hotelLat = hotel?.activityLatitude;
                const hotelAddress = hotel?.activityAddress;

                dispatch(searchHotelByNameThunk(hotelName)).then(data => {
                    const hotelSearch = data?.payload;
                    var hotelData =hotelSearch? Object?.keys(hotelSearch)?.map(key => {
                        return hotelSearch[key];
                    }):null
                    const firstValue = hotelData && hotelData?.length > 0 ? hotelData[0] : null
                    let similarHotel = hotelData?.find((htl => {

                        return isMatching(htl?.coordinate, hotelLat) && isMatching(htl?.hotel_name, hotelName) &&
                            isMatching(htl?.address?.address_line_one, hotelAddress)
                    }))

                    const searchHotel = similarHotel ? similarHotel : firstValue;
                    if (hotel && searchHotel) {
                        const city = searchHotel?.address.city_name ? searchHotel?.address.city_name + ', ' : ""
                        const state = searchHotel?.address?.state_name ? searchHotel?.address?.state_name + ', ' : ""
                        const country = searchHotel?.address?.country_code ? searchHotel?.address?.country_code : ""
                        const where = city + state + country;


                        let hotelIds = hotelData?.map((htl => htl?.hotelid_ppn));

                        const encryptedId =  encryptID(searchHotel?.cityid_ppn)
                        const url = "/hotel-search?city_id=" + encryptedId
                            + "&check_in=" + params?.check_in
                            + "&check_out=" + params?.check_out
                            + "&rooms=" + 1
                            + "&guests=" + 1
                            + "&hotels=" + hotelIds.toString()
                            + "&city_lat=" + hotel?.activityLatitude
                            + "&city_lng=" + hotel?.activityLongitude
                            + "&where=" + (where.replace(URL_REPLACE, "_"))
                        history.push(url.toLowerCase());

                    }
                    else{
                        searchHotels()///generic
                    }
                })
            }

        }

    }

    const deleteHotel = () => {
        const tripDestId = hotel?.tripDestinationId;
        const customAccomodationId = hotel?.customAccomodationId;
        const tripAccomodationId = hotel?.tripAccomodationId;
        const payload = {
            uuid: uuid,
            tripId: activeTripId,
            isCustom: customAccomodationId ? true : false,
            postData: {
                tripDestinationId: tripDestId,
                customAccomodationId: customAccomodationId,
                tripAccomodationId: tripAccomodationId

            }
        }


        if (uuid) {
            dispatch(deleteHotelTimeline(payload))
        }
    }

    const searchHotels = () => {
        const city = hotel?.cityName ? hotel.cityName + ', ' : ""
        const state = hotel?.stateName ? hotel.stateName + ', ' : ""
        const country = hotel?.countryName ? hotel.countryName : ""
        const where = city + state + country;

        let url = ""
        const encryptedId =  encryptID(hotel?.pricelineCityId)

        url = "/hotel-search?city_id=" + encryptedId
            + "&check_in=" + hotel?.check_in
            + "&check_out=" + hotel?.check_out
            + "&rooms=" + 1
            + "&hotels=" + hotel?.pricelineHotelId
            + "&guests=" + 1
            + "&city_lat=" + hotel?.latitude
            + "&city_lng=" + hotel?.longitude
            + "&where=" + (where.replace(URL_REPLACE, "_"))



        history.push(url.toLowerCase());
    }



    return (
        <div style={{
            marginTop: "12px",
            marginLeft: "12px",
            marginRight: "0px",
            marginBottom: "0px",
            backgroundColor: "var(--mainWhite)",
            textAlign: "center",
            position: "relative",
        }}>
            <div style={{
                background: "var(--frameBlack)",
            }}>
                <div style={{
                    cursor: "pointer", textAlign: "right", position: "absolute",
                    right: '10px', marginTop: '10px', zIndex: 10
                }}
                    onClick={deleteHotel}
                >
                    <DeleteIcon fill="#fff" width={14} height={16} style={{ marginRight: "0px" }} />
                </div>
                {hotel && hotel.images && hotel.images.length > 0 ? <img height={90} style={{
                    width: "100%",
                    opacity: 0.6,
                    objectFit: "cover"
                }} src={hotel.images[0]?.full_url} alt="hotelImage"    onError={({ currentTarget }) => {
                    const thumbnailImg = hotel?.room_json?.thumbnail ? "http://"+ hotel?.room_json?.thumbnail?.slice(2)?.replace("150","300"): "https://images.unsplash.com/photo-1517840901100-8179e982acb7?auto=format&fit=crop&q=80&w=2670&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src=thumbnailImg;
                  }} /> :
                    <img height={90} style={{
                        width: "100%",
                        opacity: 0.6,
                        objectFit: "cover"
                    }} src={image_boxing(hotel.image, "260", "130")} onError={({ currentTarget }) => {
                        const thumbnailImg = hotel?.room_json?.thumbnail ? "http://"+ hotel?.room_json?.thumbnail?.slice(2)?.replace("150","300"): "https://images.unsplash.com/photo-1517840901100-8179e982acb7?auto=format&fit=crop&q=80&w=2670&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src=thumbnailImg;
                      }}
                      alt="hotelImage" />}



            </div>

            <Grid container spacing={3} style={{
                paddingTop: 10, marginBottom: 10,
                font: "14px Futura Hv BT", color: "white", position: "absolute", marginTop: -97, marginLeft: 0
            }}>

                <Grid item xs={9} md={9} lg={9} xl={9} style={{
                    paddingLeft: 20, textAlign: "left", maxHeight: 30, whiteSpace: "nowrap",
                    overflow: "hidden", textOverflow: "ellipsis"
                }} >
                    {hotel.name} {hotel.activityName}
                </Grid>
            </Grid>


            <Grid container spacing={3} style={{
                paddingTop: 10, marginBottom: 10,
                font: "14px Futura Lt BT", color: "white", position: "absolute", marginTop: -50, marginLeft: 0
            }}>
                {<Grid item xs={9} md={9} lg={9} xl={9} style={{
                    visibility: hotel?.guest_rating || hotel?.guestScore ? "visible" : "hidden",
                    paddingLeft: 20, textAlign: "left", maxHeight: 30, whiteSpace: "nowrap",
                    overflow: "hidden", textOverflow: "ellipsis"
                }} >
                    Guest rating: {hotel?.guest_rating || hotel?.guestScore}/10
                </Grid>}
                <Grid item xs={3} md={3} lg={3} xl={3} style={{ paddingLeft: 20, textAlign: "left", padding: 0, paddingTop: 12 }} >
                    <span style={{ color: "#19BC9B", font: "16px/21px Futura Hv BT", paddingLeft: 5 }}> {hotel.starRating} {hotel.activityStarRating}   <StarIcon width={15} height={15} color="#19BC9B" style={{ marginTop: -5 }} /> </span>
                </Grid>
            </Grid>


            <Grid container spacing={3} style={{ paddingTop: 10, marginBottom: 10 }}>
                <Grid item xs={7} md={7} lg={7} xl={7} style={{ paddingLeft: 20, textAlign: "left" }} >
                    {hotel?.room_json?.room_data?.title && <div style={{ font: "12px/12px Futura LT BT", color: "var(--mainGrey)", textAlign: "right", }}>
                        {hotel?.room_json?.room_data?.title}
                    </div>}
                    {hotel && hotel.cost && <div style={{ font: "12px/12px Futura LT BT", color: "#19BC9B", paddingBottom: 6, paddingTop: 10, textAlign: "right", }}>from
                        <span style={{ font: "12px/12px Futura Hv BT" }}> {hotel?.display_symbol ? hotel?.display_symbol : ""}{hotel.cost} </span></div>}
                </Grid>
                <Grid item xs={5} md={5} lg={5} xl={5} style={{ paddingLeft: 20, textAlign: "left", padding: 0 }} >
                    <div onClick={bookNow} style={{ font: "14px/16px Futura Hv BT", cursor: "pointer", color: "white", backgroundColor: "#19BC9B", padding: 19, marginTop: 2 }}>
                        Book Now <RightArrowIcon width={15} height={15} color="white" style={{ marginTop: -2, marginLeft: 15 }} />
                    </div>
                </Grid>

            </Grid>
            <div onClick={searchHotels}
                style={{
                    color: "#274674",
                    cursor: "pointer",
                    textAlign: "right",
                    font: "12px/15px Futura Lt BT",
                    marginTop: 0
                }}>
                see other available hotels
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        switchFeatureContainer: state.FormBank.FeatureFormat,
        hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
        HotelSearch: state.FormBank.HotelSearch,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    }
}

const mapDispatchToProps = {
    getPriceLineHotels: Actions.getPriceLineHotels,
    setTimeLineStatus: Actions.setTimeLineStatus,
}

const TimelineSavedHotel = connect(mapStateToProps, mapDispatchToProps)(TimelineSavedHotel_)
export default TimelineSavedHotel
