import Axios from 'axios';
import Cookies from 'js-cookie';
import { environment } from '../../../../Environments/environment';
import moment from 'moment';

export const getFlightsByDate =  async (data) => {
        const limit = 1;
        const flights = data?.flights || [];
        try {
            let departureAirportCodes = []
            let destinationAirportCodes = []
            let departureDates = []

            departureAirportCodes = flights?.map((data) => data?.airportFrom?.iata);
            destinationAirportCodes =flights?.map((data) => data?.airportTo?.iata);
            departureDates = flights?.map((data) => data?.departureDateFormat);

            const response = await Axios.request({
                url: `${environment.apiUrl}proxy/dispatchRequest`,
                method: 'get',
                params: {
                    "proxyData[origin_airport_code]": departureAirportCodes,
                    "proxyData[destination_airport_code]": destinationAirportCodes,
                    "proxyData[departure_date]": departureDates,
                    "proxyData[adults]": data?.numberAdult,
                    "proxyData[children]": data?.numberChild,
                    "proxyData[cabin_class]": data?.cabinClass,
                    "proxyData[number_of_itineraries]": limit,
                    "proxyData[requested_currency]": data?.currency,
                    "proxyData[platform_version]": 2,
                    // "proxyData[sid]": getSessionId(),
                    proxyApiMethod: "GET",
                    proxyApiProperty: "air",
                    proxyApiEndpoint: "getFlightDepartures"
                },
            })


            if (response.data.ProxyResult.getAirFlightDepartures) {
                const data = response.data.ProxyResult.getAirFlightDepartures.results.result;
                return data;
            }
        }
        catch (err) {
            
        }



    }


   export  function getDates(startDate, stopDate) {
      try{
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            const dateData =  moment(new Date (currentDate)).format('YYYY-MM-DD')
            dateArray.push(dateData);
            currentDate = addDays(currentDate,1);
        }
        return dateArray;
      }catch{

      }
    }

    const addDays = function(newDate, days) {
        var date = new Date(newDate);
        date.setDate(date.getDate() + days);
        return date;
    }

    export function calculatePercentageDifference(valueA, valueB) {
        const difference = valueB - valueA;
        const percentageDifference = Math.abs((difference / valueA) * 100);
        return valueB > valueA ? -percentageDifference:percentageDifference;
      }