import * as Actions from '../../../../redux/actions'

import {
    CalendarDuotoneIcon,
    PlaneArrivalIcon,
    PlaneDepartureIcon,
    TimesIcon,
} from "../SVGBank"

import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getAirportsListThunk } from '../../FlightsPage/services/Flights.thunks'
import { getIPFromAmazon } from '../../HotelPage/hotelServices'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons'

const FlightFormFlightElement_ = ({ flight,flipDirection, flights, index, multiDestination, setFlightsData, removeAddedFlight, lengthFlights, is_mobile, lang, error, }) => {

    const dispatch = useDispatch()
    const airportsList = useSelector(state => state.Flights.airportsList)
    const [departureDate, setDepartureDate] = React.useState(flight.departureDate)

    const [airportFrom, setAirportFrom] = React.useState(flight.airportFrom)
    const [airportTo, setAirportTo] = React.useState(flight.airportTo)



    const handleSelectedAirportsFrom = (airports) => {
        if (airports !== undefined) {
            setAirportFrom(airports.length > 0 ? airports[0] : null)
            setFlightsData(flight, airports.length > 0 ? airports[0] : null, airportTo, departureDate)
        }
    }

    const handleSelectedAirportsTo = (airports) => {
        if (airports !== undefined) {
            setAirportTo(airports.length > 0 ? airports[0] : null)
            setFlightsData(flight, airportFrom, airports.length > 0 ? airports[0] : null, departureDate)
        }
    }

    const handleChangeDepartureDate = (date) => {
        setDepartureDate(date)
        setFlightsData(flight, airportFrom, airportTo, date)

    }

    const handleSearchAirports = (query) => {
        dispatch(getAirportsListThunk(query))
    }

    React.useState(() => {
        if (flight?.departureDate) {
            setDepartureDate(flight.departureDate)
        }
    }, [flight])


    React.useEffect(() => {
        if (lengthFlights === 1) {
            const getAddress = async () => {
                const airport = await getIPFromAmazon();
                if (airport && !airportFrom) { //airportFrom not set
                    setAirportFrom(airport)
                    setFlightsData(flight, airport, airportTo, departureDate)
                }
            }
            getAddress()
        }
        if (lengthFlights > 1) {
            const multiDestinationFrom = multiDestination && flights?.length > 0 && index > 0 ? flights[index - 1]?.airportTo : null;
            if (multiDestination && multiDestinationFrom) {
                setAirportFrom(multiDestinationFrom)
                setFlightsData(flight, multiDestinationFrom, airportTo, departureDate)
            }
        }

    }, [lengthFlights])


    return (
        <div className="row m-0 w-100 mt-3 flightRoundTripForm" style={{ padding: "1px", border: airportFrom !== null && airportTo !== null && airportFrom.iata === airportTo.iata ? "1px solid red" : "" }}>
            <div className="col-12 col-md-5 p-0">
                {lengthFlights > 1 && <div
                    style={{
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: "1",
                        top: "18px",
                        left: "-20px",
                    }}
                    onClick={() => removeAddedFlight(flight)}><TimesIcon width={'15'} height={'15'} />
                </div>}
                <div className="position-relative fromAirport" style={{ border: airportFrom === null && error ? "1px solid red" : "" }}>
                    <AsyncTypeahead
                        id="flight-airport-from-typeahead"
                        labelKey={(option) => {
                            return (option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                `${option.iata} - ${option.airport}`)
                        }}
                        onChange={(e) => handleSelectedAirportsFrom(e)}
                        options={airportsList}
                        placeholder="From"
                        filterBy={() => true}
                        shouldSelect={true}
                        minLength={1}
                        onSearch={query => handleSearchAirports(query)}
                        renderMenuItemChildren={(option) => {
                            return (<div key={option.id} user={option} style={{
                                font: option.type === "main" ? "16px/30px Futura Md BT" : "",
                                paddingLeft: option.type === "sub" ? 20 : 0
                            }}>

                                {option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                    `${option.iata} - ${option.airport}`
                                }
                            </div>)
                        }}
                        selected={flight.airportFrom ? [flight.airportFrom] : []}
                        isLoading={false}
                        delay={100}
                        clearButton={true}
                        multiple={false}
                    />
                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <PlaneDepartureIcon width={15} height={15} />
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-5 p-0">

                <div className="position-relative toAirport" style={{ border: airportTo === null && error ? "1px solid red" : "" }}>
                    <AsyncTypeahead
                        id="light-airport-to-typeahead"
                        labelKey={(option) => {
                            return (option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                `${option.iata} - ${option.airport}`)
                        }}
                        onChange={(e) => handleSelectedAirportsTo(e)}
                        options={airportsList}
                        placeholder="To"
                        shouldSelect={true}
                        filterBy={() => true}
                        minLength={1}
                        onSearch={query => handleSearchAirports(query)}
                        renderMenuItemChildren={(option) => {
                            return (<div key={option.id} user={option} style={{
                                font: option.type === "main" ? "16px/30px Futura Md BT" : "",
                                paddingLeft: option.type === "sub" ? 20 : 0
                            }}>

                                {option.city ? `${option.city} (${option.iata} - ${option.airport})` :
                                    `${option.iata} - ${option.airport}`
                                }
                            </div>)
                        }}
                        selected={flight.airportTo ? [flight.airportTo] : []}
                        isLoading={false}
                        delay={100}
                        clearButton={true}
                        multiple={false}
                    />


                    <div className="switchButton" style={{

                        top: is_mobile ? "10px" : "14px",
                        borderRadius: '50%', width: 35,
                    }} onClick={()=>{flipDirection(index)}}>
                        <div style={{ padding: 1, cursor: "pointer", textAlign: "center" }}>
                            <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ color: '#202020' }} />
                        </div>
                    </div>

                    <div className="position-absolute" style={{ top: is_mobile ? "10px" : "14px", left: "7px" }}>
                        <PlaneArrivalIcon width={15} height={15} />
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-2 p-0">
                <div className="position-relative" style={{ border: departureDate === "" && error ? "1px solid red" : "" }}>
                    <DatePicker
                        selected={departureDate}
                        onChange={(date) => handleChangeDepartureDate(date)}
                        placeholderText="Departure"
                        dateFormat="yyyy/MM/dd"
                        minDate={flight.availableSelectDate}
                        className="form-control" />
                    <div className="position-absolute" style={{ top: is_mobile ? "12px" : "14px", left: "7px" }}>
                        <CalendarDuotoneIcon width={15} height={15} />
                    </div>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    is_mobile: state.Setting.is_mobile,
    searchListAirports: state.Home.search_list_airports,
})

const mapDispatchToProps = {
    getAirportList: Actions.getAirportList,
}

const FlightFormFlightElement = connect(mapStateToProps, mapDispatchToProps)(FlightFormFlightElement_)
export default FlightFormFlightElement
