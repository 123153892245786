import * as Actions from '../../../redux/actions'

import { AngleUpIcon, PlusSignIcon } from '../Common/SVGBank';
import React, { Component, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import { priceline_image_boxing, list_container_mode, loadingImage, generateHQHotelImages } from '../Common/RegExValidate'
import AddedToTrip from '../Common/AddedToTrip'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EstablishmentDescription } from '../Common/EstablishmentDescription'
import EstablishmentName from '../Common/HotelEstablishment/EstablishmentName'
import Fab from '@material-ui/core/Fab';
import { Heart } from '../Common/MemberActions/Heart'
import {
    Image,
} from 'react-bootstrap';
import Influencers from '../Common/Influencers/Influencers.jsx'
import LazyLoadingHotelsScrollEventListener from './LazyLoadingHotelsScrollEventListener'
import { Plus } from '../Common/MemberActions/Plus'
import Rating from '@material-ui/lab/Rating'
import { ScrollTopLazyLoading } from "../Common/ScrollTopLazyLoading"
import { Star } from '../Common/MemberActions/Star'
import { connect } from 'react-redux'
import { member_area_router, URL_REPLACE, calcDistance } from "../Common/RegExValidate"
import moment from 'moment'
import OpenEstablishmentBtn from '../Common/HotelEstablishment/OpenEstablishmentBtn'
import { Virtuoso } from 'react-virtuoso'
import { saveHotelTimeline } from '../BookingPage/PaymentPage/payment.thunk';
import Axios from 'axios';
import { getPropertyType } from '../../../redux/actions';
import { diffDays } from '../HotelPage/Rooms';


const PricelineEstablishments_ = ({ establishmentsToFilter, noProgress, switch_container, establishments, lang, uuid_auth, activeTrip, timelineWizardStatus,
    activeTripDestinationId, activeTripDay, addActivityToTripDestinationInTrip,
    bodyClientWidth, isFetchingEstablishments, isMobile, destinaionListDisplayFormat,
    switchFeatureContainer, addSelectedSubTypeDestination, deleteSelectedSubTypeDestination, selectedSubtypes,
    lazyLoading, activeTripActivities, hotelSearchForm, hotelSearchResults, filteredEstablishments, fetchHotelsLazyDisplay,
    addPricelineHotelToMuvDb, booking_info, saveHotelTimelineTrip, onDragOverEstablishment, onDragEndEstablishment,
    onDragStartEstablishment, setTimelineDraggingEstablishment,
    onDropDeleteActivities,
    destinationId, draggingActivity, deleteActivityToDayOfTripDestinationInTrip }) => {



    const virtuoso = React.useRef(null);
    const itemsRef = React.useRef([]);
    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const promoCode = searchParams.get("promo-code") ||  searchParams.get("PROMO-CODE");


    onDragOverEstablishment = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    onDragEndEstablishment = (event) => {
        setTimelineDraggingEstablishment(null, "")
        event.preventDefault()
        event.stopPropagation()
    }

    onDragStartEstablishment = (event, establishment) => {
        setTimelineDraggingEstablishment(establishment, destinationId)
        // event.dataTransfer.setDragImage(document.getElementById('drag_establishment_' + establishment.establishmentID), 0, 0)
    }

    onDropDeleteActivities = (event) => {
        draggingActivity !== undefined &&
            draggingActivity.dragDeleteActivity !== undefined &&
            draggingActivity.dragDeleteActivity !== null &&
            deleteActivityToDayOfTripDestinationInTrip(
                draggingActivity.dragDeleteActivity.lang,
                draggingActivity.dragDeleteActivity.uuid,
                draggingActivity.dragDeleteActivity.tripDestinationId,
                draggingActivity.dragDeleteActivity.activityID,
                draggingActivity.dragDeleteActivity.tripId)
    }




    const activeTripId = activeTrip?.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination


    let widthContainer = ""
    let heightContainer = ""
    let gridContainer = ""
    let postionContainer = ""
    let positionScrollToTopLazyLoading = ""
    let positionInfluencers = establishments !== undefined && establishments.length < 5 ? establishments.length : 3
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    if (!isNaN(isMobile)) {
        widthContainer = isMobile ? Math.floor(bodyClientWidth * 0.98) : Math.floor(timelineWizardStatus ? ((bodyClientWidth) * 0.60 / 2) : (bodyClientWidth * 0.65 / 2))
        //heightContainer = isMobile ? "" : Math.floor(timelineWizardStatus ? ((bodyClientWidth - 380) * 0.75 / 2 / 2) : (bodyClientWidth * 0.75 / 2 / 2))
        widthContainer = widthContainer > 560 ? 560 : widthContainer
        heightContainer = isMobile ? "" : widthContainer / 2
        postionContainer = isMobile ? "block" : "d-flex"
        gridContainer = isMobile ? Math.floor(bodyClientWidth * 0.98 - 5) : Math.floor(timelineWizardStatus ? (bodyClientWidth * 0.535 / 4 - 18) : (bodyClientWidth * 0.7 / 4 - 18))
        positionScrollToTopLazyLoading = isMobile ? "" : (timelineWizardStatus ? "10%" : "10%")
    }

    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const featuredHotels = searchParams.get("hotels") ?? null;
    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
    const tripDuration = activeTrip.tripDuration

    const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)
        document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }


    //let  [,setState]=useState();

    const saveHotel = (hotelId, checkIn, checkOut) => {
        const saveData = {
            postData: {
                pricelineHotelId: hotelId || "",
                booking_trip_number: "",
                booking_status: "",
                booking_status_code: "",
                checkIn: checkIn || "",
                checkOut: checkOut || ""
            },
            uuid: uuid || null
        }
        saveHotelTimelineTrip(saveData);

    }

    const handleAddHotelToTripDestinationInTrip = (establishment, hotel_form_st, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        //console.log("handleAddHotelToTripDestinationInTrip hotel_form_st ",hotel_form_st);
        //console.log("handleAddHotelToTripDestinationInTrip hotelSearchForm ",hotelSearchForm);
        const lengthOfStay = moment(hotelSearchForm.check_out).diff(moment(hotelSearchForm.check_in), "days")
        const establishmentPricelineID = establishment.establishmentPricelineID

        console.log({
            establishment,
            establishmentPricelineID, lengthOfStay, lang, uuid, activeTripDestinationId,
            activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId,
            tripDuration, destinationId, activeTripDestinationIds
        })
        saveHotel(establishmentPricelineID, hotelSearchForm.check_in, hotelSearchForm.check_out)

        // addPricelineHotelToMuvDb({
        //     establishmentPricelineID, lengthOfStay, lang, uuid, activeTripDestinationId,
        //     activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId,
        //     tripDuration, destinationId, activeTripDestinationIds
        // })

        //fetchHotelEstablishment(hotel_id, ppn_bundle, hotelSearchForm)
        //addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)
        //document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip) !== null && document.getElementById(activeTripDay.tripDestinationId + "_" + activeTripDay.dayInTripDestination + "_" + activeTripDay.dayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    /*   let [, setState] = React.useState()
 
       React.useEffect(() => {
           //console.log('DEVNAV useEffect isLoggedIn', isLoggedIn)
           setState({})
       }, [establishments]) */


    const loadMore = () => {
        fetchHotelsLazyDisplay(lazyLoading, filteredEstablishments)
    }
    const isLoggedIn = () => {
        return !!(uuid_auth && uuid_auth?.length > 0);
    }


    function moveToTop(arr, ids) {
        // Create a map to ensure the order of IDs
        const idIndex = new Map(ids.map((id, index) => [id, index]));

        // Filter objects that match IDs and sort them by the order in the ids array
        const matched = arr.filter(obj => idIndex.has(obj?.establishmentPricelineID))
            .sort((a, b) => idIndex.get(a?.establishmentPricelineID) - idIndex.get(b?.establishmentPricelineID));

        // Filter objects that don't match any ID and preserve their original order
        const nonMatched = arr.filter(obj => !idIndex.has(obj?.establishmentPricelineID));

        // Concatenate matched objects to the front and non-matched objects at the end
        return [...matched, ...nonMatched];
    }


    const establishmentFiltered = React.useMemo(() => {

        if (establishmentsToFilter?.length > 0) {
            const searchedHotels = featuredHotels?.length > 0 ? featuredHotels?.split(',') : null;
            let filtered = [...establishmentsToFilter]?.filter((est) => {
                let roomInfo = est?.roomInfo;


                if (!isLoggedIn()) {
                    let merROOM = null;
                    roomInfo.forEach((room) => {
                        const rateData = room?.rate_data;
                        const merRate = rateData?.find((rate) => rate?.rate_type === "MER")

                        if (merRate) {
                            merROOM = { ...room, rate_data: [merRate] };
                        }
                    });

                    if (merROOM) {
                        return est;

                    }
                    return null;


                }

                return est

            });

            if (searchedHotels && filtered?.length > 0) {
                filtered = moveToTop(filtered, searchedHotels);


            }


            return filtered
        }
        return []
    }, [establishmentsToFilter, uuid_auth, featuredHotels])




    const hasHotels = establishmentFiltered && establishmentFiltered?.length > 0;
    const CheckImage = (path) => {
        Axios
            .get(path)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }



    return (
        <React.Fragment>

            {switch_container === member_area_router.memberAreaTrip ?

                <div
                    style={{
                        zIndex: "1",

                    }}>
                    <div id="back_to_top_establishment"></div>
                    {establishments !== undefined //&& establishments.length > 0
                        && establishments.map((establishment, establishment_idx) => {
                            const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentPricelineID)

                            return (
                                <React.Fragment key={establishment_idx}>
                                    <div
                                        draggable={!isFetchingEstablishments ? true : false}
                                        onDragOver={(e) => !isFetchingEstablishments ? onDragOverEstablishment(e) : {}}
                                        onDragEnd={(e) => !isFetchingEstablishments ? onDragEndEstablishment(e) : {}}
                                        onDragStart={(e) => !isFetchingEstablishments ? onDragStartEstablishment(e, establishment) : {}}
                                        onDrop={(e) => !isFetchingEstablishments ? onDropDeleteActivities(e) : {}}

                                        className={destinaionListDisplayFormat === list_container_mode.grid ? (!isFetchingEstablishments ? "d-inline-block draggable" : "") : (!isFetchingEstablishments ? "draggable" : "")}>
                                        {destinaionListDisplayFormat === list_container_mode.list &&
                                            <div key={establishment.establishmentPricelineID}>
                                                <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}>
                                                    <div className={postionContainer}>
                                                        <div className="d-inline-block" style={{ width: widthContainer }}>
                                                            <Image style={{ width: "100%", objectFit: "cover", objectPosition: "center", height: heightContainer }}
                                                                alt="establishment" src={establishments !== undefined && establishments.length > 0 ?
                                                                    priceline_image_boxing(establishment.estabPicture, widthContainer, heightContainer) :
                                                                    loadingImage.src + "?func=crop&w=" + Math.floor(widthContainer) + "&h=" + Math.floor(heightContainer)}
                                                            />
                                                        </div>
                                                        <div style={{ width: widthContainer + "px", minHeight: heightContainer + "px", border: "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>

                                                            {timelineWizardStatus && activeTripId && <div style={{ textAlign: "right", position: "absolute", bottom: "5px", right: "0px", zIndex: "2", }}>
                                                                {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                                                    <AddedToTrip
                                                                        establishmentName={establishment.establishmentName}
                                                                        establishmentStarRating={establishment.establishmentStarRating}
                                                                        availableDeleteActivities={availableDeleteActivities}
                                                                        textFont={"14px/14px Futura Hv BT"}
                                                                        text={"Added to trip"}
                                                                    />}
                                                                {!isFetchingEstablishments ? <React.Fragment>
                                                                    {availableDeleteActivities.length > 0 ?
                                                                        <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                                                        : <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                                                    }
                                                                </React.Fragment> : <CircularProgress
                                                                    disableShrink
                                                                    size={15}
                                                                    style={{
                                                                        color: "#6A9EEC",
                                                                        textAlign: "right",
                                                                        marginTop: "15px",
                                                                        marginRight: "5px",
                                                                    }} />
                                                                }
                                                            </div>}
                                                            <div className="box_1 padding_left_10 padding_right_10 " style={{
                                                                font: "14px/16px Futura Md BT",
                                                                color: "var(--mainGreen)",
                                                                marginTop: 6,
                                                            }}>
                                                                {establishment.establishmentNeighborhood}
                                                            </div>
                                                            {establishment?.distanceFromCity > 0 && <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                font: "12px/14px Futura Lt BT",
                                                                color: "var(--mainGrey)",
                                                                marginTop: 3,
                                                                marginBottom: 1,
                                                            }}>
                                                                {establishment.distanceFromCity.toFixed(1)} km from city center
                                                            </div>}

                                                            <EstablishmentName
                                                                establishmentName={establishment.establishmentName}
                                                                establishmentCity={establishment.establishmentCity}
                                                                establishmentState={establishment.establishmentState}
                                                                establishmentCountry={establishment.establishmentCountry}
                                                                establishmentId={establishment.establishmentPricelineID}
                                                                pricelineId={establishment.establishmentPricelineID}

                                                                ppn_bundle={establishment.ppn_bundle}

                                                                titleClass={""} titleStyle={{
                                                                    font: timelineWizardStatus ? "22px/33px Futura Hv BT" : "28px/30px Futura Hv BT",
                                                                    color: "#FFFFFF",
                                                                    cursor: "pointer",
                                                                    paddingLeft: "10px",
                                                                    paddingBottom: 10,
                                                                    paddingRight: "10px",
                                                                }} />

                                                            {establishment.establishmentStarRating > 0 &&
                                                                <div className="d-flex padding_left_10 padding_right_10 btn_rounded" style={{ paddingTop: 0 }}>
                                                                    <div className="d-flex timeline_rating_color" style={{ width: "50%" }}>
                                                                        <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishment.establishmentStarRating)} disabled={true} />
                                                                        <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{ color: "#C7C7C7" }}>({establishment.establishmentStarRating})</div>
                                                                    </div>
                                                                    {establishment.establishmentReviewRating > 0 && <div style={{ width: "50%" }}>
                                                                        <div className="padding_left_10 padding_right_10" style={{ textAlign: "right", font: "12px/15px Futura Lt BT", color: "var(--mainBlack)" }} >Guest rating: <div style={{ font: "16px/15px Futura Md BT", display: "inline-block" }}>{Number(establishment?.establishmentReviewRating)?.toFixed(1)}/</div><div style={{ font: "12px/15px Futura Md BT", display: "inline-block" }}>10</div></div>
                                                                    </div>}
                                                                </div>}

                                                            {establishment.establishmentDescription !== undefined &&
                                                                <div className={"padding_left_10 padding_right_10"} style={{ marginBottom: "2rem" }}>
                                                                    <EstablishmentDescription description={establishment.establishmentDescription}
                                                                        boxDescription={"box_3"}
                                                                        fontDescription={"14px/19px Futura Lt BT"}
                                                                        colorDescription={"var(--mainGrey)"}
                                                                        textLength={280}
                                                                        height={'100px'} />
                                                                </div>}
                                                            {establishment.establishmentDescription !== undefined &&
                                                                <div className={"padding_left_10 padding_right_10"} style={{ marginBottom: "2rem" }}>
                                                                    <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                        font: "12px/14px Futura Md BT",
                                                                        color: "var(--mainBlack)", textAlign: "right"
                                                                    }}>from</div>
                                                                    <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                        font: "22px/24px Futura Md BT",
                                                                        color: "var(--mainBlack)", textAlign: "right"
                                                                    }}><div style={{ display: "inline-block", font: "12px/14px Futura Md BT", marginBottom: "10px" }}>{establishment.display_currency}</div><div style={{ display: "inline-block" }}>{establishment.display_symbol}{Math.round(establishment.display_price)}</div></div>
                                                                    <OpenEstablishmentBtn
                                                                        establishmentName={establishment.establishmentName}
                                                                        establishmentCity={establishment.establishmentCity}
                                                                        establishmentState={establishment.establishmentState}
                                                                        establishmentCountry={establishment.establishmentCountry}
                                                                        establishmentId={establishment.establishmentPricelineID}
                                                                        pricelineId={establishment.establishmentPricelineID}
                                                                        establishment={establishment}
                                                                        ppn_bundle={establishment.ppn_bundle} />
                                                                </div>}

                                                            <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}>
                                                                {establishment.subTypes !== undefined && establishment.subTypes.length > 0 ?
                                                                    establishment.subTypes.map((subtype, subtype_idx) => {
                                                                        return <div key={subtype_idx}
                                                                            className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                selectedSubtypes.find(val => val.id === subtype.subTypeID) ?
                                                                                    deleteSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                    : addSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                            }}>
                                                                            {subtype.subTypeName}</div>
                                                                    })
                                                                    : ""}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {destinaionListDisplayFormat === list_container_mode.grid &&
                                            <PlacesElement
                                                establishments={establishments}
                                                establishment={establishment}
                                                destinationId={destinationId}
                                                lang={lang}
                                                uuid={uuid}
                                                gridContainer={gridContainer}
                                                selectedSubtypes={selectedSubtypes}
                                                switchFeatureContainer={switchFeatureContainer}
                                                timelineWizardStatus={timelineWizardStatus}
                                                availableDeleteActivities={availableDeleteActivities}
                                                isFetchingEstablishments={isFetchingEstablishments}
                                                handleAddActivityToTripDestinationInTrip={handleAddActivityToTripDestinationInTrip}
                                                activeTripDestinationId={activeTripDestinationId}
                                                activeDayInTripDestination={activeDayInTripDestination}
                                                activeTripId={activeTripId}
                                                last_establishmentId={last_establishmentId}
                                                tripDuration={tripDuration}
                                                activeTripDestinationIds={activeTripDestinationIds}
                                                addSelectedSubTypeDestination={addSelectedSubTypeDestination}
                                                deleteSelectedSubTypeDestination={deleteSelectedSubTypeDestination}
                                                lazyLoading={lazyLoading}
                                                textColor={"#FFFFFF"}
                                                handleAddHotelToTripDestinationInTrip={handleAddHotelToTripDestinationInTrip}
                                                hotelSearchForm={hotelSearchForm}
                                            />}

                                    </div>
                                </React.Fragment>
                            )
                        })
                    }


                    {hotelSearchForm !== undefined && establishments !== undefined && establishments.length === 0 && lazyLoading.noProgress && <div style={{
                        font: "21px/21px Futura Md BT", marginTop: 30
                    }}>  There are no hotels matching the search criteria.</div>}

                    <ScrollTopLazyLoading targetId={"#back_to_top_establishment"} rightPosition={positionScrollToTopLazyLoading}>
                        <Fab size="small" aria-label="scroll back to top" style={{
                            outline: "0px",
                            backgroundColor: "var(--mainGreen)"
                        }}>
                            <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                        </Fab>
                    </ScrollTopLazyLoading>
                    {/* <LazyLoadingHotelsScrollEventListener /> */}
                </div >
                : <div
                    style={{
                        zIndex: "1",
                        paddingBottom: 200,
                        paddingRight: isMobile ? 10 : 0
                    }}>
                    <div id="back_to_top_establishment"></div>
                    {establishments !== undefined //&& establishments.length > 0
                        &&

                        <Virtuoso
                            useWindowScroll
                            endReached={loadMore}


                            totalCount={establishmentFiltered?.length}
                            itemContent={index => {
                                const establishment_idx = index;
                                const establishment = establishmentFiltered[index];
                                const availableDeleteActivities = activeTripActivities.filter(a => a.activityEstabID === establishment.establishmentPricelineID)

                                const propertyType = establishment?.propertyType && getPropertyType(establishment?.propertyType)?.property_type_name ? getPropertyType(establishment?.propertyType)?.property_type_name : "";

                                const days = diffDays(hotelSearchForm?.check_out, hotelSearchForm.check_in) ?? 1;
                                const displayTaxes = establishment?.price_details?.mandatory_fee_details?.display_total;
                                const regularTaxes = Number(establishment?.price_details?.display_taxes/days).toFixed(2);
                                const taxes = Number( (promoCode? displayTaxes:regularTaxes) / days).toFixed(2);
                                const benchmarkPrice = establishment?.muverRate?.benchmark_price_details?.display_price;
                                const notSamePrice = benchmarkPrice !== establishment?.muverRate?.price_details?.display_price;
                                const resortFee = Number(establishment?.price_details?.display_property_fee / days).toFixed(2);


                                return (
                                    <React.Fragment key={establishment_idx}>
                                        <div
                                            ref={el => itemsRef.current[index] = el}
                                            draggable={!isFetchingEstablishments ? true : false}
                                            onDragOver={(e) => !isFetchingEstablishments ? onDragOverEstablishment(e) : {}}
                                            onDragEnd={(e) => !isFetchingEstablishments ? onDragEndEstablishment(e) : {}}
                                            onDragStart={(e) => !isFetchingEstablishments ? onDragStartEstablishment(e, establishment) : {}}
                                            onDrop={(e) => !isFetchingEstablishments ? onDropDeleteActivities(e) : {}}

                                            className={destinaionListDisplayFormat === list_container_mode.grid ? (!isFetchingEstablishments ? "d-inline-block draggable" : "") : (!isFetchingEstablishments ? "draggable" : "")}
                                        >
                                            {destinaionListDisplayFormat === list_container_mode.list &&
                                                <div>
                                                    <div className={timelineWizardStatus ? "margin_top_10" : "margin_top_20"}
                                                        style={{

                                                        }}>
                                                        <div className={postionContainer}>
                                                            <div className="d-inline-block" style={{
                                                                width: isMobile ? '100%' : widthContainer, maxWidth: isMobile ? "100%" : 620,
                                                                backgroundColor: "#e8e8e8"
                                                            }}>
                                                                <Image style={{ width: "100%", objectFit: "cover", maxHeight: 400, objectPosition: "center", height: "100%", }}
                                                                    alt="establishment" src={establishments !== undefined && establishments.length > 0 ?
                                                                        generateHQHotelImages(establishment.estabPicture) :
                                                                        loadingImage.src + "?func=crop&w=" + Math.floor(widthContainer) + "&h=" + Math.floor(heightContainer)}

                                                                    onError={(e) => { e.target.onError = null; e.target.src = "https://amhqajbken.cloudimg.io/v7/_muvestab_/000_c…on/grayBackgroundCamera.jpg" }} />

                                                            </div>
                                                            <div style={{ width: "100%", height: "auto", paddingBottom: isMobile ? 45 : 15, maxWidth: isMobile ? "100%" : 620, border: featuredHotels?.includes(establishment.establishmentPricelineID) ? `2px solid #19BC9B` : "1px solid #E8E8E8", verticalAlign: "middle", flexGrow: "1", paddingLeft: "10px", position: "relative" }}>


                                                                {activeTripId && <div style={{ textAlign: "left", position: "absolute", bottom: "4px", left: "0px", zIndex: "2", }}>
                                                                    {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                                                        <AddedToTrip
                                                                            establishmentName={establishment.establishmentName}
                                                                            establishmentStarRating={establishment.establishmentStarRating}
                                                                            availableDeleteActivities={availableDeleteActivities}
                                                                            textFont={"14px/14px Futura Hv BT"}
                                                                            text={"Added to trip"}
                                                                        />}
                                                                    {!isFetchingEstablishments ? <React.Fragment>

                                                                        <div style={{ marginLeft: 8 }}>
                                                                            <div className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style" style={{
                                                                            }}>
                                                                                {propertyType}
                                                                            </div>
                                                                        </div>
                                                                        {availableDeleteActivities.length > 0 ?
                                                                            <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)}
                                                                                style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}>
                                                                                <PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                                                            : <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)}
                                                                                style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}>
                                                                                <PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip

                                                                            </div>
                                                                        }
                                                                    </React.Fragment> :
                                                                        <CircularProgress
                                                                            disableShrink
                                                                            size={15}
                                                                            style={{
                                                                                color: "#6A9EEC",
                                                                                textAlign: "right",
                                                                                marginTop: "15px",
                                                                                marginRight: "5px",
                                                                            }} />
                                                                    }
                                                                </div>}


                                                                <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                    font: "12px/14px Futura Lt BT",
                                                                    color: "var(--mainGrey)",
                                                                    marginTop: 10,
                                                                    marginBottom: 1,
                                                                }}>
                                                                    {establishment?.neighborhood?.name && <span className="padding_right_10" style={{
                                                                        font: "14px/16px Futura Md BT",
                                                                        color: "var(--mainGreen)",
                                                                        marginTop: 0,
                                                                    }}>
                                                                        {establishment?.neighborhood?.name}
                                                                    </span>} {establishment?.distanceFromCity > 0 && <span>({establishment.distanceFromCity.toFixed(1)} km from city center)</span>}
                                                                </div>
                                                                <EstablishmentName
                                                                    establishmentName={establishment.establishmentName}
                                                                    establishmentCity={establishment.establishmentCity}
                                                                    establishmentState={establishment.establishmentState}
                                                                    establishmentCountry={establishment.establishmentCountry}
                                                                    establishmentId={establishment.establishmentPricelineID}
                                                                    pricelineId={establishment.establishmentPricelineID}

                                                                    ppn_bundle={establishment.ppn_bundle}
                                                                    divClass={"flex_1"}
                                                                    titleClass={""} titleStyle={{
                                                                        font: timelineWizardStatus ? "22px/33px Futura Hv BT" : isMobile ? "18px/18px Futura Hv BT" : "28px/30px Futura Hv BT",
                                                                        color: "var(--frameBlack)",
                                                                        cursor: "pointer",
                                                                        paddingLeft: "10px",
                                                                        paddingRight: "10px",
                                                                        paddingBottom: 10,
                                                                        paddingTop: 10,
                                                                    }} />

                                                                {establishment.establishmentStarRating > 0 &&
                                                                    <div className="d-flex padding_left_10 padding_right_10 btn_rounded" style={{ paddingTop: 0 }}>
                                                                        <div className="d-flex timeline_rating_color" style={{ width: "50%" }}>
                                                                            <Rating name="timeline_rating" precision={0.5} max={5} value={parseFloat(establishment.establishmentStarRating)} disabled={true} />
                                                                            <div className="futura_md_bt_11_14_black margin_bottom_5 margin_left_5" style={{ color: "#C7C7C7" }}>({establishment?.establishmentStarRating})</div>

                                                                        </div>
                                                                        {establishment.establishmentReviewRating > 0 && <div style={{ width: "50%" }}>
                                                                            <div className="padding_left_10 padding_right_10" style={{ textAlign: "right", font: "12px/15px Futura Lt BT", color: "var(--mainBlack)" }} >Guest rating: <div style={{ font: "16px/15px Futura Md BT", display: "inline-block" }}>{Number(establishment?.establishmentReviewRating)?.toFixed(1)}/</div><div style={{ font: "12px/15px Futura Md BT", display: "inline-block" }}>10</div></div>
                                                                        </div>}
                                                                    </div>}


                                                                <div className={"padding_left_10 padding_right_10"} style={{ marginBottom: "0px", minHeight: 100 }}>
                                                                    {establishment.establishmentDescription !== undefined && <EstablishmentDescription description={establishment.establishmentDescription}
                                                                        boxDescription={"box_3"}
                                                                        fontDescription={"14px/19px Futura Lt BT"}
                                                                        colorDescription={"var(--mainGrey)"}
                                                                        textLength={isMobile ? 250 : 320}
                                                                        bottom={1}
                                                                        height={"100px"}
                                                                        maxHeight={"100px"} />}
                                                                </div>

                                                                {
                                                                    <div className={"padding_left_10 padding_right_10"} style={{
                                                                        position: "relative",
                                                                        marginTop: 10,
                                                                        right: 0,
                                                                        bottom: 4
                                                                    }}>
                                                                        <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                            font: "12px/14px Futura Md BT",
                                                                            color: "var(--mainBlack)", textAlign: "right"
                                                                        }}>from</div>
                                                                        <div className="box_1 padding_left_10 padding_right_10" style={{
                                                                            font: "22px/24px Futura Md BT",
                                                                            color: "var(--mainBlack)", textAlign: "right"
                                                                        }}>

                                                                            {establishment?.muverRate && notSamePrice && <div style={{ display: "inline-block", textDecoration: "line-through", color: "red", paddingRight: 8, font: "16px/14px Futura Md BT", marginBottom: "5px" }}>{establishment?.display_symbol}{Math.round(benchmarkPrice)}</div>}
                                                                            {establishment?.muverRate && <div style={{ display: "inline-block" }}><div style={{ display: "inline-block", font: "12px/14px Futura Md BT", marginBottom: "5px" }}>{establishment?.muverRate?.price_details?.display_currency}</div><div style={{ display: "inline-block" }}>{establishment?.muverRate?.price_details?.display_symbol}{Math.round(establishment?.muverRate?.price_details?.display_price)}</div></div>}

                                                                            {!establishment?.muverRate && <div style={{ display: "inline-block" }}><div style={{ display: "inline-block", font: "12px/14px Futura Md BT", marginBottom: "5px" }}>{establishment.display_currency}</div><div style={{ display: "inline-block" }}>{establishment.display_symbol}{Math.round(establishment.display_price)}</div></div>}

                                                                            {taxes > 0 && <div style={{ font: "12px/14px Futura Md BT", display: "grid", marginTop: -2, marginBottom: 2 }}>
                                                                                <div style={{ display: "inline-block" }}>+{establishment.display_symbol}{Number(taxes)?.toFixed(2)} taxes & fees  {false && resortFee > 0 && <span style={{ font: "12px/14px Futura Md BT" }}> +{establishment.display_symbol}{Number(resortFee).toFixed(2)} resort fee
                                                                                </span>}
                                                                                </div>
                                                                            </div>}

                                                                        </div>
                                                                        <OpenEstablishmentBtn

                                                                            hotelIndex={index}
                                                                            establishmentName={establishment.establishmentName}
                                                                            establishmentCity={establishment.establishmentCity}
                                                                            establishmentState={establishment.establishmentState}
                                                                            establishmentCountry={establishment.establishmentCountry}
                                                                            establishmentId={establishment.establishmentPricelineID}
                                                                            pricelineId={establishment.establishmentPricelineID}
                                                                            establishment={establishment}
                                                                            ppn_bundle={establishment.ppn_bundle} />
                                                                    </div>}

                                                                <div className={timelineWizardStatus ? "padding_left_10 padding_right_10 text-left box_1 margin_top_5 margin_bottom_5" : "padding_left_10 padding_right_10 text-left box_1 margin_top_20 margin_bottom_10"}>
                                                                    {establishment.subTypes !== undefined && establishment.subTypes.length > 0 ?
                                                                        establishment.subTypes.map((subtype, subtype_idx) => {
                                                                            return <div key={subtype_idx}
                                                                                className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    selectedSubtypes.find(val => val.id === subtype.subTypeID) ?
                                                                                        deleteSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                        : addSelectedSubTypeDestination({ id: subtype.subTypeID, title: subtype.subTypeName }, lazyLoading, selectedSubtypes)
                                                                                }}>
                                                                                {subtype.subTypeName}</div>
                                                                        })
                                                                        : ""}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {destinaionListDisplayFormat === list_container_mode.grid &&
                                                <PlacesElement
                                                    establishments={establishments}
                                                    establishment={establishment}
                                                    destinationId={destinationId}
                                                    lang={lang}
                                                    uuid={uuid}
                                                    gridContainer={gridContainer}
                                                    selectedSubtypes={selectedSubtypes}
                                                    switchFeatureContainer={switchFeatureContainer}
                                                    timelineWizardStatus={timelineWizardStatus}
                                                    availableDeleteActivities={availableDeleteActivities}
                                                    isFetchingEstablishments={isFetchingEstablishments}
                                                    handleAddActivityToTripDestinationInTrip={handleAddActivityToTripDestinationInTrip}
                                                    activeTripDestinationId={activeTripDestinationId}
                                                    activeDayInTripDestination={activeDayInTripDestination}
                                                    activeTripId={activeTripId}
                                                    last_establishmentId={last_establishmentId}
                                                    tripDuration={tripDuration}
                                                    activeTripDestinationIds={activeTripDestinationIds}
                                                    //addSelectedSubTypeDestination={addSelectedSubTypeDestination}
                                                    deleteSelectedSubTypeDestination={deleteSelectedSubTypeDestination}
                                                    lazyLoading={lazyLoading}
                                                    textColor={"#191919"}
                                                    handleAddHotelToTripDestinationInTrip={handleAddHotelToTripDestinationInTrip}
                                                    hotelSearchForm={hotelSearchForm}
                                                />}
                                        </div>
                                    </React.Fragment>
                                )
                            }}
                        />}

                    {noProgress && !hasHotels && <div style={{
                        font: "21px/21px Futura Md BT", marginTop: 30
                    }}>  There are no hotels matching the search criteria.</div>}

                    <ScrollTopLazyLoading targetId={"#back_to_top_establishment"} rightPosition={positionScrollToTopLazyLoading}>
                        <Fab size="small" aria-label="scroll back to top" style={{
                            outline: "0px",
                            backgroundColor: "var(--mainGreen)"
                        }}>
                            <AngleUpIcon fill={"var(--mainWhite)"} style={{ marginLeft: "3px" }} />
                        </Fab>
                    </ScrollTopLazyLoading>


                    {/* <LazyLoadingHotelsScrollEventListener /> */}


                </div >}
        </React.Fragment>
    )

}

const mapStateToProps = (state) => ({
    destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
    selectedSubtypes: state.Destination.destination.selectedSubtypes,
    lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    //establishments: state.Destination.destination.resultSetsEstablishments,
    establishments: state.FormBank.HotelSearch.establishments,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    destinationId: state.Destination.destination.activeId,
    isMobile: state.Setting.is_mobile,
    bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
    noProgress: state.FormBank.HotelSearch.lazyLoading.noProgress,
    timelineWizardStatus: state.FormBank.TimelineWizard.status,
    draggingActivity: state.FormBank.TimelineWizard.draggingActivity,
    activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    isFetchingEstablishments: state.FormBank.TimelineWizard.isFetchingEstablishments,
    switch_container: state.Member.switch_container,
    establishmentsToFilter: state.FormBank.HotelSearch.establishmentsToFilter,
    filteredEstablishments: state.FormBank.HotelSearch.filteredEstablishments,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
    hotelSearchResults: state.FormBank.HotelSearch.results,
    booking_info: state.FormBank.BookingPriceline.booking_info,

})

const mapDispatchToProps = {
    addSelectedSubTypeDestination: Actions.addSelectedSubTypeDestination,
    deleteSelectedSubTypeDestination: Actions.deleteSelectedSubTypeDestination,
    setTimelineDraggingEstablishment: Actions.setTimelineDraggingEstablishment,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    addPricelineHotelToMuvDb: Actions.addPricelineHotelToMuvDb,
    setHotelEstablishmentId: Actions.setHotelEstablishmentId,
    fetchHotelsLazyDisplay: Actions.fetchHotelsLazyDisplay,
    saveHotelTimelineTrip: saveHotelTimeline
}

const PricelineEstablishments = connect(mapStateToProps, mapDispatchToProps)(PricelineEstablishments_)
export default React.memo(PricelineEstablishments)



const PlacesElement = ({ establishments, establishment, uuid, lang, gridContainer, selectedSubtypes,
    switchFeatureContainer, timelineWizardStatus, availableDeleteActivities, isFetchingEstablishments,
    handleAddActivityToTripDestinationInTrip, activeTripDestinationId, activeDayInTripDestination, activeTripId,
    last_establishmentId, tripDuration, activeTripDestinationIds, deleteSelectedSubTypeDestination,
    addSelectedSubTypeDestination, destinationId, lazyLoading, textColor,
    handleAddHotelToTripDestinationInTrip, hotelSearchForm
}) => {
    const [flipped, setFlipped] = React.useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 }
    })


    return (
        <div style={{
            position: "relative", width: gridContainer + 15, height: gridContainer + 120, display: "inline-block",

            paddingLeft: "7.5px",
            paddingRight: "7.5px",
            paddingBottom: "50px",
            paddingTop: "15px",
        }}>
            <animated.div style={{ position: "absolute", zIndex: flipped ? "0" : "1", opacity: opacity.interpolate(o => 1 - o), transform }}>
                <div style={{
                    position: "relative",
                }}>
                    <div className="d-inline-block" style={{ width: "100%" }}>
                        <Image style={{ width: "100%", objectFit: "cover", objectPosition: "center", height: Math.floor(gridContainer), width: Math.floor(gridContainer) }}
                            src={establishments !== undefined && establishments.length > 0 ? priceline_image_boxing(establishment.estabPicture, gridContainer, gridContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(gridContainer) + "&h=" + Math.floor(gridContainer / 2)}
                            alt="establishment"
                            onClick={() => setFlipped(state => !state)} />
                    </div>
                    <div className="d-flex flex-wrap rating_color pt-2 pl-2" style={{ width: gridContainer + "px", border: "1px solid #E8E8E8" }}>
                        <EstablishmentName

                            establishmentCity={establishment.establishmentCity}
                            establishmentState={establishment.establishmentState}
                            establishmentCountry={establishment.establishmentCountry}
                            establishmentName={establishment.establishmentName}
                            establishmentId={establishment.establishmentPricelineID}
                            pricelineId={establishment.establishmentPricelineID}

                            ppn_bundle={establishment.ppn_bundle}

                            divClass={"flex_1"}
                            titleClass={"box_1"} titleStyle={{
                                font: "16px/16px Futura Md BT",
                                color: textColor,
                                marginTop: "5px",
                                cursor: "pointer",
                            }} />
                        {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color p-0 btn_rounded">
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "8px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>{establishment.establishmentStarRating}</div>
                            <div style={{
                                marginTop: "5px",
                                paddingRight: "2px",
                            }}>
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.establishmentStarRating / 5).toFixed(1))} disabled={true} />
                            </div>
                        </div>}
                        {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                            && establishment.subTypes[0] !== undefined
                            && establishment.subTypes[0].subTypeName !== undefined &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px", cursor: "pointer" }} onClick={() => {
                                selectedSubtypes.find(val => val.id === establishment.subTypes[0].subTypeID) ?
                                    deleteSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, lazyLoading, selectedSubtypes)
                                    : addSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, lazyLoading, selectedSubtypes)
                            }}>{establishment.subTypes[0].subTypeName}</div>
                        }
                    </div>
                    <div style={{ position: "relative" }}>
                        {timelineWizardStatus && activeTripId && <div style={{ textAlign: "right", position: "absolute", top: "5px", right: "0px", zIndex: "2", }}>
                            {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                <AddedToTrip
                                    establishmentName={establishment.establishmentName}
                                    establishmentStarRating={establishment.establishmentStarRating}
                                    availableDeleteActivities={availableDeleteActivities}
                                    textFont={"14px/14px Futura Hv BT"}
                                    text={"Added to trip"}
                                />}
                            {!isFetchingEstablishments ? <React.Fragment>
                                {availableDeleteActivities.length > 0 ?
                                    <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "#707170", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                    : <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                }
                            </React.Fragment> : <CircularProgress
                                disableShrink
                                size={15}
                                style={{
                                    color: "#6A9EEC",
                                    textAlign: "right",
                                    marginTop: "15px",
                                    marginRight: "5px",
                                }} />
                            }
                        </div>}
                    </div>

                </div>
            </animated.div>

            <animated.div style={{ position: "absolute", zIndex: flipped ? "1" : "0", opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
                <div style={{
                    position: "relative",
                }}>
                    <div className="d-inline-block">
                        <Image style={{ width: "100%", objectFit: "cover", objectPosition: "center", height: Math.floor(gridContainer), width: Math.floor(gridContainer) }}
                            src={establishments !== undefined && establishments.length > 0 ? priceline_image_boxing(establishment.estabPicture, gridContainer, gridContainer) : loadingImage.src + "?func=crop&w=" + Math.floor(gridContainer) + "&h=" + Math.floor(gridContainer / 2)}
                            alt="establishment" />

                    </div>
                    {establishment.establishmentDescription !== undefined &&
                        <div style={{ position: "absolute", top: "45px", zIndex: "1" }} className={"padding_left_10 padding_right_10"}>
                            <EstablishmentDescription description={establishment.establishmentDescription} boxDescription={"box_10"} fontDescription={timelineWizardStatus ? "14px/19px Futura Lt BT" : "18px/25px Futura Lt BT"} colorDescription={"#FFFFFF"}
                                height={timelineWizardStatus ? "190px" : "250px"} />
                        </div>}

                    <div style={{ backgroundColor: "#19191980", position: "absolute", width: gridContainer + "px", height: gridContainer + "px", top: "0px" }} onClick={() => setFlipped(state => !state)} />
                    <div className="d-flex flex-wrap rating_color pt-2 pl-2" style={{ width: gridContainer + "px", border: "1px solid #E8E8E8" }}>
                        <EstablishmentName

                            establishmentCity={establishment.establishmentCity}
                            establishmentState={establishment.establishmentState}
                            establishmentCountry={establishment.establishmentCountry}
                            establishmentName={establishment.establishmentName}
                            establishmentId={establishment.establishmentPricelineID}
                            pricelineId={establishment.establishmentPricelineID}

                            ppn_bundle={establishment.ppn_bundle}

                            divClass={"flex_1"}
                            titleClass={"box_1"} titleStyle={{
                                font: "16px/16px Futura Md BT",
                                color: textColor,
                                marginTop: "5px",
                                cursor: "pointer",
                            }} />
                        {establishment.establishmentStarRating !== "0.0" && <div className="d-flex timeline_rating_color p-0 btn_rounded">
                            <div
                                style={{
                                    paddingRight: "5px",
                                    marginTop: "8px",
                                    font: "11px/14px Futura Md BT",
                                    color: "var(--mainGreen)",
                                }}>{establishment.establishmentStarRating}</div>
                            <div style={{
                                marginTop: "5px",
                                paddingRight: "2px",
                            }}>
                                <Rating name="timeline_rating" precision={0.5} max={1} value={parseFloat((establishment.establishmentStarRating / 5).toFixed(1))} disabled={true} />
                            </div>
                        </div>}
                        {establishment.subTypes !== undefined && establishment.subTypes.length > 0
                            && establishment.subTypes[0] !== undefined
                            && establishment.subTypes[0].subTypeName !== undefined &&
                            <div className="btn_rounded mt-1 futura_md_bt_14_16_green upcoming_trip_style" style={{ height: "26px", cursor: "pointer" }} onClick={() => {
                                selectedSubtypes.find(val => val.id === establishment.subTypes[0].subTypeID) ?
                                    deleteSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                                    : addSelectedSubTypeDestination({ id: establishment.subTypes[0].subTypeID, title: establishment.subTypes[0].subTypeName }, selectedSubtypes)
                            }}>{establishment.subTypes[0].subTypeName}</div>
                        }
                    </div>
                    <div style={{ position: "relative" }}>
                        {timelineWizardStatus && activeTripId && <div style={{ textAlign: "right", position: "absolute", top: "5px", right: "0px", zIndex: "2", }}>
                            {availableDeleteActivities.length > 0 && !isFetchingEstablishments &&
                                <AddedToTrip
                                    establishmentName={establishment.establishmentName}
                                    establishmentStarRating={establishment.establishmentStarRating}
                                    availableDeleteActivities={availableDeleteActivities}
                                    textFont={"14px/14px Futura Hv BT"}
                                    text={"Added to trip"}
                                />}
                            {!isFetchingEstablishments ? <React.Fragment>
                                {availableDeleteActivities.length > 0 ?
                                    <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "12px/12px Futura Lt BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon fill={"#707170"} width={7} height={10} style={{ marginRight: "5px" }} />add again</div>
                                    : <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "14px/14px Futura Hv BT", padding: "0px 10px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                }
                            </React.Fragment> : <CircularProgress
                                disableShrink
                                size={15}
                                style={{
                                    color: "#6A9EEC",
                                    textAlign: "right",
                                    marginTop: "15px",
                                    marginRight: "5px",
                                }} />
                            }
                        </div>}
                    </div>

                </div>
            </animated.div>
        </div >
    )
}